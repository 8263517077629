import React, { useEffect } from 'react';
import { useForm  } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { useAuth, AuthService } from "../../hooks/useAuth";
import { DEFAULT_NOTE_GENERATION_MODE } from "../../hooks/useNoteDigest";
import { useStorageHook } from "../../hooks/useLocalStorage";

import { 
    ReactSelect,
    MultiCheckbox,
    Checkbox } from '../../components/forms';


const TEMPLATE_NAMES = [
  'general_medicine',
  'mayo_general',
  'apso_merged',
  'cardiology',
  'diet',
  'psychiatry_soap',
  'psychiatry',
  'psychology',
  'soap_merged',
  'soap',
  'vet_medicine_soap',
]


function NoteCustomizer() {
  return (
    <div class="flex flex-col gap-[16px]">   
        
    {[{name: "Subjective"}, {name: "Objective"}, {name: "Assessment"}, {name: "Plan"}].map((x) => (

    <>
    {/*Section template*/}
    <div class="bg-gray-50 chrome:bg-extension-grey-[3px] p-[12px] rounded">
      <div class="w-full text-sm leading-[20px] font-bold uppercase text-gray-600 border-b-gray-200 chrome:text-white">
        {x.name}
      </div>

      <hr class="bg-gray-200 w-full h-[1px] border-0 chrome:bg-extension-grey-2.5 my-[12px] chrome:my-4"></hr>

      {/*Title*/}
      <div class="flex w-full justify-between items-center text-start my-[12px]">
        <div class="flex flex-col pr-[36px]">
          <label class="font-medium text-[14px] mobile:text-[16px] chrome:text-white" for="customTitle">Custom title</label>
        </div>
        {/* <input placeholder="Chief complaint" id="customTitle" name="customTitle" spellcheck="true" class="py-8 px-12 h-[40px] flex flex-row items-center border rounded text-14 border-grey-200 text-grey-400 bg-white shrink-0 chrome:text-white chrome:bg-transparent chrome:border-extension-grey-2.5 chrome:placeholder:text-extension-grey-2 chrome:disabled:bg-extension-grey-3 chrome:focus:border-primary-400 w-[160px]" value="cc" /> */}
        <input
          name="invite-code"
          placeholder={"Chief Complaint"}
          autoComplete="off"
          className="py-[8px] px-[12px] h-[40px] flex flex-row items-center border rounded text-[14px] border-grey-200 text-grey-400 bg-white shrink-0 chrome:text-white chrome:bg-transparent chrome:border-extension-grey-2.5 chrome:placeholder:text-extension-grey-2 chrome:disabled:bg-extension-grey-3 chrome:focus:border-primary-400 w-[160px]"
        />
      </div>

      {/*style*/}
      <div class="flex w-full justify-between items-center text-start my-[12px]">
        <div class="flex flex-col pr-[36px]">
          <label class="font-medium text-[14px] mobile:text-[16px] chrome:text-white" for="customTitle">Section Style</label>
        </div>
        {/* <input placeholder="Chief complaint" id="customTitle" name="customTitle" spellcheck="true" class="py-8 px-12 h-[40px] flex flex-row items-center border rounded text-14 border-grey-200 text-grey-400 bg-white shrink-0 chrome:text-white chrome:bg-transparent chrome:border-extension-grey-2.5 chrome:placeholder:text-extension-grey-2 chrome:disabled:bg-extension-grey-3 chrome:focus:border-primary-400 w-[160px]" value="cc" /> */}
        <div class="inline-flex">
          <button class="bg-gray-300 hover:bg-gray-400 text-gray-800 py-2 px-4 rounded-l py-[8px] px-[10px] border-grey-200 transition-colors duration-200 outline-none text-14 chrome:border-extension-grey-2.5 border-t border-b border-r first-of-type:rounded-tl first-of-type:rounded-bl first-of-type:border last-of-type:rounded-tr last-of-type:rounded-br last-of-type:border-r last-of-type:border-t last-of-type:border-b last-of-type:border-l-0 bg-white text-grey-300 chrome:bg-transparent chrome:text-extension-grey-2">
            Auto
          </button>
          <button class="bg-gray-300 hover:bg-gray-400 text-gray-800py-2 px-4  py-[8px] px-[10px] border-grey-200 transition-colors duration-200 outline-none text-14 chrome:border-extension-grey-2.5 border-t border-b border-r first-of-type:rounded-tl first-of-type:rounded-bl first-of-type:border last-of-type:rounded-tr last-of-type:rounded-br last-of-type:border-r last-of-type:border-t last-of-type:border-b last-of-type:border-l-0 bg-white text-grey-300 chrome:bg-transparent chrome:text-extension-grey-2">
            Bullet
          </button>
          <button class="bg-gray-300 hover:bg-gray-400 text-gray-800 py-2 px-4 rounded-r py-[8px] px-[10px] border-grey-200 transition-colors duration-200 outline-none text-14 chrome:border-extension-grey-2.5 border-t border-b border-r first-of-type:rounded-tl first-of-type:rounded-bl first-of-type:border last-of-type:rounded-tr last-of-type:rounded-br last-of-type:border-r last-of-type:border-t last-of-type:border-b last-of-type:border-l-0 bg-white text-grey-300 chrome:bg-transparent chrome:text-extension-grey-2">
            Paragraph
          </button>
        </div>
      </div>

      {/*Detail*/}
      <div class="flex w-full justify-between items-center text-start my-[12px]">
        <div class="flex flex-col pr-[36px]">
          <label class="font-medium text-[14px] mobile:text-[16px] chrome:text-white" for="customTitle">Section Style</label>
        </div>
        {/* <input placeholder="Chief complaint" id="customTitle" name="customTitle" spellcheck="true" class="py-8 px-12 h-[40px] flex flex-row items-center border rounded text-14 border-grey-200 text-grey-400 bg-white shrink-0 chrome:text-white chrome:bg-transparent chrome:border-extension-grey-2.5 chrome:placeholder:text-extension-grey-2 chrome:disabled:bg-extension-grey-3 chrome:focus:border-primary-400 w-[160px]" value="cc" /> */}
        <div class="inline-flex">
          <button class="bg-gray-300 hover:bg-gray-400 text-gray-800 py-2 px-4 rounded-l py-[8px] px-[10px] border-grey-200 transition-colors duration-200 outline-none text-14 chrome:border-extension-grey-2.5 border-t border-b border-r first-of-type:rounded-tl first-of-type:rounded-bl first-of-type:border last-of-type:rounded-tr last-of-type:rounded-br last-of-type:border-r last-of-type:border-t last-of-type:border-b last-of-type:border-l-0 bg-white text-grey-300 chrome:bg-transparent chrome:text-extension-grey-2">
            Normal
          </button>
          <button class="bg-gray-300 hover:bg-gray-400 text-gray-800 py-2 px-4 rounded-r py-[8px] px-[10px] border-grey-200 transition-colors duration-200 outline-none text-14 chrome:border-extension-grey-2.5 border-t border-b border-r first-of-type:rounded-tl first-of-type:rounded-bl first-of-type:border last-of-type:rounded-tr last-of-type:rounded-br last-of-type:border-r last-of-type:border-t last-of-type:border-b last-of-type:border-l-0 bg-white text-grey-300 chrome:bg-transparent chrome:text-extension-grey-2">
            Detailed
          </button>
        </div>
      </div>

      {/*hide*/}
      <div class="flex w-full justify-between items-center text-start my-[12px]">
        <div class="flex flex-col pr-[36px]">
          <label class="font-medium text-[14px] mobile:text-[16px] chrome:text-white" for="customTitle">Hide section by default</label>
        </div>
        {/* <input placeholder="Chief complaint" id="customTitle" name="customTitle" spellcheck="true" class="py-8 px-12 h-[40px] flex flex-row items-center border rounded text-14 border-grey-200 text-grey-400 bg-white shrink-0 chrome:text-white chrome:bg-transparent chrome:border-extension-grey-2.5 chrome:placeholder:text-extension-grey-2 chrome:disabled:bg-extension-grey-3 chrome:focus:border-primary-400 w-[160px]" value="cc" /> */}

        <div className="inline-flex items-center">
      <label
        className={`relative flex items-center p-[3px] rounded-full cursor-pointer`} // {/*"relative flex items-center p-3 rounded-full cursor-pointer"*/}
        htmlFor="checkbox"
        data-ripple-dark="true"
      >
        <input
          type="checkbox"
          className={`before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none ${true ? 'rounded-full' : 'rounded-md'} border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-blue-500 checked:bg-blue-500 checked:before:bg-blue-500 hover:before:opacity-10`}
          id="checkbox"
        />
        <div className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-3.5 w-3.5"
            viewBox="0 0 20 20"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth="1"
          >
            <path
              fillRule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clipRule="evenodd"
            ></path>
          </svg>
        </div>
      </label>
    </div>

    </div>



   
  </div>
</>
    ))}
</div>
  )
}


export function SettingsNoteView({}) {

    const {
      control,
      register,
      handleSubmit,
      watch,
      formState: {errors, isValid}
    } = useForm({mode: "onChange"})
    const {user, updateUser, loading} = useAuth();
    const [templateNames, setTemplateNames, loadingTemplateNames] = useStorageHook("templates", null);
    const [customInstructions, setCustomInstructions, loadingCustomInstructions] = useStorageHook("customInstructions", null);

    useEffect(() => {
        // console.log("templateNames: ", templateNames)
        // console.log("loadingTemplateNames: ", loadingTemplateNames)
        const getTemplatesAsync = async () => {
          if (!loadingTemplateNames) {
            console.log("Calling AuthService.getTemplates...")
            const response = await AuthService.getTemplates();
            console.log("GOT RESPONSE: ", response.data.templates)
            setTemplateNames(response.data.templates)
          }
        }
        getTemplatesAsync();
    }, [loadingTemplateNames]);
  
    const onSubmit = async (data) => {
      // submit user update using the User Provider.
      console.log("customIn: ", data.customInstructions)
      setCustomInstructions(data.customInstructions)
      updateUser(data)
    }
    
    useEffect(() => {
        // TypeScript users 
        // const subscription = watch(() => handleSubmit(onSubmit)())
        const subscription = watch(handleSubmit(onSubmit));
        return () => subscription.unsubscribe();
    }, [handleSubmit, watch, user]);

    console.log("templateNames: ", templateNames)
    const note_template_options = templateNames != null ? TEMPLATE_NAMES.map(itemName => {
      console.log("itemName ", itemName);
      const templateDict = templateNames[itemName.toUpperCase()]
      // const {key, title, description} = templateDict;
      console.log("templateDict: ", templateDict)
      const key = templateDict['key']
      const title = templateDict['title']
      const description = templateDict['description']
      console.log(key, title, description);
      return {
        value: key,
        label: (
          <div className="">
            <div className="font-bold text-black text-[14px]">{title}</div>
            <div className="text-grey-300 text-[12px]">{description}</div>
          </div>
        )
      }
    }) : []

    console.log("NOTE TEMPLATE: ", user)
  
    return (<>
     {
          loading || loadingTemplateNames || loadingCustomInstructions || note_template_options.length === 0
          ? (
            <div className="flex flex-col">
            <div className="flex flex-col items-center justify-center mb-[24px] h-[64px]">
                <div role="status">
                    <svg aria-hidden="true" className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                    </svg>
                    <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          ): (<>
      <div className="flex flex-col ">
        <div className="text-[14px] font-medium text-primary mb-[4px] ">
        Note template
        </div>           
        <ReactSelect
          name="note_template"
          options={
            note_template_options
            // [
            //   { value: 'general_medicine', label: 'Default - Multiple Sections'},
            //   { value: 'apso_merged', label: 'APSO - Merged AP'},
            //   { value: 'cardiology', label: 'Cardiology'},
            //   { value: 'diet', label: 'Dietician'},
            //   { value: 'psychiatry_soap', label: 'Psychiatry - SOAP'},
            //   { value: 'psychiatry', label: 'Psychiatry'},
            //   { value: 'psychology', label: 'Psychology'},
            //   { value: 'soap_merged', label: 'SOAP - Merged AP'},
            //   { value: 'soap', label: 'SOAP'},
            //   { value: 'vet_medicine_soap', label: 'Vet. Med. SOAP'},
            // ]
          }
          defaultValue={user && user.note_template}
          control={control}
          isSearchable={false}
        />
      </div>

      <div className="flex flex-col ">
        <div className="text-md font-medium text-primary mb-[4px] ">
        Note format
        </div>                            
        <MultiCheckbox name="note_format" labelClassName="mr-[12px]" control={control} defaultValue={user && user.note_format} options={[
          {"value": "AUTO", "label": "Auto"},
          {"value": "BULLET", "label": "Bullet"},
          {"value": "PARAGRAPH", "label": "Paragraph"},
        ]}/>
      </div>

      <div className="flex flex-col ">
        <div className="text-md font-medium text-primary mb-[4px] ">
        Custom Instructions
        </div>                            
        <textarea
            name="customInstructions"
            placeholder="Eg: Add more detail to the Physical Exam"
            autoComplete="off"
            className="text-[14px] w-full rounded border border-solid border-[#00000077] bg-transparent p-[8px] leading-[24px]  !outline-none"
            {...register(
              "customInstructions",
              {
                value: customInstructions
              }
            )}
          />
      </div>

      {/* <div className="flex flex-col ">
        <div className="text-md font-medium text-primary mb-[4px] ">
          Note sections
        </div>
        <NoteCustomizer />
      </div> */}

  
      {/* <div className="flex flex-col ">
        <div className="text-[14px] font-medium text-primary mb-[4px] ">
        Note Quality Generation
        </div>                            
        <MultiCheckbox name="note_generation_mode" labelClassName="mr-[12px]" control={control} defaultValue={(user && user.note_generation_mode) || DEFAULT_NOTE_GENERATION_MODE} options={[
          {"value": "highest_quality", "label": "Highest Quality"},
          {"value": "balanced", "label": "Balanced"},
          {"value": "fastest", "label": "Fastest Speed"},
        ]}/>
      </div> */}

      </>)}
  
    </>);
  }
  