import axios from "axios";
import { createContext, useContext, useMemo , useEffect, useState, useRef} from "react";
import { useEffectAsync } from '@chengsokdara/react-hooks-async'
import { v4 as uuidv4 } from 'uuid';

import { useStorageHook } from "./useLocalStorage";
import { AuthService } from './useAuth';
import { BASE_URL } from "../constants";

// [
//     {
//         "audioFeedbackEncryptionKey": null,
//         "completedNoteGenerations": [
//           // A completed note generation represents a generated note from useDigest, when the user clicks
//           // the button to generate the note. You can have multiple generations for the same note,
//           // where a new note can have transcript items from a new Transcript Session. Or a new base note.
        //   {
        //     "completedAt": null,
        //     "note": {
        //         "initialSections": [
  
        //         ],
        //         "parameters": {
        //           "local": "ENGLISH_UK",
        //           "template": "GENERAL_MEdiciNE_SPLIT_BY_PROBLEM",
        //           "transcriptItemUuids": [
  
        //           ],
        //           "trigger": "MANUAL",
        //           "type": "FULL"
        //         },
        //         "processedTranscriptItemUuids": [
 
        //         ],
        //         "sections": [

        //         ]
  
        //     },
        //     "status": "SUCCESS",
        //   },
//           {
//             "completedAt": null,
//             "note": {
//                 "initialSections": [
  
//                 ],
//                 "parameters": {
//                   "local": "ENGLISH_UK",
//                   "template": "GENERAL_MEdICINE_SPLIT_BY_PROBLEM",
//                   "baseTranscriptItemUuids": [
  
//                   ],
//                   "newTranscriptItemUuids": [
  
//                   ],
//                   "trigger": "MANUAL",
//                   "type": "INCREMENTAL"
//                 },
//                 "processedTranscriptItemUuids": [
  
//                 ],
//                 "sections": [
  
//                 ]
//             },
//             "status": "SUCCESS",
//           }
//         ],
//         "createdAt": 1701290411000,
//         "freeText": "",
//         "isImportedViaAirdrop": false,
//         "pendingNoteGeneration": null,
//         "rating": null,
//         "title": null,
//         "transcript": {
//             "sessions": [
//               // A transcript session represents a uninterrupted reocrding during an encounter.
//               // every time the doctor clicks/start and stop, a new transcript session is started.
//                 {
//                     "endedAt": 1701291155102,
//                     "hadUnstableNetwork": false,
//                     "items": [
//                         {
//                             "endOffsetMs": 80533,
//                             "isFinal": false,
//                             "speaker": "UNSPECIFIED",
//                             "startOffsetMs": 78083,
//                             "text": "Staying 1234",
//                             "uuid": "b734893c-94ae-4208-99de-e66657118496"
//                         }
//                     ],
//                     "locale": "ENGLISH_US",
//                     "model": "AUTO",
//                     "startedAt": 1701291074658,
//                     "uuid": "4551867f-a732-47a6-9731-7f44d6a0b7b1"
//                 },
//                 ...
//             ]
//         },
//         "transcriptQuotes": [],
//         "type": "IN_PERSON",
//         "updatedAt": 1701297460211,
//         "uuid": "a1f1edc4-9c9a-4808-a1fc-2a77d252614a"
//     }
//   ]

const EncounterContext = createContext();

const defaultNoteObject = {
  uuid: null,
  type: null,
  transcriptQuotes: null,
  updatedAt: null,
  audioFeedbackEncryptionKey: null,
  completedNoteGenerations: null,
  createdAt: null,
  freeText: null,
  isImportedViaAirdrop: null,
  pendingNoteGeneration: null,
  rating: null,
  title: null,
  transcript: {
    sessions: []
  }
}

const defaultTranscriptObject = {
  uuid: null,
  endedAt: null,
  hadUnstableNetwork: null,
  items: [],
//   items: [
//     {
//         "endOffsetMs": 80533,
//         "isFinal": false,
//         "speaker": "UNSPECIFIED",
//         "startOffsetMs": 78083,
//         "text": "Staying 1234",
//         "uuid": "b734893c-94ae-4208-99de-e66657118496"
//     }
//   ],
  locale: null,
  model: null,
  startedAt: null,
}

function getMonthYear () {
    let dateObj = new Date();
    let month = dateObj.getUTCMonth() + 1; //months from 1-12
    let day = dateObj.getUTCDate();
  
    return month + "/" + day;
  }
  

const createNewEncounter = () => {
    return {
      audioFeedbackEncryptionKey: null,
      completedNoteGenerations: [],
      createdAt: new Date().getTime(),
      freeText: '',
      isImportedViaAirdrop: false,
      pendingNoteGeneration: null,
      rating: null,
      title: "Consultation " + getMonthYear(),
      transcript: {
        sessions: [],
      },
      transcriptQuotes: [],
      type: 'IN_PERSON',
      updatedAt: null,
      uuid: uuidv4(),
    };
};

const addTranscriptSession = (userOverrides = {}) => {
    return {
      endedAt: null,
      hadUnstableNetwork: false,
      items: [],
      locale: 'ENGLISH_US', // Default locale, adjust as needed
      model: 'AUTO', // Default model, adjust as needed
      startedAt: new Date().getTime(),
      uuid: uuidv4(),
      duration: 0,
      ...userOverrides, // Apply user-provided overrides
    };
};

const encounterReducer = (encounters, action) => {
    const { index } = action;
    const updatedEncounters = [...encounters];
  
    switch (action.type) {
      case 'CREATE_NEW_ENCOUNTER':
        return [...encounters, createNewEncounter()];
      case 'LOAD_ENCOUNTER':
        return [...encounters, action.payload];
      case 'ADD_NOTE_GENERATION':
        updatedEncounters[index] = {
          ...updatedEncounters[index],
          completedNoteGenerations: [...updatedEncounters[index].completedNoteGenerations, action.payload],
        };
        return updatedEncounters;
      case 'ADD_TRANSCRIPT_SESSION':
        const newTranscriptSession = {...addTranscriptSession(), ...action.payload}
        updatedEncounters[index] = {
          ...updatedEncounters[index],
          transcript: {
            sessions: [...updatedEncounters[index].transcript.sessions, newTranscriptSession],
          },
        };
        return updatedEncounters;
      case 'UPDATE_TRANSCRIPT_SESSION':
        console.log("UPDATE_TRANSCRIPT_SESSION - ", index, ", action.payload=",action.payload )
        updatedEncounters[index] = {
          ...updatedEncounters[index],
          transcript: {
            sessions: action.payload,
          },
        };
        return updatedEncounters;
      case 'UPDATE_ENCOUNTER_FIELDS':
        console.log("UPDATE_ENCOUNTER_FIELDS - ", index, ", action.payload=", action.payload, updatedEncounters, updatedEncounters[index])
        updatedEncounters[index] = {
          ...updatedEncounters[index],
          ...action.payload,
        };
        return updatedEncounters;
      case 'UPDATE_NOTE_GENERATION':
        updatedEncounters[index] = {
            ...updatedEncounters[index],
            completedNoteGenerations: action.payload,
        };
        return updatedEncounters;
      case 'DELETE_ALL_ENCOUNTERS':
          // delete all but the first.
          if (updatedEncounters.length > 1) {
            return [updatedEncounters[updatedEncounters.length - 1]];
          }
          return []
      case 'DELETE_ENCOUNTER':
          console.log("Encounter: ", updatedEncounters)
          const newUpdatedEncounters = updatedEncounters.filter(encounter => encounter.uuid !== action.payload)
          console.log("NEW Encounter: ", newUpdatedEncounters)
          return newUpdatedEncounters;
      default:
        return encounters;
    }
};

export const EncountersProvider = ({ children, initialEncounterUUID = null }) => {
  const [encounters, setEncounters, initialLoadingOfEncounters] = useStorageHook('encounters', []);

  // const [currentIndex, setCurrentIndex] = useState(null);
  const [currentEncounter, setCurrentEncounterState] = useState(null);

  const encountersRef = useRef([]);
  const currentIndexRef = useRef(null);
  const currentEncounterRef = useRef(null);

  const setCurrentIndex = (value) => {
    currentIndexRef.current = value
  }
  const setCurrentEncounter = (value) => {
    currentEncounterRef.current = value
    setCurrentEncounterState(value)
  }

  useEffect(() => {
    encountersRef.current = encounters
  }, [encounters])

  useEffect(() => {
    if (initialLoadingOfEncounters) {
      // Add loading state or indicator if needed
      return;
    }

    if (!encounters.length) {
      // If no encounters exist, create a new one
      const newEncounter = createNewEncounter();
      setEncounters([newEncounter]);
      currentIndexRef.current = 0
    } else if (initialEncounterUUID) {
      const index = encounters.findIndex((encounter) => encounter.uuid === initialEncounterUUID);
      console.log("FOUND InitialEncoutnerIDX: ", index)
      if (index >= 0) {
        setCurrentIndex(index);
        currentIndexRef.current = index
      } else {
        console.error(`Encounter with UUID ${initialEncounterUUID} not found.`);
        // Handle error or set a default index
      }
    } else {
      // Load the latest encounter
      setCurrentIndex(encounters.length - 1);
    }
  }, [initialLoadingOfEncounters]);

  useEffect(() => {
    if (currentIndexRef.current) {
      setCurrentEncounter(encounters[currentIndexRef.current])
    }
  }, [encounters])

  const dispatchWithStorageUpdate = (action) => {
    // Update the storage after each action
    console.log("CURRENT encounter -> ", encountersRef.current)
    const newState = encounterReducer(encountersRef.current, action);
    console.log("NEW encounter -> ", newState)
    setEncounters(newState);
    // setCurrentIndex(newState.length - 1);
  };

  const updateEncounterUUID = (newEncounterUUID) => {
    console.log("updateEncounterUUID called ", newEncounterUUID)
    const index = encounters.findIndex((encounter) => encounter.uuid === newEncounterUUID);
    console.log("updateEncounterUUID.index ", index)
    console.log("updateEncounterUUID.encounters ", encounters)
    if (index >= 0) {
      currentIndexRef.current = index
      setCurrentEncounter(encounters[index]);
      console.log("INTERNAL currentEncounter: ", currentEncounterRef.current)
      console.log("INTERNAL currentIndex: ", currentIndexRef.current)
    } else {
      console.error(`Encounter with UUID ${newEncounterUUID} not found.`);
      // Handle error or set a default index
    }
  };

  const updateTranscriptSession = (sessionIndex, updatedSession) => {
    if (currentEncounterRef.current && currentEncounterRef.current.transcript.sessions.length > sessionIndex) {
      const updatedSessions = currentEncounterRef.current.transcript.sessions.map((session, index) =>
        index === sessionIndex ? updatedSession : session
      );
      dispatchWithStorageUpdate({
        type: 'UPDATE_TRANSCRIPT_SESSION',
        payload: updatedSessions,
        index: currentIndexRef.current,
      });
    }
  };

  const updateEncounterFields = (fields) => {
    console.log("updateCurrentTranscriptItems.currentEncounter: ", encounters, currentEncounterRef.current, currentIndexRef.current)
    if (currentEncounterRef.current) {    
      dispatchWithStorageUpdate({
        type: 'UPDATE_ENCOUNTER_FIELDS',
        payload: fields,
        index: currentIndexRef.current,
      });
    }
  };

  const updateCurrentTranscriptItems = (updatedTranscriptItems) => {
    console.log("updateCurrentTranscriptItems.currentEncounter: ", currentEncounterRef.current, currentIndexRef.current)
    if (currentEncounterRef.current && currentEncounterRef.current.transcript.sessions.length > 0) {
      const lastSessionIndex = currentEncounterRef.current.transcript.sessions.length - 1;
      // const currentSession = currentEncounterRef.current.transcript.sessions[lastSessionIndex];

      const updatedSessions = currentEncounterRef.current.transcript.sessions.map((session, index) =>
        index === lastSessionIndex ? { ...session, items: updatedTranscriptItems } : session
      );
    
      dispatchWithStorageUpdate({
        type: 'UPDATE_TRANSCRIPT_SESSION',
        payload: updatedSessions,
        index: currentIndexRef.current,
      });
    }
  };

  const updateCurrentTranscriptDuration = (updatedDuration) => {
    console.log("updateCurrentTranscriptItems.currentEncounter: ", currentEncounterRef.current, currentIndexRef.current)
    if (currentEncounterRef.current && currentEncounterRef.current.transcript.sessions.length > 0) {
      const lastSessionIndex = currentEncounterRef.current.transcript.sessions.length - 1;
      // const currentSession = currentEncounterRef.current.transcript.sessions[lastSessionIndex];

      const updatedSessions = currentEncounterRef.current.transcript.sessions.map((session, index) =>
        index === lastSessionIndex ? { ...session, duration: updatedDuration } : session
      );
    
      dispatchWithStorageUpdate({
        type: 'UPDATE_TRANSCRIPT_SESSION',
        payload: updatedSessions,
        index: currentIndexRef.current,
      });
    }
  };

  const updateNoteSection = (updatedFields) => {
    if (currentEncounterRef.current && currentEncounterRef.current.completedNoteGenerations.length > 0) {
      const lastGenerationIndex = currentEncounterRef.current.completedNoteGenerations.length - 1;
      const currentGeneration = currentEncounterRef.current.completedNoteGenerations[lastGenerationIndex];

      if (currentGeneration.status === 'SUCCESS') {
        const updatedSections = currentGeneration.note.sections.map((section) => {
          if (section.key == updatedFields.key) {
            return {
              ...section,
              ...updatedFields,
            }
          }
          return section
        });

        const updatedGenerations = currentEncounterRef.current.completedNoteGenerations.map((generation, index) =>
          index === lastGenerationIndex
            ? { ...generation, note: { ...generation.note, sections: updatedSections } }
            : generation
        );

        dispatchWithStorageUpdate({
          type: 'UPDATE_NOTE_GENERATION',
          payload: updatedGenerations,
          index: currentIndexRef.current,
        });
      }
    }
  };

  const clearEncounters = async () => {
    await dispatchWithStorageUpdate({ type: 'DELETE_ALL_ENCOUNTERS' })
  };

  const deleteEncounter = async (uuid) => {
    console.log("HOOK: DELETING ENCOUNTER: ", uuid)
    await dispatchWithStorageUpdate({ type: 'DELETE_ENCOUNTER', payload: uuid })
  };

  const value = {
    currentEncounter,
    encounters,
    updateCurrentTranscriptItems,
    addNoteGeneration: (noteGeneration) => dispatchWithStorageUpdate({ type: 'ADD_NOTE_GENERATION', payload: noteGeneration, index: currentIndexRef.current }),
    addTranscriptSession: (transcriptSession) => dispatchWithStorageUpdate({ type: 'ADD_TRANSCRIPT_SESSION', payload: transcriptSession, index: currentIndexRef.current}),
    saveEncounter: () => setEncounters(encounters),
    startNewEncounter: () => dispatchWithStorageUpdate({ type: 'CREATE_NEW_ENCOUNTER' }),
    updateEncounterUUID,
    updateNoteSection,
    clearEncounters,
    deleteEncounter,
    updateEncounterFields,
    updateCurrentTranscriptDuration,
  };

  return <EncounterContext.Provider value={value}>{children}</EncounterContext.Provider>;
};


export const useEncounters = () => {
  return useContext(EncounterContext);
};
