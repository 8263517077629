/*global chrome*/

import { useEffect, useState } from "react";
import { useEffectAsync } from '@chengsokdara/react-hooks-async'
import { v4 as uuidv4 } from 'uuid';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import { useStorageHook, getTokensFromStorage, setTokensFromStorage } from "./useLocalStorage";


const generateDeviceIdFromUUID = async () => {
    console.log("generateDeviceIdFromChromeInstanceID...")
    return Promise.resolve().then(function () {
        return uuidv4()
    });
};

const generateDeviceIdFromChromeInstanceID = async () => {
    console.log("generateDeviceIdFromUUID...")
    return new Promise((resolve, reject) => {
        chrome.instanceID.getID((instanceID) => {
            resolve(instanceID)
        })
    })
};


const getDeviceName = () => {
    
    if (process.env.REACT_APP_BUILD_PLATFORM === "chrome") {
        return "Chrome"
    }

    if (isMobile) {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return "Windows";
        }

        if (/android/i.test(userAgent)) {
            return "Android";
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "iPhone";
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad/.test(userAgent) && !window.MSStream) {
            return "iPad";
        }

        return "Mobile";
    }

    return "Desktop"

};



const generateDeviceId = process.env.REACT_APP_BUILT_FOR_EXTENSION === 'true' ? generateDeviceIdFromChromeInstanceID : generateDeviceIdFromUUID;


export const useDeviceId = () => {

    const [device, setDevice, loadingDevice] = useStorageHook("device", null);

    const setDeviceObject = (overrides) => {
        setDevice(Object.assign({}, device, overrides))
    }
  
    useEffectAsync(async () => {
      if (!loadingDevice && !device) {
        const newDeviceId = await generateDeviceId()
        const newDeviceName = getDeviceName()
        setDevice({deviceId: newDeviceId, deviceName: newDeviceName})
      }
    }, [loadingDevice])
  
    return [device, setDeviceObject, loadingDevice];
  
  };