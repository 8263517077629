import React, {useState, useEffect, createRef} from 'react';
import {

  Link,
  Outlet,
  useLocation,
} from "react-router-dom";
import styled, {keyframes} from "styled-components";

import { 

  ButtonTextContainer,
  ButtonContainer,
  ButtonChevronContainer, 
  ButtonSeparator} from '../../components/forms';

import {

  MicrophoneSvgIcon,
  ChevronSvgIcon,
  CopySvgIcon,

  CheckMarkSvgIcon,
  PauseSvgIcon,
  RedoSvgIcon,
  TrashSvgIcon
} from '../../components/svgs'

import { useAuth } from "../../hooks/useAuth";

import { ChromeDraggableElement, useMinimizedStateContext } from '../../App';



export const CustomScrollContainer = styled.div`
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  overscroll-behavior-y: contain;
  padding: 8px;
`;


const blinkAnimation = keyframes`
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
`
const RecorderDot = styled.div`
 animation-name: ${blinkAnimation};
 animation-duration: 2s;
 animation-iteration-count: infinite;
`




function MainTranscribeAndNotesNav({recording,  shouldShowNoteTab}) {
    let location = useLocation();
    const { user, login } = useAuth();
  
    console.log("User ", user)
  
    if (!location) {
      return (<></>)
    }

    const activeStyle = {
      background: "linear-gradient(0deg,#282828 0%,#282828 100%) padding-box,linear-gradient(180deg,#6e6e6e,#2f2f2f) border-box",
      border: "1px solid",
      borderImageSource: "linear-gradient(180deg,#b8b8b8 -76.62%,rgba(184,184,184,0) 131.05%)",
    }
  
    return (
      <>
      {/* Tabs */}
      <div className="w-full md:w-1/2 md:mx-auto flex flex-shrink-0 rounded-[8px] h-[38px] items-center justify-center font-semibold p-[4px] cursor-default bg-primary-dark bg-white">
      <Link to="note" className="flex flex-fill h-full">
          <div style={location.pathname.includes('note') ? activeStyle : {}} className={`text-sm flex flex-1 items-center justify-center font-medium h-full rounded-sm shadow-sm ${location.pathname.includes('note') ? 'card text-white' : 'text-black text-shaded hover:text-gray-500 unselectedtab'} ${shouldShowNoteTab ? '' : 'pointer-events-none'  }`}>
            <div className="flex items-center">
              <span>Note</span>
              <RecorderDot className="h-[4px] w-[4px] rounded-full bg-red-600 ml-[6px] invisible" /> {/*  invisible */ }
            </div>
          </div>
        </Link>
        <Link to="transcribe" className="flex flex-fill h-full active" data-status="active">
          <div style={location.pathname.includes('transcribe') ? activeStyle : {}} className={`text-sm flex flex-1 items-center justify-center font-medium h-full rounded-sm shadow-sm ${location.pathname.includes('transcribe') ? 'card text-white' : 'text-black text-shaded hover:text-gray-500 unselectedtab'}`}>
            <div className="flex items-center">
              <span>Transcript</span>
              <RecorderDot className={`h-[12px] w-[12px] rounded-full bg-red-600 ml-[6px] ${!recording ? 'invisible' : ''}`} /> {/*  invisible */ }
            </div>
          </div>
        </Link>
      </div>
      {/* ./End Tabs */}
      </>
    )
  }
  
  
  export function MainTranscribeAndNotesScreen({
    shouldShowNoteTab,
    isNewNote,
    isLoadingNote,
    recording,
    loadingRecorder,
    onEndRecording,
    onResumeRecording,
    onStartRecording,
    onPauseRecording,
    onClickCopyTranscript,
    onClickCopyNote,
    onClickRegenerateNote,
    onDiscardConsultation,
    hasTranscript,
    hasNote,
    error,
    displayCheck,
    duration,
}) {

  const {isMinimized, toggle} = useMinimizedStateContext()
  let location = useLocation();
  
    const [isMainButtonOpen, setIsMainButtonOpenSetter] = useState(false);
    const [stopState, SetstopState] = useState();

    // const onMouseUp = () => {
    //     setIsMainButtonOpenSetter(false)
    //     window.removeEventListener('mouseup', onMouseUp);
    // }

    const setIsMainButtonOpen = (value) => {
      // window.addEventListener('mouseup', onMouseUp);
      setIsMainButtonOpenSetter(value)
    }

    const buttonTextContainerExtraStyles = isMinimized ? {
      borderRadius: "8px",
      paddingTop: "4px",
      paddingBottom: "4px",
      fontSize: "14px",
    } : {}
  
    return (<>

      {error && 
        <div className="flex flex-col w-full p-[4px] bg-red-500 text-center mb-[8px]">
          <span className=" text-[12px] text-align-center font-bold text-white">{error}</span>
        </div>
      }
    
     {/* MainContent */}
     <div className="flex flex-col w-full h-full px-[8px] pb-[8px] flex-fill space-y-[8px] justify-end">
  
     {!isNewNote && !isMinimized && <MainTranscribeAndNotesNav recording={recording} shouldShowNoteTab={shouldShowNoteTab}/>}

      {/* MainContent */}
      {!isMinimized && (
        <div className="flex flex-col flex-fill" style={{animationDuration: "200ms"}}>
          <div className="flex flex-col w-full gap-[8px] flex-fill">

            {/* {error && 
              <div className="p-[4px] bg-red-500 text-center">
                <span className="text-white text-[12px] text-align-center">{error}</span>
              </div>
            } */}

            {/* TranscriptContent */}
            <div className="flex flex-col flex-fill space-y-[8px]">
              <div className={`flex flex-fill ${process.env.REACT_APP_BUILD_PLATFORM === "chrome" && "card"} rounded`}>
                <CustomScrollContainer className="flex flex-fill flex-col space-y-[12px]">
      
                    {<Outlet context={[
                        shouldShowNoteTab,
                        isNewNote,
                        isLoadingNote,
                        recording,
                        loadingRecorder,
                        onEndRecording,
                        onResumeRecording,
                        onStartRecording,
                        onPauseRecording,
                        onClickCopyTranscript,
                        onClickCopyNote,
                        onClickRegenerateNote,
                        onDiscardConsultation,
                        hasTranscript,
                        hasNote,
                        error,
                        displayCheck,
                        duration
                    ]}/>}
      
                </CustomScrollContainer>
              </div>
            </div>
            {/* ./End TranscriptContent */}
          
          </div>
        </div>
      )}
      {/* ./End MainContent */}

      {/* <div class="bg-white rounded-lg w-72 border shadow block p-4">
          <div class="w-full h-4 bg-gray-400 rounded-full">
              <div class="w-[90%] h-full text-center text-xs text-white bg-violet-500 rounded-full">
                  75%
              </div>
          </div>
      </div> */}
  
      {/* ActionButton */}
      <div className="flex md:absolute md:bottom-[32px] md:right-[44px] md:static md:p-[16px] md:pt-[1px]">
        <div className="flex flex-col flex-grow">
          <ButtonContainer>
  
              {
                isLoadingNote && (
                <ButtonTextContainer style={buttonTextContainerExtraStyles}>
                  <div className="flex justify-center items-center space-x-[4px] text-white">
                    <div role="status" className="mr-[4px]">
                      <svg aria-hidden="true" className="w-[18px] h-[18px] text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                      </svg>
                      <span className="sr-only">Loading...</span>
                    </div>
                    <div>Generating your note...</div>
                  </div>
                </ButtonTextContainer>
                )
              }
              {
                !isLoadingNote && !isNewNote && recording && (
                <ButtonTextContainer style={buttonTextContainerExtraStyles} onClick={onEndRecording}>
                  <div className="flex justify-center items-center space-x-[4px] text-white">
                    <CheckMarkSvgIcon />
                    <div>End consultation</div>
                  </div>
                </ButtonTextContainer>
                )
              }
              {
                !isLoadingNote && !isNewNote && !recording && (!hasNote || !location.pathname.includes('note')) && (
                <ButtonTextContainer style={buttonTextContainerExtraStyles} onClick={onResumeRecording}>
                  <div className="flex justify-center items-center space-x-[4px] text-white">
                  {
                    loadingRecorder
                    ? (<><MicrophoneSvgIcon /><div>Resume Consultation</div></>)
                    : (
                      <div className="flex justify-center items-center space-x-[4px] text-white">
                        <div role="status" className="mr-[4px]">
                          <svg aria-hidden="true" className="w-[18px] h-[18px] text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                          </svg>
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    )
                  }
                  </div>
                </ButtonTextContainer>
                )
              }
              {
                !isLoadingNote && !isNewNote && !recording && hasNote && location.pathname.includes('note') && (
                <ButtonTextContainer style={buttonTextContainerExtraStyles} onClick={onClickCopyNote}>
                  <div className="flex justify-center items-center space-x-[4px] text-white">
                    {/* <CopySvgIcon /> */}
                    {displayCheck ? <CheckMarkSvgIcon className="text-green-500" /> : <CopySvgIcon /> }
                    <div>Copy note</div>
                  </div>
                </ButtonTextContainer>
                )
              }
              {
                !isLoadingNote && isNewNote && (
                <ButtonTextContainer style={buttonTextContainerExtraStyles} onClick={onStartRecording}>
                  <div className="flex justify-center items-center space-x-[4px] text-white">
                      {
                        loadingRecorder
                        ? (<><MicrophoneSvgIcon /><div>Start Consultation</div></>)
                        : (
                          <div className="flex justify-center items-center space-x-[4px] text-white">
                            <div role="status" className="mr-[4px]">
                              <svg aria-hidden="true" className="w-[18px] h-[18px] text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                              </svg>
                              <span className="sr-only">Loading...</span>
                            </div>
                          </div>
                        )
                      }
                  </div>
                </ButtonTextContainer>
                )
              }

  
            { !isNewNote && !isMinimized && !isLoadingNote && (
              <>
              <ButtonSeparator />
              <ButtonChevronContainer onClick={setIsMainButtonOpen.bind(null, !isMainButtonOpen)}>
                <ChevronSvgIcon className={`text-white transform ${!isMainButtonOpen ? "rotate-180" : ""}`} />
              </ButtonChevronContainer>
              {isMainButtonOpen && (
                <div className="absolute bottom-[63px] right-[8px]  md:bottom-[68px] md:right-[18px] z-10 mt-2 origin-top-right rounded-[8px] bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1">
                  <div className="py-[5px] rounded" style={{background: "linear-gradient(0deg,#282828 0%,#282828 100%) padding-box,linear-gradient(180deg,#6e6e6e,#2f2f2f) border-box"}}>
                    { !isNewNote && !recording && hasTranscript && (!hasNote || !location.pathname.includes('note')) && (<>
                      <ButtonContainer onClick={onClickCopyTranscript}
                        className="text-white block px-4 py-2 text-sm !rounded-none !border-none hover:text-gray-300 cursor-pointer">
                        <div className="flex justify-center items-center space-x-[4px] text-white">
                          {displayCheck ? <CheckMarkSvgIcon className="text-green-500" /> : <CopySvgIcon /> }
                          <div>Copy transcript</div>
                        </div>
                      </ButtonContainer>
                      {error && (
                      <ButtonContainer onClick={onDiscardConsultation}
                        className="text-white block px-4 py-2 text-sm !rounded-none !border-none hover:text-gray-300 cursor-pointer">
                        <div className="flex justify-center items-center space-x-[4px] text-white">
                          <TrashSvgIcon />
                          <div>Discard consultation</div>
                        </div>
                      </ButtonContainer>)}
                    </>)}
                    { !isNewNote && !recording && hasTranscript && hasNote && location.pathname.includes('note') && (<>
                      <ButtonContainer onClick={onClickRegenerateNote}
                        className="text-white block px-4 py-2 text-sm !rounded-none !border-none hover:text-gray-300 cursor-pointer">
                        <div className="flex justify-center items-center space-x-[4px] text-white">
                          <RedoSvgIcon className="text-white"/>
                          <div>Regenerate note</div>
                        </div>
                      </ButtonContainer>
                    </>)}
                    { !isNewNote && recording && (
                      <ButtonContainer onClick={() => {onPauseRecording(); setIsMainButtonOpen(false)}} className="text-white block px-4 py-2 text-sm !rounded-none !border-none hover:text-gray-300 cursor-pointer">
                        <div className="flex justify-center items-center space-x-[4px] text-white">
                          <PauseSvgIcon className="text-white"/>
                          <div>Pause consultation</div>
                        </div>
                      </ButtonContainer>
                    )}
                    { false && (
                      <ButtonContainer onClick={() => console.log("CRegenerate Note clicked")}
                        className="text-white block px-4 py-2 text-sm !rounded-none !border-none hover:text-gray-300">
                        <div className="flex justify-center items-center space-x-[4px] text-white">
                          <RedoSvgIcon className="text-white"/>
                          <div>Regenerate Note</div>
                        </div>
                      </ButtonContainer>
                    )}
                  </div>
                </div>
              )}
              </> 
            )}
          </ButtonContainer>
        </div>
      </div>
      {/* ./End ActionButton */}
  
      </div>
      {/* ./End MainContent */}
  
    </>)
  }
  