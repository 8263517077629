import React, {useState, useEffect, useRef, createContext, useContext, useMemo } from 'react';
// import { tabs,runtime } from 'webextension-polyfill'
import {
  BrowserRouter,
  MemoryRouter,
  Routes,
  Route,
  Outlet,
  useLocation,
  Navigate,
  Link,
  useNavigate
} from "react-router-dom";
import styled from 'styled-components'

import {
  SettingsLayout,
  SettingsGeneralView,
  SettingsAccountView,
  SettingsNoteView } from "./pages/settings";

import {
  HistoryView,
  PastNoteView,
} from "./pages/history"

import {
  ContactView
} from "./pages/ContactPage"

import {
  GeneralInfoPage,
  SignupPage,
  LoginPage,
  InviteCodePage,
  ConfirmPage,
} from "./pages/auth";
import { HomePage } from "./pages/WelcomePage"

import {
  TranscriptView,
  NotesView,
  NoteLayout,
  EncounterView
} from "./pages/notes"

import logo from './logo.svg';
import './App.css';

import {
  MainWidgetHeader
} from './components/layouts'
import { ProtectedRoute } from './components/ProtectedRoute';

// hooks
import { useEnumerateDevices, EnumerateDevicesProvider } from './hooks/useEnumerateDevices';
import { useEncounters, EncountersProvider } from './hooks/useEncounters';
import { AuthProvider, useAuth } from "./hooks/useAuth";
import { NoteDigestProvider } from "./hooks/useNoteDigest"

import { BASE_URL } from "./constants"

import {
  MicrophoneSvgIcon,
  HistoryIconSvg,
  SettingsIconSvg,
  MailIconSvg,
  BackSvgIcon,
} from './components/svgs'
import {
  KeenIcon
} from './components/keenicons'


const DEMO_NOTE = [
  {
      "content": [
          "32-year-old male referred by family doctor for severe acne."
      ],
      "key": "CHIEF_COMPLAINT",
      "text": "32-year-old male referred by family doctor for severe acne.",
      "title": "Chief Complaint"
  },
  {
      "content": [
          "Patient presents with severe acne located on the lower chin, cheeks with scarring, and back. Previous unsuccessful treatments include various topical creams and retinoids."
      ],
      "key": "HISTORY_OF_PRESENT_ILLNESS",
      "text": "Patient presents with severe acne located on the lower chin, cheeks with scarring, and back. Previous unsuccessful treatments include various topical creams and retinoids.",
      "title": "History of Present Illness"
  },
  {
      "content": [
          "Upon exam, patient has visible acne on lower chin, cheeks with severe scarring, and pronounced acne on back."
      ],
      "key": "PHYSICAL_EXAM",
      "text": "Upon exam, patient has visible acne on lower chin, cheeks with severe scarring, and pronounced acne on back.",
      "title": "Physical Exam"
  },
  {
      "content": [
          "Assessment indicates a severe case of acne requiring pharmacological intervention."
      ],
      "key": "ASSESSMENT",
      "text": "Assessment indicates a severe case of acne requiring pharmacological intervention.",
      "title": "Assessment"
  },
  {
      "content": [
          "Start treatment with Accutane 50 mg daily for three months, monthly blood work, and a follow-up appointment in two months."
      ],
      "key": "PLAN",
      "text": "Start treatment with Accutane 50 mg daily for three months, monthly blood work, and a follow-up appointment in two months.",
      "title": "Plan"
  },
  {
      "content": [
          "Accutane 50 mg orally, once daily for three months."
      ],
      "key": "PRESCRIPTION",
      "text": "Accutane 50 mg orally, once daily for three months.",
      "title": "Prescriptions"
  },
  {
      "content": [
          "Scheduled follow-up appointment in two months and monthly blood work requisition given."
      ],
      "key": "APPOINTMENTS",
      "text": "Scheduled follow-up appointment in two months and monthly blood work requisition given.",
      "title": "Appointments"
  }
];


export function ChromeDraggableElement({className, children}) {

  const mouseRef = useRef("NO_DRAG")

  const onMouseDown = (event) => {
    if (mouseRef.current === "NO_DRAG") {
      mouseRef.current = {
        lastScreenX: event.screenX,
        lastScreenY: event.screenY
      }
    }
  };

  const onMouseUp = (event) => {
    if (mouseRef.current !== "NO_DRAG") {
      mouseRef.current = "NO_DRAG"
    }
  };

  const onMouseMove = (event) => {
    if (mouseRef.current !== "NO_DRAG") {
      if (event.buttons % 2 === 1) {
          const dX = event.screenX - mouseRef.current.lastScreenX;
          const dY = event.screenY - mouseRef.current.lastScreenY;
          chrome.runtime.sendMessage({
              type: "ON_DRAG",
              dX,
              dY,
          });
          mouseRef.current = {
              lastScreenX: event.screenX,
              lastScreenY: event.screenY
          }
      } else {
        mouseRef.current = "NO_DRAG";
      }
      event.preventDefault();
      event.stopPropagation();
    }
  };

  useEffect(() => {
      window.addEventListener('mousemove', onMouseMove);
      window.addEventListener('mouseup', onMouseUp);
      return () => {
        window.removeEventListener('mousemove', onMouseMove);
        window.removeEventListener('mouseup', onMouseUp);
      };
    }, []);

  return (
    <div
      className={className}
      onMouseDown={onMouseDown}
      // onMouseUp={onMouseUp}
      // onMouseMove={onMouseMove}
    >
      {children}
    </div>
  )
}


const MinimizedStateContext = createContext();

export const MinimizedStateProvider = (props) => {

  const [isMinimized, setIsMinimized] = useState(false)

  const toggle = () => {
    setIsMinimized(prev => !prev)
  }

  useEffect(() => {
    setIsMinimized(false)
  }, [])

  // const value = useMemo(
  //   () => ({
  //     isMinimized,
  //     toggle
 
  //   }),
  //   [ isMinimized,
  //     toggle,
  //   ]
  // );

  const value = {
    isMinimized,
    toggle
  };

  return <MinimizedStateContext.Provider value={value}>{props.children}</MinimizedStateContext.Provider>;
};

export const useMinimizedStateContext = () => {
  return useContext(MinimizedStateContext);
};



function AppLayout({children, dims}) {
  if (process.env.REACT_APP_BUILD_PLATFORM !== "chrome") {
    return (
      <>
        {children}
      </>
    )
  }

  return (
    <div className="fixed inset-tr-0 transition-[width,height] ease-in-out p-[1px]" style={{...dims, transitionDuration: "200ms"}}>
      <div className="flex flex-col items-center border rounded h-full text-body"
        style={{
          background: "linear-gradient(rgb(245 245 245 / 80%) 0%, rgb(163 166 177 / 48%) 100%)",
          backdropFilter: "blur(2px)", outline: "rgba(18, 18, 24, 0.5) solid 1px", border: "1px solid rgba(255, 255, 255, 0.5)"
        }}>

          {/* <ChromeDraggableElement className="cursor-move w-full h-[6px] flex-shrink-0" /> */}

        {/* MovableTopBar */}
        <ChromeDraggableElement className="cursor-move w-full h-[6px] flex-shrink-0" />
        {/* ./End MovableTopBar */}


          {children}
      </div>
    </div>
  )
}



function AuthLayout({}) {
  let location = useLocation()
  let navigate = useNavigate()

  if (process.env.REACT_APP_BUILD_PLATFORM === "chrome") {
    return (
      <div className="flex-fill flex space-x-[16px] overflow-hidden rounded px-[6px] pb-[6px] h-full w-full ">
        <div className="card relative flex h-full w-full flex-col p-[16px]" style={{transition: "transform .3s ease-in-out"}}>

          {location.pathname !== "/auth" && <div className="absolute top-[12px] left-[12px]" onClick={() => navigate(-1)}>
              <button type="button">
                <BackSvgIcon className=""/>
              </button>
            </div>
          }

          <Outlet />
        </div>
      </div>
    )
  }

  return (
    <div className="absolute inset-0 bg-white w-full h-full flex items-center justify-center" style={{ transitionDuration: "200ms"}}>
      <div className="absolute p-[32px] mobile:p-[16px] top-0 flex-col items-center gap-[16px]">
        <img src={process.env.PUBLIC_URL + "/notatio_logo_full.png"} style={{
                width: "225px",
                height: "auto",
                margin: "0 auto",
          }}/>
          <div className="flex items-center gap-[4px]">
            <div className={`relative w-[59px] h-[2px] rounded-l-full ${
                location.pathname.includes('signin') ||
                location.pathname.includes('invite') ||
                location.pathname.includes('info') || 
                location.pathname.includes('signup') ||
                location.pathname.includes('confirm') ? 'bg-blue-500' : 'bg-gray-300'}`}>
              <div className="absolute inset-0 bg-primary-light duration-200 transition origin-left rounded-l-full scale-x-full"></div>
            </div>
            <div className={`relative w-[59px] h-[2px] ${
              location.pathname.includes('signin') ||
                location.pathname.includes('info') || 
                location.pathname.includes('signup') ||
                location.pathname.includes('confirm')  ? 'bg-blue-500' : 'bg-gray-300'}`}>
              <div className="absolute inset-0 bg-primary-light duration-200 transition origin-left scale-x-full"></div>
            </div>
            <div className={`relative w-[59px] h-[2px] ${
              location.pathname.includes('signin') ||
                location.pathname.includes('signup') ||
                location.pathname.includes('confirm')  ? 'bg-blue-500' : 'bg-gray-300'}`}>
              <div className="absolute inset-0 bg-primary-light duration-200 transition origin-left scale-x-full"></div>
            </div>
            <div className={`relative w-[59px] h-[2px] rounded-r-full ${
                location.pathname.includes('confirm') ? 'bg-blue-500' : 'bg-gray-300'}`}>
              <div className="absolute inset-0 bg-primary-light duration-200 transition origin-left rounded-r-full scale-x-full"></div>
            </div>
          </div>
      </div>
      {location.pathname !== "/auth/welcome" && <div onClick={() => navigate(-1)}>
            <button className="p-[8px] rounded bg-white border border-solid border-grey-200 shadow-[0px_3px_6px_-2px_rgba(33,42,47,0.08)] absolute start-[24px] top-[24px] mobile:start-[12px] mobile:top-[12px]" type="button">
              <BackSvgIcon />
            </button>
          </div>}
      <div className="w-full h-full flex items-center justify-center max-w-md mobile:max-w-full">
        <div className="w-full flex-col justify-between p-[16px] relative flex-shrink-0" style={{transition: "transform .3s ease-in-out"}}>
          
          <Outlet />
        </div>
      </div>
    </div>
  )
}

const NavigationButtonDesktopOld = styled.button`
  position: relative;
  display: flex;
  border-radius: 8px;
  padding: 12px 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));

`;

const NavigationButtonDesktop = styled.button`
padding: 0;
width: 45px;
height: 45px;
display: flex;
align-items: center;
justify-content: center;
flex-shrink: 0;
flex: 0 0 auto;
border-radius: 0.475rem;
margin: 0 auto;


`;


const NavigationButtonContentWrapperDesktop = styled.div`
  display: flex;
  align-items: center;

  gap: 4px;
  flex: 1 1 auto;
  min-width: 0;
  min-height: 0;
`;

const NavigationButtonContentDesktop = styled.div`
  transition-property: opacity;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  transition-duration: .2s;
  display: flex;
  align-items: center;
  justify-content: center;
`;

// /dashboard/current/transcribe
function NavigationItemDesktop({to, label, iconElement, activePath=null}) {
  let location = useLocation()
  const active = location.pathname.includes(activePath || to)
  return (
    <Link className={`flex flex-col ${active ? "active" : ""}`} to={to} data-status={`${active ? "active" : ""}`}>
      <NavigationButtonDesktopOld className={`text-left text-[14px] leading-[24px] px-[8px] py-[6px] flex hover:bg-white/[.05] ${active ? "!bg-white/[.1]" : ""}`} type="button">
        <NavigationButtonContentWrapperDesktop className="">
          {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" data-icon="microphoneThick" className="text-black">
            <path d="M6.8 6.08C6.8 4.38 8.14 3 9.8 3s3 1.38 3 3.08v2.84c0 1.7-1.34 3.08-3 3.08s-3-1.38-3-3.08V6.08Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M4.6 10.63s.2 3.74 5.2 3.74 5.2-3.74 5.2-3.74M9.8 14.8v2.24" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
          </svg> */}
          {iconElement}
          <NavigationButtonContentDesktop className="text-white">
            {label}
          </NavigationButtonContentDesktop>
        </NavigationButtonContentWrapperDesktop>
      </NavigationButtonDesktopOld>
    </Link>
  );
}

function NavigationDesktop({userAccount, numNotes}) { 
  return (
    <aside className="flex flex-col w-[300px] min-w-[300px] p-[8px] border-r border-gray-200 hidden lg:flex bg-[#111828]">
      <div className="px-[8px] pt-[16px] pb-[18px]">
        <img src={"https://notatio.ai/assets/svg/logos/logo-white-notatio.png"} style={{
                width: "200px",
                height: "auto",
                margin: "",
          }}/>
      </div>
      <div className="flex flex-col">
        <NavigationItemDesktop to={"/dashboard/current/note"} activePath={"/dashboard/current"} label="Current Consultation" iconElement={<MicrophoneSvgIcon className="text-white"/>} />
        <NavigationItemDesktop to={"/dashboard/history"} label="Past Consultations" iconElement={<HistoryIconSvg className="text-white"/>} />
        <NavigationItemDesktop to={"/dashboard/settings/general"} label="Settings" iconElement={<SettingsIconSvg className="text-white" /> }/>
      </div>
      <div className="flex flex-fill relative">
      </div>
      <div className="flex flex-col">
        {userAccount && userAccount == "PRO" && (
          <div class="my-2 w-full">
            <div class="p-3 bg-white/[.05] rounded-[8px]">
              <div class="flex align-items-center">\
                <span class="bg-white/[.1] text-white text-m font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">Pro Plan</span>
              </div>
              <span class="block mt-[8px] ml-[10px] text-white">
                Unlimited access
              </span>
            </div>
          </div>
        )}
        {userAccount && userAccount == "FREE" && (
          <div class="my-2 w-full">
            <div class="p-3 bg-white/[.05] rounded-[8px]">
              <div class="flex align-items-center">\
                <span class="bg-white/[.1] text-white text-m font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">Free Plan</span>
              </div>
              <span class="block mt-[8px] ml-[10px] text-white">
                <strong>{numNotes}</strong> of 30 notes used
              </span>
            </div>
          </div>
        )}
        <NavigationItemDesktop to={"/dashboard/contact-us"} label="Contact" iconElement={<MailIconSvg className="text-white" />} />
      </div>
      {/* <div className="flex-fill"></div> */}
      {/* <div className="p-[8px]"></div> */}
    </aside>
  );
}

function NavigationItemDesktopNewV3({to, label, iconElement, activePath=null}) {
  let location = useLocation()
  const active = location.pathname.includes(activePath || to)
  return (
    <Link className={`flex flex-col content-center py-3 ${active ? "active" : ""}`} to={to} data-status={`${active ? "active" : ""}`}>
      <NavigationButtonDesktop className={`leading-[24px] flex ${active ? "!bg-white !bg-opacity-10" : ""}`} type="button">
        <NavigationButtonContentWrapperDesktop className="">
          {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" data-icon="microphoneThick" className="text-black">
            <path d="M6.8 6.08C6.8 4.38 8.14 3 9.8 3s3 1.38 3 3.08v2.84c0 1.7-1.34 3.08-3 3.08s-3-1.38-3-3.08V6.08Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M4.6 10.63s.2 3.74 5.2 3.74 5.2-3.74 5.2-3.74M9.8 14.8v2.24" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
          </svg> */}
          {iconElement}
          <NavigationButtonContentDesktop className="text-black">
            {/* {label} */}
          </NavigationButtonContentDesktop>
        </NavigationButtonContentWrapperDesktop>
      </NavigationButtonDesktop>
    </Link>
  );
}


function NavigationDesktopNewV3() {
  return (
    <aside className="flex flex-col w-[80px] min-w-[80px] p-[8px] border-r border-gray-200 hidden lg:block" style={{background: "#111828"}}>
      <div className="px-[8px] pt-[16px] pb-[18px]">
        <img src={process.env.PUBLIC_URL + "/notatio_logo.png"} style={{
                width: "80px",
                height: "auto",
                margin: "",
                borderRadius: "5px"
          }}/>
      </div>
      <div className="flex flex-col">
        <NavigationItemDesktop to={"/dashboard/current/note"} activePath={"/dashboard/current"} label="Current Consultation" iconElement={< KeenIcon name="subtitle text-white text-[30px]"/>} />
        <NavigationItemDesktop to={"/dashboard/history"} label="Past Consultations" iconElement={<KeenIcon name="archive text-white text-[30px]" />} />
        <NavigationItemDesktop to={"/dashboard/settings/general"} label="Settings" iconElement={<KeenIcon name="gear text-white text-[30px]"/>}/> {/* <SettingsIconSvg className="text-black" /> */}
        <NavigationItemDesktop to={"/dashboard/contact-us"} label="Contact" iconElement={<KeenIcon name="mail text-white text-[30px]"/>} />
      </div>
      <div className="flex-fill"></div>
      <div className="p-[8px]"></div>
    </aside>
  );
}

// /dashboard/current/transcribe
function NavigationItemMobile({to, label, iconName, activePath}) {
  let location = useLocation()
  const active = location.pathname.includes(activePath || to)
  return (
    <Link className={`min-w-[72px] max-w-[72px] ${active ? "active" : ""}`} to={to} data-status={`${active ? "active" : ""}`}>
        <div className={`flex flex-col gap-[2px] items-center justify-center transition duration-200 text-new-primary font-medium`}>
          {iconName == "MicrophoneSvgIcon" && <MicrophoneSvgIcon className={`text-black text-[24px] ${active ? "!text-blue-500" : ""}`} />}
          {iconName == "HistoryIconSvg" && <HistoryIconSvg className={`text-black text-[24px] ${active ? "!text-blue-500" : ""}`} />}
          {iconName == "SettingsIconSvg" && <SettingsIconSvg className={`text-black text-[24px] ${active ? "!text-blue-500" : ""}`} />}
          {iconName == "MailIconSvg" && <MailIconSvg className={`text-black text-[24px] ${active ? "!text-blue-500" : ""}`} />}
          <span className={`text-[12px] leading-[16px] ${active ? "!text-blue-500" : ""}`}>{label}</span>
      </div>
    </Link>
  );
}

function NavigationMobile() {
  let isPWA = false;
  if (window) {
    isPWA = window.matchMedia('(display-mode: standalone)').matches
  }
  return (
    <div className="flex flex-col block lg:hidden">
      <div className="h-[1px] bg-gray-200"></div>
      <div className={`flex justify-between px-[20px] pt-[6px] ${isPWA ? 'pb-[32px]' : 'pb-[16px]'}`}>

        {/* <a className="min-w-[72px] max-w-[72px] active" href="/current" data-status="active">
          <div className="flex-col gap-2 items-center justify-center transition duration-200 text-new-primary font-medium">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none" data-icon="microphoneThin" className="text-24">
              <path d="M10.88 9.73a4.86 4.86 0 0 1 4.8-4.93c2.65 0 4.8 2.2 4.8 4.93v4.54a4.86 4.86 0 0 1-4.8 4.93c-2.65 0-4.8-2.2-4.8-4.93V9.73Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path><path d="M7.35 17s.33 6 8.33 6 8.33-6 8.33-6m-8.33 6.67v3.6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              </path>
            </svg>
            <span className="text-12 leading-16">Consultation</span>
          </div>
        </a> */}

        <NavigationItemMobile to={"/dashboard/current/note"} activePath="/dashboard/current" label="Consultation" iconName={"MicrophoneSvgIcon"} />
        <NavigationItemMobile to={"/dashboard/history"} label="History" iconName={"HistoryIconSvg"} />
        <NavigationItemMobile to={"/dashboard/settings/general"} activePath="/dashboard/settings" label="Settings" iconName={"SettingsIconSvg"}/>
        <NavigationItemMobile to={"/dashboard/contact-us"} label="Contact" iconName={"MailIconSvg"} />
      

      </div>
    </div>
  );
}

function AppLayoutDesktop({children}) {

  let {user, getUser} = useAuth();

  useEffect(() => {
    getUser();
  }, []);

  if (process.env.REACT_APP_BUILD_PLATFORM === "chrome") {
    return (
      <>{children}</>
    );
  }

  return (
    <div className="h-full w-full flex flex-fill flex-col absolute inset-0">
      <div className="flex flex-fill relative">
        <div className="absolute inset-0 overflow-hidden flex flex-col">
          <div className="overflow-hidden flex grow flex-col lg:flex-row">

            <NavigationDesktop
              userAccount={user && user.subscription_plan}
              numNotes={user && user.num_notes_generated}
            />

            <main className="flex flex-fill flex-col relative bg-white">
              <div className="flex flex-fill flex w-full h-full">
                <div className="flex flex-fill flex-col">
                  {children}
                </div>
              </div>
            </main>

            <NavigationMobile />

          </div>
        </div>
      </div>
    </div>
  )
}

function NoMatchPage({}) {

  if (process.env.REACT_APP_BUILD_PLATFORM === "chrome") {
    return (
      <>{"text"}</>
    );
  }

  return (
    <div className="h-full w-full flex flex-fill flex-col absolute inset-0">
      <div className="flex flex-fill relative">
        <div className="absolute inset-0 overflow-hidden flex flex-col">
          <div className="overflow-hidden flex grow flex-col md:flex-row">

            <NavigationDesktop />

            <main className="flex flex-fill flex-col relative bg-white">
              <div className="flex flex-fill flex w-full h-full">
                <div className="flex flex-fill flex-col">
                  {"NO MATCH"}
                </div>
              </div>
            </main>


            <NavigationMobile />

          </div>
        </div>
      </div>
    </div>
  )
}

const Page = (props) => {

  useEffect(() => {
    if (document.title) {
      document.title = props.title || "Notatio Copilot";
    }
  }, [props.title]);

  return props.children;
};

function App() {

  const {isMinimized, toggle} = useMinimizedStateContext()

  useEffect(() => {
    if (document.title) {
      document.title = "Notatio Copilot";
    }
  }, []);

  const dims = !isMinimized
    ? {width: "335px", height: "550px"}
    : {width: "245px", height: "85px"};

  const Router = process.env.REACT_APP_BUILD_PLATFORM === "chrome" ?  MemoryRouter :  BrowserRouter;


  return (<>
    <AppLayout dims={dims}>

        <Router
          // initialEntries={["/current/transcribe"]}
          // initialEntries={["/confirm"]}
          future={{ v7_startTransition: true }}>
          <AuthProvider>
            <Routes>

              {/* Auth Pages */}
              <Route path="/" element={<Navigate to="auth/welcome" replace={true} />} />
              {/* <Route index element={<HomePage />} /> */}
              
              <Route path="auth" element={
                  <Page title="Onboarding · Notatio Copilot">
                    <AuthLayout/>
                  </Page>
              }>
                <Route index element={<Navigate to="welcome" />} />
                <Route path="welcome" element={<HomePage />} />
                <Route path="signin" element={<LoginPage />} />
                <Route path="invite" element={<InviteCodePage />} />
                <Route path="info" element={<GeneralInfoPage />} />
                <Route path="signup" element={<SignupPage />} />
                <Route path="confirm" element={<ConfirmPage />} />

              </Route>

              {/* Main Pages */}
              <Route path="dashboard" element={
                <Page title="Dashboard · Notatio Copilot">
                    <EnumerateDevicesProvider>
                      <EncountersProvider>
                        <NoteDigestProvider>
                          <AppLayoutDesktop>
                            <Outlet />
                          </AppLayoutDesktop>
                        </NoteDigestProvider>
                      </EncountersProvider>
                    </EnumerateDevicesProvider>
                  </Page>
              }>
                    <Route path="current" element={
                      <ProtectedRoute>
                        <NoteLayout />
                      </ProtectedRoute>
                    }>
                      <Route path="encounter" element={<EncounterView />} />
                      <Route path="transcribe" element={
                          <TranscriptView />
                      } />
                      <Route path="note" element={<NotesView note={DEMO_NOTE} />} />
                    </Route>


                    {/* History Modal */}
                    <Route path="history" element={<HistoryView />}/>
                    <Route path="history/:id" element={<PastNoteView />}>
                      <Route path="encounter" element={<EncounterView />} />
                      <Route path="transcribe" element={
                          <TranscriptView />
                      } />
                      <Route path="note" element={<NotesView note={DEMO_NOTE} />} />
                    </Route>
                    {/* History Modal */}

                    {/* Contact Us Modal */}
                    <Route path="contact-us" element={<ContactView />}/>
                    {/* Contact Us Modal */}

                    {/* Settings */}
                    <Route path="settings" element={<SettingsLayout />}>
                      <Route path="general" element={<SettingsGeneralView />} />
                      <Route path="note" element={<SettingsNoteView />} />
                      <Route path="account" element={<SettingsAccountView />} />
                    </Route>
                    {/* Settings */}
              </Route>

              {/* 404 */}
              <Route path="*" element={<Navigate to="/auth/welcome" />}/>
              {/* 404 */}

            </Routes>
          </AuthProvider>
        </Router>

    </AppLayout>

    <style jsx>{`
        body {
          margin: 0;
          line-height: inherit;
        }

        #root {
          height: 100%;
          padding: 2px;
        }

        .inset-tr-0 {
          top: 0;
          right: 0;
        }

        .card {
          /* background: linear-gradient(114.08deg,#323338 -11.45%,#1f2024 92.1%) padding-box,linear-gradient(175deg,#717174,#1f2024) border-box; */
          background: linear-gradient(114.08deg,#ffffff -11.45%,#ffffff 92.1%) padding-box,linear-gradient(175deg,#ffffff,#ffffff) border-box;
          box-shadow: 0 4px 4px #0003;
          border-radius: 8px;
          border: 0.5px solid transparent;
        }

        .PhoneInputInput {
          /*color: white;*/
          background: none;
          outline: none !important;
        }

      `}</style>
    </>
  );
}

export default App;
