import { Controller } from 'react-hook-form';

// https://www.material-tailwind.com/docs/html/checkbox
{/* <Checkbox checked={value} onChange={onChange} /> */}
export function Checkbox({name, control, defaultValue, labelClassName, circle=false}) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => (
        <div className="inline-flex items-center">
          <label
            className={`${labelClassName} relative flex items-center p-[3px] rounded-full cursor-pointer`} // {/*"relative flex items-center p-3 rounded-full cursor-pointer"*/}
            htmlFor="checkbox"
            data-ripple-dark="true"
          >
            <input
              type="checkbox"
              className={`before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none ${circle ? 'rounded-full' : 'rounded-md'} border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-blue-500 checked:bg-blue-500 checked:before:bg-blue-500 hover:before:opacity-10`}
              id="checkbox"
              value={value}
              onChange={onChange}
            />
            <div className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-3.5 w-3.5"
                viewBox="0 0 20 20"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth="1"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
          </label>
        </div>
      )} 
    />
  );
}

export function MultiCheckbox({name, control, defaultValue, options, labelClassName, circle=true}) {

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => {
       return  <>
          { options && options.map(o => (
            <div key={o.value} className="inline-flex items-center">
              <label
                className={`${labelClassName} relative flex items-center p-[3px] rounded-full cursor-pointer`} // {/*"relative flex items-center p-3 rounded-full cursor-pointer"*/}
                htmlFor="checkbox"
                data-ripple-dark="true"
              >
                <input
                  type="checkbox"
                  className={`before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none ${circle ? 'rounded-full' : 'rounded-md'} border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-blue-500 checked:bg-blue-500 checked:before:bg-blue-500 hover:before:opacity-10`}
                  id="checkbox"
                  value={o.value}
                  onChange={(e) => {return onChange(e.target.value)}}
                  checked={value == o.value}
                />
                <div className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-3.5 w-3.5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    stroke="currentColor"
                    strokeWidth="1"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </div>
              </label>
              <div className="text-[14px]">{o.label}</div>
            </div>
          ))}
        </>}
      } 
    />
  );
}

