import React, { useEffect, useState } from 'react';
import { useForm  } from "react-hook-form";

import { ReactSelect } from '../../components/forms';
import {QRCodeModal, QRReaderModal} from '../../components/Modal'
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import { 

  ButtonTextContainer,
  ButtonContainer,
  ButtonChevronContainer, 
  ButtonSeparator} from '../../components/forms';
  
import { useSoundMeter, SoundMeter } from '../../hooks/useSoundMeter';
import { useEnumerateDevices, useEnumerateDevicesContext } from '../../hooks/useEnumerateDevices';
import { useAuth } from "../../hooks/useAuth";
import { useDeviceId } from "../../hooks/useDeviceId";


export function SettingsGeneralView({}) {

    const {user, updateUser, loading} = useAuth();
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [device, setDevice, loadingDevice] = useDeviceId("device", null);

    const {
      control,
      register,
      handleSubmit,
      watch,
      formState: {errors, isValid},
    } = useForm({mode: "onChange",  defaultValue: {
      // "deviceName": device device.deviceName,
    }})
  
  
    const {
      recording,
      volume,
      stopRecording,
      startRecording,
      setDeviceId,
    } = useSoundMeter({autoStart: true})


    // const { devices, loadingDevices } = useEnumerateDevices()
    const { devices, loadingDevices,currentDevice, setCurrentDeviceWithId, refreshDevices } = useEnumerateDevicesContext()
    useEffect(() => {
      console.log("React Select click;"); refreshDevices()
    }, [])
  
    const onSubmit = async (data) => {
      console.log("data: ", data)
      if (data.deviceName) {
        setDevice({deviceName: data.deviceName})
      }
      // if (data.audio_device !== currentDevice.deviceId) {
        // console.log("Selected a new device id... current is ", currentDevice.deviceId, " new is ", data.audio_device)
        setCurrentDeviceWithId(data.audio_device)
        setDeviceId(data.audio_device)
      // }
      // submit user update using the User Provider.
      updateUser(data)
    }
    
    useEffect(() => {
        // TypeScript users 
        // const subscription = watch(() => handleSubmit(onSubmit)())
        const subscription = watch(handleSubmit(onSubmit));
        return () => subscription.unsubscribe();
    }, [handleSubmit, watch]);

    useEffect(() => {
      if (currentDevice) {
        console.log("SettingsGeneralView.currentDevice: ", currentDevice);
        setDeviceId(currentDevice.deviceId)
      }
    }, [currentDevice]);
  
    return (<>

      {(loading || loadingDevices || loadingDevice)
          ? (
            <div className="flex flex-col">
            <div className="flex flex-col items-center justify-center mb-[24px] h-[64px]">
                <div role="status">
                    <svg aria-hidden="true" className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                    </svg>
                    <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          ): (<form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col py-[8px]">
        <div className="text-[14px] font-medium text-primary mb-[4px] ">
        Interface and note language
        </div>                            
        <ReactSelect name="note_language" options={[
            { value: 'ENGLISH_US', label: 'English'},
          ]}
          defaultValue={user && user.note_language}
          control={control}
        />
      </div>
      <div className="flex flex-col py-[8px]">
        <div className="text-[14px] font-medium text-primary mb-[4px]">
        Consultation language
        </div>                            
        <ReactSelect name="transcript_language" options={[
            { value: 'ENGLISH_US', label: 'English'},
            // { value: 'FRENCH', label: 'French'},
            // { value: 'SPANISH', label: 'Spanish'},
            // { value: 'GREEK', label: 'Greek'},
          ]}
          defaultValue={user && user.transcript_language}
          control={control}
        />
      </div>

      <hr class="bg-gray-200 w-full h-[1px] border-0 my-[16px]" />

      <div className="flex flex-col py-[8px]">
        <div className="text-[14px] font-medium text-primary mb-[4px]">
        Microphone
        </div>                            
        <ReactSelect
          name="audio_device"
          options={devices ? devices.map(d =>({
            "value": d.deviceId,
            "label": d.label
          })) : []}
          defaultValue={currentDevice && currentDevice.deviceId}
          control={control}
        />
        <div className="flex flex-row gap-[8px] items-center">
          <span className="mt-[8px] text-[14px] leading-[24px] mb-[8px]">Sound Level</span>
          <SoundMeter volume={volume} />
        </div>
      </div>

      <hr class="bg-gray-200 w-full h-[1px] border-0 my-[10px]" />

      {/* <BrowserView>
        <div className="flex flex-col py-[8px]">
          <div className="text-[14px] font-medium text-primary mb-[4px]">
          Receive notes from other devices
          </div>      
          <input
              name="invite-code"
              placeholder="Device name"
              autoComplete="off"
              // defaultValue={"Desktop"}
              defaultValue={device && device.deviceName}
              className="mb-[12px] text-[14px] w-full rounded border border-solid border-[#00000077] bg-transparent p-[8px] leading-[24px]  !outline-none text-left"
              {...register(
                "deviceName",
              )}
            />                      
          
          <ButtonContainer onClick={(e) => {e.preventDefault(); setModalIsOpen(true); console.log("Button clicked")}}>
            <ButtonTextContainer>
              <div className="flex justify-center items-center space-x-[4px] text-white">
                <div>Receive notes from phone</div>
              </div>
            </ButtonTextContainer>
        </ButtonContainer>
        </div>
        <QRCodeModal
            modalIsOpen={modalIsOpen}
            closeModal={() => setModalIsOpen(false)}
            onConfirm={() => {}}
            confirmLabel={"Delete consultation"}
            title={"Are you sure you want to delete this consultations?"}
            subtitle={"You won't be able to recover the consultation."}
            deviceId={device && device.deviceId}
        />
      </BrowserView> */}

      {/* <MobileView>
        <div className="flex flex-col py-[8px]">

        <div className="text-[14px] font-medium text-primary mb-[4px]">
          Device name
          </div>    
          <input
              name="invite-code"
              placeholder="Device name"
              autoComplete="off"
              // defaultValue={"Desktop"}
              defaultValue={device && device.deviceName}
              className="mb-[12px] text-[14px] w-full rounded border border-solid border-[#00000077] bg-transparent p-[8px] leading-[24px]  !outline-none text-left"
              {...register(
                "deviceName",
              )}
            />

          <div className="text-[14px] font-medium text-primary mb-[4px]">
          Devices to which your notes will automatically be sent
          </div>                            
          <ButtonContainer onClick={(e) => {e.preventDefault(); setModalIsOpen(true); console.log("Button clicked")}}>
            <ButtonTextContainer>
              <div className="flex justify-center items-center space-x-[4px] text-white">
                <div>Add a new device</div>
              </div>
            </ButtonTextContainer>
          </ButtonContainer>
        </div>
        <QRReaderModal
              modalIsOpen={modalIsOpen}
              closeModal={() => setModalIsOpen(false)}
              onConfirm={() => {}}
              confirmLabel={"Delete consultation"}
              title={"Are you sure you want to delete this consultations?"}
              subtitle={"You won't be able to recover the consultation."}
              deviceId={device && device.deviceId}
          />
      </MobileView> */}

  
      {/* <div className="flex flex-col text-white">
        <div className="text-[14px] font-medium text-primary mb-[4px] text-white">
        Improve transcript
        </div>             
        <div className="flex flex-col text-[14px]">
          <div className="flex items-start relative">
            <Checkbox name="concentGiven" labelClassName="mr-[12px]" control={control} defaultValue={true} />
            <div className="flex-fill">
              Allow sharing audio after a consultation. You need <a href="https://www.nabla.com/copilot-audio-patient-consent/" target="_blank" className="underline" rel="noreferrer">the patient’s consent</a>.
            </div>
          </div>
        </div>
      </div> */}
      </form>)}
  
    </>);
  }