import { useState, useRef, useEffect } from 'react'
import { Controller, } from 'react-hook-form';

import styled from 'styled-components';


const CustomTextAreaInNote = styled.textarea`
    width: 100%;
    border-radius: 8px;
    border-width: 1px;
    background-color: transparent;
    resize: none;
    border: solid 0.5px #ffffff77;
    // font-size: 12px;
    color: black;
    border: none;
    padding: 0;
    margin-top: 0 !important;
  `;

// Updates the height of a <textarea> when the value changes.
export const useAutosizeTextArea = (
  textAreaRef,
  value,
) => {
  useEffect(() => {
    if (textAreaRef) {
      // We need to reset the height momentarily to get the correct scrollHeight for the textarea
      textAreaRef.style.height = "0px";
      const scrollHeight = textAreaRef.scrollHeight;

      // We then set the height directly, outside of the render loop
      // Trying to set this with state or a ref will product an incorrect value.
      textAreaRef.style.height = scrollHeight + "px";
    }
  }, [textAreaRef, value]);
};

export function AutoResizeTextarea({name, control, defaultValue, className}) {

  const textAreaRef = useRef(null);
  const [tmpValue, setTmpValue] = useState(defaultValue);
  useAutosizeTextArea(textAreaRef.current, tmpValue);

    return (
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field: { onChange, value } }) => {
         return  <>
          <CustomTextAreaInNote
            onChange={(v) => {onChange(v); setTmpValue(v)}}
            value={value}
            ref={textAreaRef}
            rows={1}
            className={className}
          />
        </>}
      } 
    />
  );
}

