import React, { useEffect } from 'react';
import { useForm  } from "react-hook-form";

import { ReactSelect } from '../../components/forms';
  
import { useSoundMeter, SoundMeter } from '../../hooks/useSoundMeter';


export function IntroScreenTranscriptView() {

    const {
      recording,
      volume,
      stopRecording,
      startRecording,
    } = useSoundMeter({autoStart: true})
  
    return (
      <div className="flex flex-col h-full max-w-[328px] mobile:max-w-full">
        <div className="flex flex-col flex-center gap-[30px] pointer-events-none flex-1 px-[8px] justify-center">
          
        <div className="flex justify-center py-[10px]">
          <div className="relative self-center ">
              <img src={process.env.PUBLIC_URL + '/flaticons/microphone.png'} style={{width: "150px", height: "auto"}}/>
            </div>
          </div>

          <div className="flex flex-col items-center justify-center">
            <span className="text-[12px] text-body text-center text-base">
              Click on 'Start consultation' below to start the transcription.
            </span>
          </div>
  
          <div className="mr-[4px]">
            <div className="flex gap-[4px]">
              <div className={`d-block rounded-[3px] h-[4px] w-[13px] bg-shaded bg-blue`} />
              <div className={`d-block rounded-[3px] h-[4px] w-[13px] bg-shaded bg-blue`} />
            </div>
          </div>
  
          <div className="flex flex-col h-[120px] mb-[12px]">
            {/* <div className="flex justify-between items-center gap-[32px] px-[16px] pb-[8px]">
              <div className="flex flex-shrink-0 text-white text-[12px]">Consultation type</div>
              <div className="flex w-full">
                <div className="text-white pl-[8px] py-[4px] font-medium w-full flex flex-shrink-0 select-none p-[8px] border-[0.5px] border-grey-300 rounded text-[14px]">
                  <div className="flex w-full items-center">
                    <span className="truncate">In-person</span>
                    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 21 21" data-icon="chevron" height="26" width="26" className="ml-auto hover:opacity-80 outline-0 flex-shrink-0" tabIndex="-1" style={{transform: "rotate(90deg)"}}><path d="m8.65 6.75 3.5 3.25-3.5 3.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="flex justify-between items-center gap-[6px] px-[16px]">
              <div className="text-[12px] text-base">Test your microphone</div>
              <div className="mr-[4px]">
                {/* <div className="flex gap-[4px]">
                  <div className={`rounded-[3px] w-[4px] h-[13px] bg-shaded ${ volume >= 2 ? 'bg-white' : 'bg-zinc-500'}`} />
                  <div className={`rounded-[3px] w-[4px] h-[13px] bg-shaded ${ volume >= 3 ? 'bg-white' : 'bg-zinc-500'}`} />
                  <div className={`rounded-[3px] w-[4px] h-[13px] bg-shaded ${ volume >= 4 ? 'bg-white' : 'bg-zinc-500'}`} />
                  <div className={`rounded-[3px] w-[4px] h-[13px] bg-shaded ${ volume >= 5 ? 'bg-white' : 'bg-zinc-500'}`} />
                  <div className={`rounded-[3px] w-[4px] h-[13px] bg-shaded ${ volume >= 6 ? 'bg-white' : 'bg-zinc-500'}`} />
                  <div className={`rounded-[3px] w-[4px] h-[13px] bg-shaded ${ volume >= 7 ? 'bg-white' : 'bg-zinc-500'}`} />
                  <div className={`rounded-[3px] w-[4px] h-[13px] bg-shaded ${ volume >= 8 ? 'bg-white' : 'bg-zinc-500'}`} />
                  <div className={`rounded-[3px] w-[4px] h-[13px] bg-shaded ${ volume >= 9 ? 'bg-white' : 'bg-zinc-500'}`} />
                </div> */}
                <SoundMeter volume={volume} />
              </div>
            </div>
          </div>
  
        </div>
      </div>
    )
  }
  