import { useEffectAsync } from '@chengsokdara/react-hooks-async'
import { useEffect, useRef, useState, useMemo, useContext, createContext } from 'react'


const defaultConfig = {

};


export const useEnumerateDevices = (config) => {

  const {

  } = {
    ...defaultConfig,
    ...config,
  }

  const streamRef = useRef(null)

  const [devices, setDevices] = useState([]) // OR USE REF?
  const [loading, setLoading] = useState(false) // OR USE REF?

  /**
   * if config.autoStart is true
   * start speech recording immediately upon component mounted
   */
  useEffectAsync(async () => {
    await refreshDevices()
  }, [])

  useEffect(() => {
    console.log("useEnumerateDevices: Mount ENUMERATE")
    return () => {
      console.log("useEnumerateDevices: UNMOUNT")
      if (streamRef.current) {
        console.log("useEnumerateDevices: Removing steam from EnumerateDevices")
        streamRef.current.getTracks().forEach(track => {
          track.stop();
        });
      }
    }
  }, [])

  const refreshDevices = async () => {
    setLoading(true)
    // note in safari -- need to get userMedia first before listing devices.
    console.log("useEnumerateDevices: Getting steam in EnumerateDevices for SAFARI...")
    streamRef.current = await navigator.mediaDevices.getUserMedia({
      audio: true,
      // video: false
    });

    try {
      await navigator.mediaDevices.enumerateDevices()
          .then(devicesAvailable => {
            let audioDevices = devicesAvailable.filter(d => d.kind == "audioinput" && !d.label.includes('Virtual'))
            // console.log("devices: ", audioDevices)
            // audioDevices.map(d => console.log(d))
            let validDevices = []
            // let defaultDeviceGroup = null;
            // audioDevices.forEach(device => {
            //   if (device.deviceId == "default") {
            //     validDevices.push(device)
            //     defaultDeviceGroup = device.groupId
            //   }
            // })
            // audioDevices.forEach(device => {
            //   if (device.groupId != defaultDeviceGroup) {
            //     validDevices.push(device)
            //   }
            // })
            validDevices = audioDevices;
            setDevices(validDevices)
            // var camera = devices.find(device => device.kind == "videoinput");
            // if (camera) {
            //     var constraints = { deviceId: { exact: camera.deviceId } };
            //     return navigator.mediaDevices.getUserMedia({ video: constraints });
            // }
            setLoading(false)
          })
    } catch {
      console.warn("useEnumerateDevices: Failed to get devices...")
    }
    if (streamRef.current) {
      console.log("useEnumerateDevices: Removing steam from EnumerateDevices")
      streamRef.current.getTracks().forEach(track => {
        track.stop();
      });
    }
  };

  return {
    devices,
    loading,
    refreshDevices,
  }

};


const EnumerateDevicesContext = createContext();

export const EnumerateDevicesProvider = (config) => {

  const[currentDevice, setCurrentDevice] = useState(null)
  const [loadingCurrentDevice, setLoadingCurrentDevice] = useState(true) // OR USE REF?

  const {
    devices,
    loading,
    refreshDevices,
  } = useEnumerateDevices(config)

  useEffect(() => {

    if (devices.length > 0) {
      if (!currentDevice) {
        const defaultDevice = devices.find((d) => d.deviceId == "default")
        setCurrentDevice(defaultDevice);
        setLoadingCurrentDevice(false)
      }
    }
  }, [devices, loading])

  const setCurrentDeviceWithId = (deviceId) => {
    const newDevice = devices.find((d) => d.deviceId == deviceId)
    setCurrentDevice(newDevice);
  };

  const value = useMemo(
    () => ({
      loadingDevices: (loading || loadingCurrentDevice),
      devices,
      currentDevice,
      setCurrentDevice,
      setCurrentDeviceWithId,
      refreshDevices,
    }),
    [devices, loading, loadingCurrentDevice, currentDevice, refreshDevices, setCurrentDevice, setCurrentDeviceWithId]
  );
  return <EnumerateDevicesContext.Provider value={value}>{config.children}</EnumerateDevicesContext.Provider>;
};

export const useEnumerateDevicesContext = () => {
  return useContext(EnumerateDevicesContext);
};
