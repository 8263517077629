import axios from "axios";
import { createContext, useContext, useMemo , useEffect, useState} from "react";
import { jwtDecode } from "jwt-decode";
import { useEffectAsync } from '@chengsokdara/react-hooks-async'

import { useStorageHook, getTokensFromStorage, setTokensFromStorage } from "./useLocalStorage";
import { useDeviceId } from "./useDeviceId";

import { API_URL, AUTH_URL } from "../constants";


const AuthContext = createContext();
// const API_URL = "http://localhost:80/api/";
// const API_URL = "http://44.213.106.199:80/api/";
// const AUTH_URL = `${API_URL}auth/`;

const authenticatedApi = axios.create({
  baseURL: API_URL,
});

// Add a request interceptor
// https://blog.theashishmaurya.me/handling-jwt-access-and-refresh-token-using-axios-in-react-app
authenticatedApi.interceptors.request.use(
  async (config) => {
    const accessToken = await getValidAccessToken()
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// TODO(oandrienko): Remove this? We check the validity every request anyway.
// Add a response interceptor
// authenticatedApi.interceptors.response.use(
//   (response) => response,
//   async (error) => {
//     const originalRequest = error.config;

//     // If the error status is 401 and there is no originalRequest._retry flag,
//     // it means the token has expired and we need to refresh it
//     if (error.response.status === 401 && !originalRequest._retry) {
//       originalRequest._retry = true;

//       try {
//         const accessToken = await getValidAccessToken()

//         // Retry the original request with the new token
//         originalRequest.headers.Authorization = `Bearer ${accessToken}`;
//         return axios(originalRequest);
//       } catch (error) {
//         // Handle refresh token error or redirect to login
//         console.log("error refreshing: ", error)
//       }
//     }

//     return Promise.reject(error);
//   }
// );


let IS_REFRESHING = false


export async function getValidAccessToken() {
  // TODO(oandrienko): We need to set this in auth provider too...
  // const tokens = JSON.parse(localStorage.getItem('tokens'));
  const tokens = await getTokensFromStorage();

  // tokens are missing somehow
  if (!tokens || (!tokens.accessToken && !tokens.refreshToken)) {
    return null;
  }

  // check if access token is expired
  if (isJwtExpired(tokens.accessToken)) {
    // if refresh is also expired, we need to logout the user.
    if (isJwtExpired(tokens.refreshToken)) {
      return null;
    }
    IS_REFRESHING = true;
    // fetch new token with refresh token
    const response = await axios.post(AUTH_URL + 'refresh', {}, {
      headers: {Authorization: `Bearer ${tokens.refreshToken}`}
    });
    const newAccessToken = response.data.access_token;

    // localStorage.setItem('tokens', JSON.stringify({
    //   accessToken: newAccessToken,
    //   refreshToken: tokens.refreshToken}));
    await setTokensFromStorage({
        accessToken: newAccessToken,
        refreshToken: tokens.refreshToken})
    IS_REFRESHING = false;

    return newAccessToken
  }

  return tokens.accessToken
}


const isJwtExpired = (token) => {
  if (typeof(token) !== 'string' || !token) throw new Error('Invalid token provided');

  let expired = false;
  const { exp } = jwtDecode(token);
  if (!exp) {
    return false;
  }

  const currentTime = new Date().getTime() / 1000;

  if (currentTime > exp) expired = true;

  return expired;
}

const getTemplatesApi = () => {
  return authenticatedApi.get(API_URL + "templates");
}

const registerApi = (
  phone,
  // email,
  country_code,
  specialty,
  invite_code,
  device,
) => {
  return axios.post(AUTH_URL + "register-phone", {
    phone,
    // email,
    country_code,
    specialty,
    invite_code,
    device,
  });
};

const loginApi = (phone, country, device) => {
  return axios
    .post(AUTH_URL + "login-phone", {
      phone,
      country,
      device
    })
    .then((response) => {
      return response.data;
    }).catch((error) => {
      console.log("ERROR response: ", error.response.data)
      return error.response.data
    });
};

const verifyApi = (phone, code, device) => {
  return axios
    .post(AUTH_URL + "validate-phone", {
      phone,
      code,
      device,
    })
    .then((response) => {
      return response.data;
    }).catch((error) => {
      console.log("ERROR response: ", error.response.data)
      return error.response.data
    });
};

const logoutApi = async (tokenType) => {
  const config = {headers: {}}

  const tokens = await getTokensFromStorage();

  if (tokenType === 'access') {
    config.headers.Authorization = `Bearer ${tokens.accessToken}`;
  } else if (tokenType === 'refresh') {
    config.headers.Authorization = `Bearer ${tokens.refreshToken}`;
  }

  return axios
    .delete(AUTH_URL + "logout", config)
    .then((response) => {
      return response.data;
    });
};

const refreshApi = () => {
  return axios
    .post(AUTH_URL + "refresh", {})
    .then((response) => {
      return response.data;
    })
};

const getUserApi = () => {
  return authenticatedApi
    .get(API_URL + "users/")
    .then((response) => {
      return response.data;
    }).catch((error) => {
      console.log("ERROR response: ", error.response.data)
      return error.response.data
    });
};

const updateUserApi = (fields) => {
  return authenticatedApi
    .post(API_URL + "users/", fields)
    .then((response) => {
      return response.data;
    }).catch((error) => {
      console.log("ERROR response: ", error.response.data)
      return error.response.data
    });
};

const validateInviteCodeApi = (inviteCode) => {
  return axios
    .post(AUTH_URL + "validate-invite", {
      invite_code: inviteCode
    })
    .then((response) => {
      return response.data;
    }).catch((error) => {
      console.log("ERROR response: ", error.response.data)
      return error.response.data
    });
}

export const AuthService = {
  register: registerApi,
  login: loginApi,
  logout: logoutApi,
  verify: verifyApi,
  refresh: refreshApi,
  validateInviteCode: validateInviteCodeApi,
  getTemplates: getTemplatesApi,
  authenticatedApi: authenticatedApi
  // getCurrentUser,
};

export const UserService = {
  getUser: getUserApi,
  updateUser: updateUserApi,
  // getCurrentUser,
};

export const AuthProvider = ({ children }) => {

  const [user, setUser, loadingUser] = useStorageHook("user", null);
  const [tokens, setTokens, loadingTokens] = useStorageHook("tokens", null);
  const [device, setDevice, loadingDevice] = useDeviceId("device", null);

  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  
  useEffectAsync(async () => {
    setLoading(true)
    try {
      const accessToken = await getValidAccessToken()
      if (accessToken) {
        console.log("Valid access token. Authenticated=true!")
        setAuthenticated(true)
      } else {
        console.log("Invalid access token. Authenticated=false!")
        await logout()
      }
    } catch {
      console.log("Invalid access token. Authenticated=false!")
      await logout()
    }
    setLoading(false)
  }, [])

  // call this function when you want to authenticate the user
  // THESE DON'T RETURN TOKENS. THEY ONLY SEND AN AUTH CODE.
  const signup = async (
    phone,
    // email,
    country_code,
    specialty,
    invite_code) => {
    const response = await AuthService.register(
      phone,
      // email,
      country_code,
      specialty,
      invite_code,
      device.deviceId);
    return response
  };

  // call this function when you want to authenticate the user
  // THESE DON'T RETURN TOKENS. THEY ONLY SEND AN AUTH CODE.
  const login = async (phone, country) => {
    const response = await AuthService.login(phone, country, device.deviceId);
    return response
  };

  const validateInviteCode = async (inviteCode) => {
    const response = await AuthService.validateInviteCode(inviteCode);
    return response
  };

  // call this function when you want to authenticate the user
  const verify = async (phone, code) => {
    const response = await AuthService.verify(phone, code, device.deviceId);
    if (response.error) {
      return response
    }
    const newTokens = {
      accessToken: response.access_token,
      refreshToken: response.refresh_token};
    setTokens(newTokens);
    const currentUser = response.user;
    setUser(currentUser)
    return newTokens
  };

  // call this function to sign out logged in user
  const logout = async () => {
    console.log("Logging out...")
    try {
      let response = await AuthService.logout('access');
      console.log("Logout access response: ", response)
      response = await AuthService.logout('refresh');
      console.log("Logout access response: ", response)
    } catch {
      console.log("Issue logging out.")
    }
    setAuthenticated(false)
    setTokens(null)
    setUser(null)
  };

  const getUser = async () => {
    const response = await UserService.getUser();
    let newUser = Object.assign({}, response.user)
    setUser(newUser);
  };

  const updateUser = async (fields) => {
    let newUser = Object.assign({}, user, fields);
    setUser(newUser);
    const response = await UserService.updateUser(fields);
    newUser = Object.assign({}, response.user)
    setUser(newUser);
  };

  const value = useMemo(
    () => ({
      authenticated,
      user,
      tokens,
      loading,
      signup,
      login,
      logout,
      verify,
      getUser,
      updateUser,
      validateInviteCode,
    }),
    [authenticated, user, tokens, loading, signup, login, logout, verify, updateUser, validateInviteCode]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};


export const useAuth = () => {
  return useContext(AuthContext);
};
