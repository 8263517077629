import React, { useState, useEffect } from 'react';
import { useForm  } from "react-hook-form";
import {
    useNavigate,
    useQuery,
    useLocation,
    Link } from "react-router-dom";
import { 
    PhoneInput,
    ButtonTextContainer,
    ButtonContainer } from '../../components/forms';

import { BackSvgIcon } from '../../components/svgs'

import { useAuth } from "../../hooks/useAuth";


export function SignupPage({withExistingAccount = false}) {

    const location = useLocation()
    console.log("Signup.location: ",location)

    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState()
    const [error, setError] = useState()
    const [phoneCountryCode, phoneCountryCodeSetter] = useState((location.state && location.state.countryCode) || 'US');
    const navigate = useNavigate(); // <-- access navigate function
    const { user, signup, login} = useAuth();
  

    useEffect(() => {
      if (!withExistingAccount && (!location.state || !location.state.inviteCode || !location.state.specialty || !location.state.countryCode)) {
        navigate("/auth/welcome");
      }
    }, [location])
  
    const {
      control,
      handleSubmit,
      formState: {errors, isValid}
    } = useForm({mode: "onChange"})
  
    const onSubmit = async (data) => {
      setValue(data)
      const phoneNumber = data.phoneInputWithCountrySelect;
      // const email = data.email
  
      setLoading(true)
      if (!withExistingAccount) {
        console.log("Signup: ", phoneNumber, phoneCountryCode, location.state.specialty, location.state.inviteCode)
      } else {
        console.log("Signin: ", phoneNumber, phoneCountryCode)
      }
      const response = withExistingAccount
        ? await login(phoneNumber, phoneCountryCode)
        : await signup(phoneNumber, phoneCountryCode, location.state.specialty, location.state.inviteCode)
      setLoading(false)
      if (response.error) {
        setError(response.error)
      } else {
        let specialty = null, inviteCode = null;
        if (location.state) {
          specialty = location.state.specialty
          inviteCode = location.state.inviteCode
        }
        navigate("/auth/confirm", { replace: true, state:{ phoneNumber, phoneCountryCode, withExistingAccount, specialty, inviteCode }});
      }
    }
  
    return (<>
          {/* <form onSubmit={handleSubmit(onSubmit)}> */}
     {/* MainContent */}
     <form className="" onSubmit={handleSubmit(onSubmit)}>
  
  
        {/* <div className="flex justify-center py-[50px]">
          <div className="relative self-center">

          </div>
        </div> */}
  
        <div className="flex flex-col">
          <div className="flex flex-col items-center justify-center mb-[24px] h-[64px]">
            <h1 className="text-[24px] text-center font-medium">
              Enter your phone number
            </h1>
          </div>
          <span className="text-[14px] text-center">
            {withExistingAccount 
              ? "Before you can sign in, make sure you've created your account."
              : "We'll only use your phone number to verify your identity and contact you if needed."}
          </span>
        </div>
  
        {/* Form */}
        <div className="mt-[24px] w-full flex-col items-center space-y-[8px]">
          {/* <input name="email" placeholder="janedoe@gmail.com" type="email" id="emailInput" className="text-[14px] w-full rounded border border-solid border-[#ffffff77] bg-transparent p-[8px] leading-[24px] text-white !outline-none" /> */}
          <PhoneInput
            onCountryChange={(v) => phoneCountryCodeSetter(v)}
            name="phoneInputWithCountrySelect"
            className="text-[14px] w-full rounded border border-solid border-[#00000077] bg-transparent p-[8px] leading-[24px] !outline-none text-black"
            control={control}
            autoComplete="off"
            countries={["US", "CA"]}
            defaultCountry={(location.state && location.state.countryCode) || 'US'}
            />
          <span className="text-red-500 text-[14px] font-semibold">{error && error}</span>
          {/* <input name="invite-code" placeholder="Invite Code" className="text-[14px] w-full rounded border border-solid border-[#ffffff77] bg-transparent p-[8px] leading-[24px] text-white !outline-none text-center" /> */}
          {/* <label htmlFor="invite-code" className="block text-sm font-medium leading-6 text-gray-500 text-center">Enter the invite code you were provided above. Dom't have one? Email us</label> */}
        </div>
        {errors && errors.name}
        {/* End Form */}
  
        {/* Form */}
        {/* <div className="my-[24px] w-full flex-col items-center space-y-[8px] text-white text-center">
          <label htmlFor="invite-code" className="block text-sm font-medium leading-6 text-gray-500">Enter the invite code you were provided below.</label>
          <input name="invite-code" placeholder="Invite Code" className="text-[14px] w-full rounded border border-solid border-[#ffffff77] bg-transparent p-[8px] leading-[24px] text-white !outline-none text-center" />
        </div> */}
        {/* End Form */}
  
        <div className="flex-fill"></div>
  
        {/* <div className="flex flex-col">
          <form>
            <PhoneInput
              placeholder="Enter phone number"
              defaultCountry='US'
              international={false}
              value={value}
              countrySelectProps={{ unicodeFlags: true }}
              onChange={setValue}/>
          </form>
        </div> */}
  
  
        {/* ActionButton */}
        <div className="flex-col items-center mt-[32px]">
            <ButtonContainer disabled={!isValid} type="submit">
              <ButtonTextContainer disabled={!isValid}
                // css={!isValid && disabledButton}
                // className="disabled:bg-[#404040]"
              >
                <div className="flex justify-center items-center space-x-[4px] text-white">
                  {/* <MicrophoneSvgIcon /> */}
                  <div>{loading ? "Loading" : "Next"}</div>
                </div>
              </ButtonTextContainer>
            </ButtonContainer>
          {/* </Link> */}
        </div>
        {/* ./End ActionButton */}
  
        {/* Sign in text */}
        {/* <div className="mt-[8px] pt-[4px] text-[14px] text-grey-[2px] leading-[24px] text-center text-white">
          Already have an account? <a className="text-primary hover:underline cursor-pointer font-medium text-blue-600" target="_blank" rel="noreferrer">Sign in</a>
        </div> */}
        {/* END Sign in text */}
  
    </form>
    {/* ./End MainContent */}
    {/* </form> */}
  
    </>
    )
  }
  