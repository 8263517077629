import React, {useState, useEffect, useRef, createRef} from 'react';

// import React from 'react';
import ReactDOM from 'react-dom';
import ReactModal from 'react-modal';
import QRCode from "react-qr-code";
import { QrReader } from 'react-qr-reader';


import {
  Link,
  useNavigate,
} from "react-router-dom";
import styled, {keyframes} from "styled-components";

import {
  NablaLogoSvg,
  PlusIconSvg,
  HistoryIconSvg,
  MailIconSvg,
  SettingsIconSvg,
  MinimizeIconSvg,
  ExpandIconSvg,
  CloseIconSvg,
} from './svgs'
import { BASE_URL } from "../constants"

const customStyles = {
    content: {
    //   top: '50%',
    //   left: '50%',
    //   right: 'auto',
    //   bottom: 'auto',
    //   marginRight: '-50%',
    //   transform: 'translate(-50%, -50%)',
        // backgroundColor: 'blue'
    },
    overlay: {
        // backgroundColor: 'red',
    }
  };


  

ReactModal.setAppElement('#modal');


const Button = styled.button`
    position: relative;
    display: flex;
    border-radius: 8px;
    padding: 12px 16px;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));

--tw-bg-opacity: 1;
background-color: rgb(33 42 47 / var(--tw-bg-opacity));
--tw-shadow: 0px 2px 4px -2px rgba(0,0,0,.12);
--tw-shadow-colored: 0px 2px 4px -2px var(--tw-shadow-color);
box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow);

padding-top: 15px;
padding-bottom: 15px;
`;

const ButtonLight = styled.button`
    position: relative;
    display: flex;
    border-radius: 8px;
    padding: 12px 16px;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));

--tw-bg-opacity: 1;
background-color: rgb(245 247 250 / var(--tw-bg-opacity));
--tw-text-opacity: 1;
color: rgb(109 117 141 / var(--tw-text-opacity));
padding-top: 15px;
padding-bottom: 15px;
`;


const ButtonWrapper = styled.div`
display: flex;
align-items: center;
gap: 4px;
flex: 1 1 auto;
min-width: 0;
min-height: 0;
justify-content: center;
`;

const ButtonContent = styled.span`
transition-property: opacity;
transition-timing-function: cubic-bezier(.4,0,.2,1);
transition-duration: .2s;
display: flex;
align-items: center;
justify-content: center;
`;


export function Modal({modalIsOpen, closeModal, onConfirm, confirmLabel, title, subtitle}) {
    // let subtitle;

    // const [modalIsOpen, setIsOpen] = React.useState(false);

    // function openModal() {
    //   setIsOpen(true);
    // }
  
    function afterOpenModal() {
      // references are now sync'd and can be accessed.
    //   subtitle.style.color = '#f00';
    }
  
    // function closeModal() {
    //   setIsOpen(false);
    // }

    const onConfirmAndClose = () => {
        onConfirm();
        closeModal()
    }

    return (<>
        <ReactModal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        // style={customStyles}
        contentLabel="Example Modal"
        overlayClassName={"absolute inset-0 z-tooltip bg-gray-400/25 backdrop-blur-[5px] p-[32px] chrome:p-0 chrome:bg-transparent chrome:backdrop-blur-none"}
        className={"h-full w-full flex items-center justify-center"}
        >
            {/* <div className="absolute inset-0 z-tooltip bg-grey-400/25 backdrop-blur-[5px] p-32 chrome:p-0 chrome:bg-transparent chrome:backdrop-blur-none"> */}
                {/* <div className="h-full w-full flex items-center justify-center"> */}
                    <div className="bg-white chrome:bg-gray-400 rounded-[16px] p-[32px] shadow-mid chrome:h-full rounded chrome:p-[12px] max-w-[504px]">
                        {/* <ButtonLight className="_button_b8w3u_1 absolute top-[12px] right-[12px] hidden chrome:flex p-[0px] text-shaded hover:text-white bg-transparent _default_b8w3u_60 _medium_b8w3u_30 _primary_b8w3u_61 _icon-button_b8w3u_46 _align-center_b8w3u_18" type="button">
                            <ButtonWrapper className="_content-wrapper_b8w3u_7">
                                <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 21 21" data-icon="close" height="20" width="20"><path d="m15.4 5-10 10m0-10 10 10" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                            </ButtonWrapper>
                        </ButtonLight> */}
                        <div className="flex-col chrome:h-full">
                            <div className="font-semibold text-[24px] leading-[32px] text-left text-grey-400 chrome:text-grey-100 chrome:leading-[36px] chrome:text-center chrome:font-medium chrome:mt-[102px]">
                                {title}
                            </div>
                            <div className="text-grey-300 text-14 leading-[24px] text-left mt-[7px] chrome:text-shaded chrome:leading-[20px] chrome:text-center chrome:mt-12">
                                {subtitle}
                            </div>
                            <div className="chrome:flex-fill"></div>
                            <div className="sm:gap-[16px] sm:mt-[24px] gap-[8px] mt-[8px] flex flex-col md:flex-row-reverse">
                                <ButtonLight onClick={closeModal} className="_button_b8w3u_1 flex-fill chrome:bg-grey-4 chrome:p-8 chrome:leading-[20px] chrome:text-white chrome:hover:brightness-110 chrome:active:brightness-125 _default_b8w3u_60 _large_b8w3u_34 _primary_b8w3u_61 _cancellation_b8w3u_111 _align-center_b8w3u_18" type="button">
                                    <ButtonWrapper className="_content-wrapper_b8w3u_7">
                                        <ButtonContent className="_content_b8w3u_2">Cancel</ButtonContent>
                                    </ButtonWrapper>
                                </ButtonLight>
                                <Button onClick={onConfirmAndClose} className="_button_b8w3u_1 flex-fill chrome:bg-new-primary chrome:p-8 chrome:leading-[20px] chrome:hover:brightness-110 chrome:active:brightness-125 _default_b8w3u_60 _large_b8w3u_34 _primary_b8w3u_61 _align-center_b8w3u_18" type="button">
                                    <ButtonWrapper className="_content-wrapper_b8w3u_7">
                                        <ButtonContent className="_content_b8w3u_2">{confirmLabel}</ButtonContent>
                                    </ButtonWrapper>
                                </Button>
                            </div>
                        </div>
                    </div>
                {/* </div>
            </div> */}
        </ReactModal>
    </>);
}


export function QRCodeModal({modalIsOpen, closeModal, onConfirm, confirmLabel, title, subtitle, deviceId}) {
    // let subtitle;

    // const [modalIsOpen, setIsOpen] = React.useState(false);

    // function openModal() {
    //   setIsOpen(true);
    // }
  
    function afterOpenModal() {
      // references are now sync'd and can be accessed.
    //   subtitle.style.color = '#f00';
    }
  
    // function closeModal() {
    //   setIsOpen(false);
    // }

    const onConfirmAndClose = () => {
        onConfirm();
        closeModal()
    }

    return (<>
        <ReactModal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        // style={customStyles}
        contentLabel="Example Modal"
        overlayClassName={"bg-white absolute inset-0 z-modal flex items-center justify-center"}
        className={"h-full w-full flex items-center justify-center"}
        >
            {/* <div className="absolute inset-0 z-tooltip bg-grey-400/25 backdrop-blur-[5px] p-32 chrome:p-0 chrome:bg-transparent chrome:backdrop-blur-none"> */}
                {/* <div className="h-full w-full flex items-center justify-center"> */}
                    <div className="absolute inset-0 bg-white w-full h-full flex flex-col items-center justify-center px-[20px]">

                    <div class="absolute z-1 right-[24px] top-[24px]">
                        <button onClick={closeModal} class="p-[8px] transition duration-200 hover:bg-gray-100 active:bg-gray-150 rounded bg-white border border-solid border-gray-200 shadow-[0px_3px_6px_-2px_rgba(33,42,47,0.08)]">
                            <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 21 21" data-icon="close" height="20" width="20" class="text-grey-300"><path d="m15.4 5-10 10m0-10 10 10" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                        </button>
                    </div>

                        <h2 class="text-[24px] font-medium">To receive notes generated on your phone</h2>
                        <p class="text-[16px] mt-[4px] text-gray-500 max-w-[440px] mb-[20px]">Scan the code below with your phone.</p>

                        <div class="p-1 bg-gradient-to-b from-[#F0F0F0] to-white rounded">
                            <div class="p-[12px] bg-white rounded">
                                <div class="p-1 bg-gradient-to-b from-[#F0F0F0] to-white rounded">
                                    <div class="p-[12px] bg-white rounded">
                                        <div class="bg-gradient-to-br from-black to-[#363943] rounded">
                                            {/* <img alt="QR Code" src="" style={{imageRendering: "pixelated"}} /> */
                                            <QRCode value={BASE_URL + "link-device#" + deviceId} />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                {/* </div>
            </div> */}
        </ReactModal>
    </>);
}

export function QRReaderModal({modalIsOpen, closeModal, onConfirm, confirmLabel, title, subtitle, deviceId}) {
    // let subtitle;

    // const [modalIsOpen, setIsOpen] = React.useState(false);

    // function openModal() {
    //   setIsOpen(true);
    // }
  
    function afterOpenModal() {
      // references are now sync'd and can be accessed.
    //   subtitle.style.color = '#f00';
    }
  
    // function closeModal() {
    //   setIsOpen(false);
    // }

    const onConfirmAndClose = () => {
        onConfirm();
        closeModal()
    }
    const [data, setData] = useState('No result');


    return (<>
        <ReactModal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        // style={customStyles}
        contentLabel="Example Modal"
        overlayClassName={"bg-white absolute inset-0 z-modal flex items-center justify-center"}
        className={"h-full w-full flex items-center justify-center"}
        >
            {/* <div className="absolute inset-0 z-tooltip bg-grey-400/25 backdrop-blur-[5px] p-32 chrome:p-0 chrome:bg-transparent chrome:backdrop-blur-none"> */}
                {/* <div className="h-full w-full flex items-center justify-center"> */}
                    <div className="absolute inset-0 bg-white w-full h-full flex flex-col items-center justify-center px-[20px]">

                    <div class="absolute z-1 right-[24px] top-[24px]">
                        <button onClick={closeModal} class="p-[8px] transition duration-200 hover:bg-gray-100 active:bg-gray-150 rounded bg-white border border-solid border-gray-200 shadow-[0px_3px_6px_-2px_rgba(33,42,47,0.08)]">
                            <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 21 21" data-icon="close" height="20" width="20" class="text-grey-300"><path d="m15.4 5-10 10m0-10 10 10" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                        </button>
                    </div>

                    {/* <div className="flex top-0 h-[400px] w-[400px] max-h-full max-w-full"> */}
                        {/* <div className="flex top-0 h-[400px] w-[400px] max-h-full max-w-full"> */}
                            
                            <QrReader
                                className="flex top-0 h-[400px] w-[400px] max-h-full max-w-full"
                                // containerStyle={{color: 'red'}}
                                // videoContainerStyle={{color: 'blue'}}
                                videoStyle={{top: "0px", left: "0px", width: "100%", height: "100%", display: "block", overflow: "hidden", position: "absolute"}}
                                onResult={(result, error) => {
                                if (!!result) {
                                    setData(result?.text);
                                }

                                if (!!error) {
                                    console.info(error);
                                }
                                }}
                                style={{ width: '100%' }}
                            />
                        {/* </div> */}
                    {/* </div> */}

                        <h2 class="text-[24px] font-medium">Add your computer</h2>
                        <p class="text-[16px] mt-[4px] text-gray-500 max-w-[440px] mb-[20px]">
                        Open Copilot settings on your computer and click on the "Receive notes from phone" button, then scan the code with your phone.
                        </p>
                        <p>{data}</p>

                        
                    </div>
                {/* </div>
            </div> */}
        </ReactModal>
    </>);
}

