import { Navigate, Outlet } from "react-router-dom";

import { useAuth } from "../hooks/useAuth";

// https://blog.logrocket.com/complete-guide-authentication-with-react-router-v6/
// https://dev.to/sanjayttg/jwt-authentication-in-react-with-react-router-1d03
// https://ui.dev/react-router-protected-routes-authentication

export const ProtectedRoute = ({ children }) => {
  const { tokens } = useAuth();
  if (!tokens) {
    // user is not authenticated
    return <Navigate to="/" />;
  } else {
    //console.log("Found valid tokens...", tokens)
  }
  return children;
};

export const ProtectedLayout = ({ children }) => {
  const { tokens } = useAuth();
  if (!tokens) {
    // user is not authenticated
    return <Navigate to="/" />;
  } else {
    //console.log("Found valid tokens...", tokens)
  }
  return <Outlet/>;
};
