import React, { useEffect,useState } from 'react';
import { useForm  } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../hooks/useAuth";
import { useEncounters } from "../../hooks/useEncounters";
import {Modal} from '../../components/Modal'

const SANDBOX_EPIC_URL = "https://fhir.epic.com/interconnect-fhir-oauth/"
const R4_ENDPOINT = "https://fhir.epic.com/interconnect-fhir-oauth/api/FHIR/R4/"
const CONFIG_URL = "https://fhir.epic.com/interconnect-fhir-oauth/api/FHIR/R4/.well-known/smart-configuration"

// const configJson = get(CONFIG_URL)
// const authorizationEndpoint = configJson.authorization_endpoint;


export function SettingsAccountView({}) {

    const {
      control,
      register,
      handleSubmit,
      formState: {errors, isValid}
    } = useForm({mode: "onChange"})
    const [modalIsOpen, setModalIsOpen] = useState(false)

    const {
      clearEncounters,
    } = useEncounters()
  
    const navigate = useNavigate(); // <-- access navigate function
  
    const {user, logout} = useAuth();
  
    const onClickLogout = async () => {
      console.log("logout clicked")
      await logout()
      console.log("Clearing counters...")
      await clearEncounters();
      navigate('/')
    };
  
    return (<>
    <Modal
      modalIsOpen={modalIsOpen}
      closeModal={() => setModalIsOpen(false)}
      onConfirm={onClickLogout}
      confirmLabel={"Logout and delete"}
      title={"You’re about to log out."}
      subtitle={"By logging out, you will lose your consultation history, which is only stored locally."}
    />
      <div className="flex flex-col">
        <div className="text-[14px] font-medium text-primary mb-[4px]">
        Account
        </div>                            
        <input
            name="phoneNumber"
            placeholder="Phone Number"
            autoComplete="off"
            className="text-[14px] w-full rounded border border-solid border-[#ffffff77] bg-transparent p-[8px] leading-[24px] !outline-none text-left"
            disabled={true}
            defaultValue={user && user.phone}
            style={{border: "0.5px solid rgba(199,199,199 0.467)", background: "rgba(63, 64, 67, 0.1)"}}
            {...register("phoneNumber")}
          />
          <div className="flex mt-[4px] items-center justify-between w-full">
            <div className="flex items-center">
              {/* <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" data-icon="closeCircle" className="text-red-500"><path d="M3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path> <path d="M8 8L12 12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path> <path d="M12 8L8 12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg> */}
              <svg width="24" height="24" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" data-icon="check" className="text-green-300"><path d="M3.375 9.78879L5.70595 12.9814C6.43654 13.982 7.93611 13.9625 8.64054 12.9433L14.625 4.28394" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path></svg>
              <div className="font-semibold text-[14px] ms-[2px]">Signed-in</div>
            </div>
            <button onClick={() => setModalIsOpen(true)} className="text-[14px] font-medium px-[8px] py-[4px] rounded-sm bg-black text-white hover:brightness-110 active:brightness-125">
              Logout
            </button>
          </div>
      </div>

      <hr class="bg-gray-200 w-full h-[1px] border-0 my-[10px]" />

      <div className="flex flex-col ">
        <div className="text-[14px] font-medium text-primary mb-[4px] ">
        Subscription
        </div>   
        <div className="text-[14px] leading-[20px]">
          <p>Free plan ({user && user.phone}) limited to 30 consultations per month.</p>
          <p className="mt-[16px]">
            <a className="underline" target="_blank" rel="noreferrer" href="mailto:andrienko@live.ca">Contact us</a> to upgrade for $99/month and have unlimited consultations.
          </p>
        </div>
      </div>

      <hr class="bg-gray-200 w-full h-[1px] border-0 my-[10px]" />

      <div className="flex flex-col ">
        <div className="text-[14px] font-medium text-primary mb-[4px] ">
        EHR Connections
        </div>   
        <div className="text-[14px] leading-[20px]">
          <p>Sign-in to your EHR Vendor below to enable note export.</p>
          <button disabled={true} onClick={() => console.log("epic sign-in clicked")} className="pointer-none w-full rounded text-[14px] font-medium px-[8px] py-[8px] rounded-sm bg-gray-400 text-white my-[8px]">
              Connect with Epic / Coming Soon
          </button>
        </div>
      </div>
  
  
      {/* <div className="flex flex-col text-white">
        <div className="text-[14px] font-medium text-primary mb-[4px] text-white">
        Personal Information
        </div> 
          <CustomTextAreaInNote
            name="personalInformation"
            placeholder="Personal Information"
            autoComplete="off"
            // className="text-[14px] w-full rounded border border-solid border-[#ffffff77] bg-transparent p-[8px] leading-[24px] text-white !outline-none text-left"
            disabled={false}
            // value={"Personal information"}
            // style={{border: "0.5px solid rgba(255, 255, 255, 0.467)", background: "rgb(63, 64, 67)"}}
            {...register("personal_information")}
          />
      </div> */}
  
      {/* <div className="flex flex-col ">
        <div className="text-[14px] font-medium text-primary mb-[4px]">Privacy &amp; Security</div>
        <div className="text-[14px] mb-[4px]">
          Audio, transcripts and notes are not stored by Notatio. <a href="https://www.nabla.com/blog/privacy-security/" target="_blank" rel="noreferrer" className="underline">More info on how we protect data</a>.
        </div>
      </div> */}
  
  
    </>);
  }
  
  