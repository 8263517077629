import React, { useState, useEffect } from 'react';
import { useForm  } from "react-hook-form";
import {
    useNavigate,
    useLocation,
    Link } from "react-router-dom";

import { ReactSelect, ButtonContainer, ButtonTextContainer } from '../../components/forms';
import { useStorageHook } from "../../hooks/useLocalStorage";
import { BackSvgIcon } from '../../components/svgs'


const MEDICAL_SPECIALTIES = [
  { value: "ADDICTION_MEDICINE", label: "Addiction Medicine" },
  { value: "ADOLESCENT_AND_YOUNG_ADULT_MEDICINE", label: "Adolescent And Young Adult Medicine" },
  { value: "ALLERGY", label: "Allergy" },
  { value: "ANESTHESIOLOGY", label: "Anesthesiology" },
  { value: "BEHAVIORAL_HEALTH", label: "Behavioral Health" },
  { value: "CARDIOLOGY", label: "Cardiology" },
  { value: "CHIROPRACTIC", label: "Chiropractic" },
  { value: "DENTISTRY", label: "Dentistry" },
  { value: "DERMATOLOGY", label: "Dermatology" },
  { value: "DIABETOLOGY", label: "Diabetology" },
  { value: "DIET", label: "Diet" },
  { value: "EAR_NOSE_AND_THROAT_MEDICINE", label: "Ear Nose And Throat Medicine" },
  { value: "EMERGENCY_MEDICINE", label: "Emergency Medicine" },
  { value: "ENDOCRINOLOGY", label: "Endocrinology" },
  { value: "FAMILY_MEDICINE", label: "Family Medicine" },
  { value: "FORENSIC_MEDICINE", label: "Forensic Medicine" },
  { value: "GASTROENTEROLOGY", label: "Gastroenterology" },
  { value: "GENERAL_MEDICINE", label: "General Medicine" },
  { value: "GENETICS", label: "Genetics" },
  { value: "GERIATRICS", label: "Geriatrics" },
  { value: "HEMATOLOGY", label: "Hematology" },
  { value: "HEPATOLOGY", label: "Hepatology" },
  { value: "HOSPITAL_MEDICINE", label: "Hospital Medicine" },
  { value: "IMMUNOLOGY", label: "Immunology" },
  { value: "INFECTIOUS_DISEASES", label: "Infectious Diseases" },
  { value: "INTERNAL_MEDICINE", label: "Internal Medicine" },
  { value: "LACTATION_CONSULTANT", label: "Lactation Consultant" },
  { value: "LIFESTYLE_MEDICINE", label: "Lifestyle Medicine" },
  { value: "MENTAL_HEALTH", label: "Mental Health" },
  { value: "MIDWIFERY", label: "Midwifery" },
  { value: "NEPHROLOGY", label: "Nephrology" },
  { value: "NEUROLOGY", label: "Neurology" },
  { value: "NEUROSURGERY", label: "Neurosurgery" },
  { value: "NURSING", label: "Nursing" },
  { value: "NUTRITION", label: "Nutrition" },
  { value: "OBSTETRICS_GYNECOLOGY", label: "Obstetrics Gynecology" },
  { value: "OCCUPATIONAL_MEDICINE", label: "Occupational Medicine" },
  { value: "ONCOLOGY", label: "Oncology" },
  { value: "OPHTHALMOLOGY", label: "Ophthalmology" },
  { value: "ORTHOPEDICS", label: "Orthopedics" },
  { value: "OSTEOPATHY", label: "Osteopathy" },
  { value: "PAIN_MEDICINE", label: "Pain Medicine" },
  { value: "PEDIATRICS", label: "Pediatrics" },
  { value: "PHYSICAL_REHABILITATION_MEDICINE", label: "Physical Rehabilitation Medicine" },
  { value: "PHYSIOTHERAPY", label: "Physiotherapy" },
  { value: "PLASTIC_SURGERY", label: "Plastic Surgery" },
  { value: "PODIATRY", label: "Podiatry" },
  { value: "PREVENTIVE_MEDICINE", label: "Preventive Medicine" },
  { value: "PSYCHIATRY", label: "Psychiatry" },
  { value: "PSYCHOLOGY", label: "Psychology" },
  { value: "PSYCHOTHERAPY", label: "Psychotherapy" },
  { value: "PUBLIC_HEALTH", label: "Public Health" },
  { value: "PULMONOLOGY", label: "Pulmonology" },
  { value: "RADIOLOGY", label: "Radiology" },
  { value: "RHEUMATOLOGY", label: "Rheumatology" },
  { value: "SEXOLOGY", label: "Sexology" },
  { value: "SPORT_MEDICINE", label: "Sport Medicine" },
  { value: "SUBSTANCE_USE_DISORDER", label: "Substance Use Disorder" },
  { value: "SURGERY", label: "Surgery" },
  { value: "TOXICOLOGY", label: "Toxicology" },
  { value: "UROLOGY", label: "Urology" },
  { value: "VASCULAR_MEDICINE", label: "Vascular Medicine" },
  { value: "VETERINARY_MEDICINE", label: "Veterinary Medicine" },
  { value: "WOUND_MEDICINE", label: "Wound Medicine" },
  { value: "OTHER", label: "Other" }
];


export function GeneralInfoPage() {

    const [loading, setLoading] = useState(false);
    const [defaultCountry, setDefaultCountry, loadingCountry] = useStorageHook('country', '')
    // const [phoneCountryCode, phoneCountryCodeSetter] = useState('CA');
    const navigate = useNavigate(); // <-- access navigate function
  
    const location = useLocation()

    useEffect(() => {
      if (!location.state || !location.state.inviteCode) {
        navigate("/auth/welcome");
      }

    }, [])


    useEffect(() => {
      // guess country.
      if (!loadingCountry && !defaultCountry) {
        fetch('https://api.ipregistry.co/?key=tryout')
        .then(function (response) {
            return response.json();
        })
        .then(function (payload) {
            console.log(payload.location.country.name + ', ' + payload.location.city);
            if (payload.location.country.name == "Canada") {
              setDefaultCountry("CA")
            } else if (payload.location.country.name == "United States") {
              setDefaultCountry("US")
            }
        })
        .catch(err => {
          setDefaultCountry('')
        });
      }

    }, [loadingCountry])
  
  
    const {
      control,
      register,
      handleSubmit,
      formState: {errors, isValid}
    } = useForm({mode: "onChange"})
  
    const onSubmit = async (data) => {
      const {specialty, countryCode} = data;
      console.log("countryCode:", countryCode)
      navigate("/auth/signup", { replace: true, state:{specialty, countryCode, inviteCode: location.state.inviteCode  }});
    }

    console.log("defaultCountry: ", defaultCountry)
  
    return (<>
          {/* <form onSubmit={handleSubmit(onSubmit)}> */}
     {/* MainContent */}
     <form className="" onSubmit={handleSubmit(onSubmit)}>
  
        {/* <div className="flex justify-center py-[50px]">
          <div className="relative self-center">

          </div>
        </div> */}
  
        <div className="flex flex-col">
          <div className="flex flex-col items-center justify-center mb-[24px] h-[64px]">
            <h1 className="text-[24px] text-center font-medium">
              What is your medical specialty?
            </h1>
          </div>
          {/* <span className="text-[14px] text-center text-white">
            Enter the invite code you were provided above. Don't have one? Email us.
          </span> */}
        </div>
  
        {/* Form */}
        <div className="mt-[24px] w-full flex-col items-center space-y-[8px]">
          {/* <label htmlFor="invite-code" className="block text-sm font-medium leading-6 text-gray-500 text-center">Enter the invite code you were provided above. Don't have one? Email us</label> */}
          {/* <input
            name="invite-code"
            placeholder="Invite Code"
            className="text-[14px] w-full rounded border border-solid border-[#ffffff77] bg-transparent p-[8px] leading-[24px] text-white !outline-none text-center"
          /> */}
          {/* <label htmlFor="invite-code" className="block text-sm font-medium leading-6 text-gray-500 text-center">Enter the invite code you were provided above. Dom't have one? Email us</label> */}
          
          <div className="text-md font-medium text-primary mb-[4px]">Medical specialty</div>
          {/* <div className="w-full flex flex-shrink-0 select-none p-[8px] border-[0.5px] border-grey-300 rounded text-[14px]">
            <div className="flex w-full items-center">
              <span className="truncate">General medicine</span>
              <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 21 21" data-icon="chevron" height="26" width="26" className="ml-auto hover:opacity-80 outline-0 flex-shrink-0" tabIndex="-1" style={{"transform": "rotate(90deg);"}}><path d="m8.65 6.75 3.5 3.25-3.5 3.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
            </div>
          </div> */}
  
          <ReactSelect name="specialty"
            options={MEDICAL_SPECIALTIES}
            defaultValue={'GENERAL_MEDICINE'}
            control={control}
            isSearchable={true}
          />
          
          <div className="text-md font-medium text-primary mb-[4px]">Country of practice</div>
          {/* <div className="flex w-full">
            <div className="w-full flex flex-shrink-0 select-none p-[8px] border-[0.5px] border-gray-300 rounded text-[14px]">
              <div className="flex w-full items-center">
                <span className="truncate">Canada</span>
                <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 21 21" data-icon="chevron" height="26" width="26" className="ml-auto hover:opacity-80 outline-0 flex-shrink-0" tabIndex="-1" style={{"transform": "rotate(90deg);"}}><path d="m8.65 6.75 3.5 3.25-3.5 3.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
              </div>
            </div>
          </div> */}
  
          {/* <DropDownWithScrolling />
           */}

           {!loadingCountry && (
            <ReactSelect name="countryCode" options={[
                { value: 'CA', label: 'Canada'},
                { value: 'US', label: 'United States'},
              ]}
              defaultValue={defaultCountry}
              control={control}
            />
          )}
  
          {/* <div className="flex flex-col">
            <div className="flex flex-col items-center justify-center mb-[24px] h-[64px]">
              <h1 className="text-24 text-center font-medium">What is your medical specialty?</h1>
            </div>
            <div className="text-[14px] font-medium text-primary mb-[4px]">Medical specialty</div>
            <div className="flex w-full">
              <div className="w-full flex flex-shrink-0 select-none p-[8px] border-[0.5px] border-grey-300 rounded text-[14px]">
                <div className="flex w-full items-center">
                  <span className="truncate">General medicine</span>
                  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 21 21" data-icon="chevron" height="26" width="26" className="ml-auto hover:opacity-80 outline-0 flex-shrink-0" tabIndex="-1" style={{"transform": "rotate(90deg);"}}><path d="m8.65 6.75 3.5 3.25-3.5 3.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                </div>
              </div>
            </div>
            <div className="flex-col w-full mt-16 items-start">
              <div className="text-[14px] font-medium text-primary mb-[4px]">Country of practice</div>
              <div className="flex w-full">
                <div className="w-full flex flex-shrink-0 select-none p-[8px] border-[0.5px] border-gray-300 rounded text-[14px]">
                  <div className="flex w-full items-center">
                    <span className="truncate">Canada</span>
                    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 21 21" data-icon="chevron" height="26" width="26" className="ml-auto hover:opacity-80 outline-0 flex-shrink-0" tabIndex="-1" style={{"transform": "rotate(90deg);"}}><path d="m8.65 6.75 3.5 3.25-3.5 3.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
  
        </div>
        {errors && errors.name}
        {/* End Form */}
  
        {/* Form */}
        {/* <div className="my-[24px] w-full flex-col items-center space-y-[8px] text-white text-center">
          <label htmlFor="invite-code" className="block text-sm font-medium leading-6 text-gray-500">Enter the invite code you were provided below.</label>
          <input name="invite-code" placeholder="Invite Code" className="text-[14px] w-full rounded border border-solid border-[#ffffff77] bg-transparent p-[8px] leading-[24px] text-white !outline-none text-center" />
        </div> */}
        {/* End Form */}
  
        <div className="flex-fill"></div>
  
        {/* <div className="flex flex-col">
          <form>
            <PhoneInput
              placeholder="Enter phone number"
              defaultCountry='US'
              international={false}
              value={value}
              countrySelectProps={{ unicodeFlags: true }}
              onChange={setValue}/>
          </form>
        </div> */}
  
  
        {/* ActionButton */}
        <div className="flex-col items-center mt-[32px]">
            <ButtonContainer disabled={!isValid} type="submit">
              <ButtonTextContainer disabled={!isValid}
                // css={!isValid && disabledButton}
                // className="disabled:bg-[#404040]"
              >
                <div className="flex justify-center items-center space-x-[4px] text-white">
                  {/* <MicrophoneSvgIcon /> */}
                  <div>{loading ? "Loading" : "Next"}</div>
                </div>
              </ButtonTextContainer>
            </ButtonContainer>
          {/* </Link> */}
        </div>
        {/* ./End ActionButton */}
  
        {/* Sign in text */}
        {/* <div className="mt-[8px] pt-[4px] text-[14px] text-grey-[2px] leading-[24px] text-center text-white">
          Already have an account? <a className="text-primary hover:underline cursor-pointer font-medium text-blue-600" target="_blank" rel="noreferrer">Sign in</a>
        </div> */}
        {/* END Sign in text */}
  
    </form>
    {/* ./End MainContent */}
    {/* </form> */}
  
    </>
    )
  }
  