import React, { useState, useEffect, createRef } from 'react';
import { useForm  } from "react-hook-form";
import {
    useNavigate,
    useLocation,
    Link } from "react-router-dom";
import { 
    PhoneInput,
    ButtonTextContainer,
    ButtonContainer } from '../../components/forms';

import { BackSvgIcon } from '../../components/svgs'

import { useAuth } from "../../hooks/useAuth";



// https://stackoverflow.com/questions/71145871/how-to-focus-the-next-input-with-react
export function ConfirmPage(props) {

    const [loading, setLoading] = useState()
    const [value, setValue] = useState()
    const [error, setError] = useState()
    const [success, setSuccess] = useState()
    const navigate = useNavigate(); // <-- access navigate function
    const { user, verify } = useAuth();
  
    const location = useLocation()
    // const location = {
    //   state: {
    //     phoneNumber: "+17788373432",
    //     phoneCountryCode: "CAD"
    //   }
    // };
  
    const {
      control,
      register,
      handleSubmit,
      formState: {errors, isValid}
    } = useForm({mode: "onChange"})

    // phoneNumber, phoneCountryCode, withExistingAccount, specialty, inviteCode
    useEffect(() => {
      console.log("LOCAITON: ", location.state)
      if (!location.state ||
          !location.state.withExistingAccount && (
            location.state.phoneCountryCode == null ||
            location.state.phoneNumber == null ||
            location.state.specialty == null ||
            location.state.inviteCode == null) ||
          location.state.withExistingAccount && (
            location.state.phoneCountryCode == null ||
            location.state.phoneNumber == null)
       ) {
        navigate("/auth/welcome");
      }
    }, [location])

    const { signup, login} = useAuth();
  
  
    const onSubmit = async (data) => {
      const code = data.code.join("")
      setValue(data)
  
      setLoading(true)
      const response = await verify(phoneNumber, code);
      if (response.error) {
        setError(response.error)
      } else {
        navigate("/dashboard/current/note");
      }
      setLoading(false)
    }

    const onResendInviteCode = async () => {
      console.log("onResend: ", location.state)
      setLoading(true)
      const response = location.state.withExistingAccount
        ? await login(location.state.phoneNumber, location.state.phoneCountryCode)
        : await signup(location.state.phoneNumber, location.state.phoneCountryCode, location.state.specialty, location.state.inviteCode)
      setLoading(false)
      if (response.error) {
        setError(response.error)
      } else {
        setSuccess("Resent authorization code!")
      }
    }
  
    const { phoneNumber, phoneCountryCode } = location.state
  
    useEffect(() => {
      if (!phoneNumber || !phoneCountryCode) {
        navigate('/auth/signup')
      } else {
        console.log("Sending SMS to ", phoneNumber)
      }
    }, [phoneNumber]);
  
    // number of inputs
    const numerOfInputs = props.numerOfInputs || 4;
  
    // create a array of refs
    const [inputRefsArray] = useState(() =>
      Array.from({ length: numerOfInputs }, () => createRef())
    );
    // state for current input index
    const [currentIndex, setCurrentIndex] = useState(0);
  
    // save letters in a array where each entry in the array refers to an input
    const [letters, setLetters] = useState(() =>
      Array.from({ length: numerOfInputs }, () => "")
    );
  
    const handleKeyPress = (event) => {
      const key = event.keyCode || event.charCode;
      setCurrentIndex((prevIndex) => {
        // calculate the next input index, next input after the final input will be again the first input.
        // you can change the logic here as per your needs
        const nextIndex = prevIndex < numerOfInputs - 1 ? prevIndex + 1 : -1;
        if (key === 8 || key === 46) {
          const prevPrevIndex = prevIndex > 0 ? prevIndex - 1 : 0;
          const prevPrevInput = inputRefsArray?.[prevPrevIndex]?.current;
          prevPrevInput.focus();
          prevPrevInput.select();
          return prevPrevIndex;
        }
        if (nextIndex == -1) {
          inputRefsArray?.[prevIndex]?.current.blur();
          return prevIndex;
        } else {
          const nextInput = inputRefsArray?.[nextIndex]?.current;
          nextInput.focus();
          nextInput.select();
          return nextIndex;
        }
      });
    };
  
    useEffect(() => {
      // focus the firs iput initially
      if (inputRefsArray?.[0]?.current) {
        inputRefsArray?.[0]?.current?.focus();
      }
      
      // add the event listener for keyup keyboard event
      window.addEventListener("keyup", handleKeyPress, false);
      
      // remove the event listener when the component unmounts
      return () => {
        window.removeEventListener("keyup", handleKeyPress);
      };
    }, []);
  
  
    return (<>
     {/* MainContent */}
     <form className="" onSubmit={handleSubmit(onSubmit)}>
  
{/*   
        <div className="flex justify-center py-[50px]">
          <div className="relative self-center">

          </div>
        </div> */}
  
        <div className="flex flex-col">
          <div className="flex flex-col items-center justify-center mb-[24px] h-[64px]">
            <h1 className="text-[24px] text-center font-medium">
              Confirm phone number
            </h1>
          </div>
          <span className="text-[14px] text-center">
          We sent a <i>4-digit</i> verification code to your phone. Enter the code from the phone in the field below.
          </span>
        </div>
  
        {/* Form */}
        {/* <div className="mt-[24px] w-full flex-col items-center space-y-[8px] text-white"> */}
        <div className="grid grid-cols-4 gap-[8px] mt-[24px]">
        {inputRefsArray.map((inputRef, index) => {
          const { ref, onChange, ...rest } = register(`code.${index}`);
          return (
            <input
              type="tel"
              pattern="[0-9]*" novalidate
              ref={(e) => {
                ref(e);
                inputRef.current = e;
              }}
              key={index}
              id={`box${index}-1`}
              // type="text"
              autoComplete="off"
              onChange={(e) => {
                const { value } = e.target;
                setLetters((letters) =>
                  letters.map((letter, letterIndex) =>
                    letterIndex === index ? value : letter
                  )
                );
                onChange(e);
              }}
              onClick={(e) => {
                setCurrentIndex(index);
                e.target.select();
              }}
              value={letters[index]}
              max={"1"}
              className="text-[14px] w-full rounded border border-solid border-[#00000077] bg-transparent p-[8px] leading-[24px] !outline-none text-center"
              // {...register(`code${index}`)}
              {...rest}
              />
          )
        })}
          {/* <input name="email" className="text-[14px] w-full rounded border border-solid border-[#ffffff77] bg-transparent p-[8px] leading-[24px] text-white !outline-none text-center" /> */}
          {/* <input name="email" className="text-[14px] w-full rounded border border-solid border-[#ffffff77] bg-transparent p-[8px] leading-[24px] text-white !outline-none text-center" /> */}
          {/* <input name="email" className="text-[14px] w-full rounded border border-solid border-[#ffffff77] bg-transparent p-[8px] leading-[24px] text-white !outline-none text-center" /> */}
          {/* <PhoneNumberForm /> */}
        </div>
        <span className="text-red-500 text-[14px]">{error && error}</span>
        <span className="text-green-500 text-[14px]">{success && success}</span>
        {/* End Form */}
  
        <div className="flex-fill"></div>
  
        {/* <div className="flex flex-col">
          <form>
            <PhoneInput
              placeholder="Enter phone number"
              defaultCountry='US'
              international={false}
              value={value}
              countrySelectProps={{ unicodeFlags: true }}
              onChange={setValue}/>
          </form>
        </div> */}
  
        {/* ActionButton */}
        <div className="flex-col items-center mt-[32px]">
            <ButtonContainer type="submit">
  
              <ButtonTextContainer>
                <div className="flex justify-center items-center space-x-[4px] text-white">
                  {/* <MicrophoneSvgIcon /> */}
                  <div>{loading ? "Loading..." : "Sign-in with code"}</div>
                </div>
              </ButtonTextContainer>
  
            </ButtonContainer>
          </div>
        {/* ./End ActionButton */}
  
        {/* Sign in text */}
        <div className="mt-[8px] pt-[4px] text-[14px] text-grey-[2px] leading-[24px] text-center ">
          <button onClick={onResendInviteCode} className="text-primary hover:underline cursor-pointer font-medium text-blue-600">
            Resend code
          </button>
        </div>
        {/* END Sign in text */}
  
    </form>
    {/* ./End MainContent */}
  
    </>
    )
  }
  