import React, { useEffect, useState, useRef } from 'react';
import {useOutletContext} from 'react-router-dom'
import { useForm  } from "react-hook-form";
import {
    useNavigate,
    useLocation,
    Link } from "react-router-dom";

import { ReactSelect, AutoResizeTextarea} from '../../components/forms';
import { useTranscriptionContext } from '../../hooks/useTranscription';
import { useNoteDigest } from '../../hooks/useNoteDigest';
import { useEncounters } from '../../hooks/useEncounters';
  
import styled from 'styled-components'

import { 

  ButtonTextContainerWhite as ButtonTextContainer,
  ButtonContainerWhite as ButtonContainer,
  ButtonChevronContainerWhite as ButtonChevronContainer, 
  ButtonSeparatorWhite as ButtonSeparator} from '../../components/forms';


  import {

    MicrophoneSvgIcon,
    ChevronSvgIcon,
    CopySvgIcon,
  
    CheckMarkSvgIcon,
    PauseSvgIcon,
    RedoSvgIcon,
    TrashSvgIcon
  } from '../../components/svgs'

import { ChromeDraggableElement, useMinimizedStateContext } from '../../App';
import { findAllByTestId } from '@testing-library/react';

const RecorderAnimationRecording = styled.div`
width: auto;
display: flex;
height: 100px;
align-items: center;
& {
  span {
    width: 15px;
    height: 15px;
    margin: 0px 3px;
    background-color: white;
    border-radius: 35px;
    //transform:scaleY(0.15);
    opacity: 1.0;
    // transition: height 3s ease-in-out;
    //transition: height 1s ease-out;
    // animation-iteration-count: 1;

  }

  .is_active {
    animation: loadingRecording 0.9s ease-in-out infinite normal;
    // animation-iteration-count: infinite;
  }

  span:nth-child(1) {
    background-color: #31C0F0;
    animation-delay: 0.2s;
  }

  span:nth-child(2) {
    background:#AB58F5;
    animation-delay:0.3s;
  }
  span:nth-child(3) {
    background:#3CD9BB;
    animation-delay:0.4s;
  }
  span:nth-child(4) {
    background:#FEB954;
    animation-delay:0.5s;
  }
  span:nth-child(5) {
    background:#EF519B;
    animation-delay:0.6s;
  }


  @keyframes loadingRecording {
    0% {
      // transform: scaleY(0.15);
      height: 15px;
    }

    35% {
      // transform: scaleY(1);
      height: 100px;
    }

    60%, 100% {
      // transform: scaleY(0.15);
      height: 15px;
    }
  }

  @keyframes {
    100% {
      height: 100px
    }
  }
}
`;


const RecorderAnimationNote = styled.div`
width: auto;
display: flex;
height: 100px;
align-items: center;
& {
  span {
    width: 15px;
    height: 15px;
    margin: 0px 3px;
    background-color: white;
    border-radius: 35px;
    //transform:scaleY(0.15);
    opacity: 1.0;
  }
  
  .is_active_note {
    animation: loadingNote 0.9s ease-in-out infinite;
  }

  span:nth-child(1) {
    background-color: #31C0F0;
    animation-delay: 0.2s;
  }

  span:nth-child(2) {
    background:#AB58F5;
    animation-delay:0.3s;
  }
  span:nth-child(3) {
    background:#3CD9BB;
    animation-delay:0.4s;
  }
  span:nth-child(4) {
    background:#FEB954;
    animation-delay:0.5s;
  }
  span:nth-child(5) {
    background:#EF519B;
    animation-delay:0.6s;
  }


  @keyframes loadingNote {
    0% {
      // transform: scaleY(0.15);
      // height: 15px;
      opacity: 0.0;
    }

    35% {
      // transform: scaleY(1);
      // height: 100px;
      opacity: 1.0;
    }

    60%, 100% {
      // transform: scaleY(0.15);
      // height: 15px;
      opacity: 0.0;
    }
  }
}
`;

const FiguresGradient = () => (
    <figure class="absolute w-[80%] md:w-[65%]" style={{
      transform: "rotate(180deg)",
      right: "0px",
      bottom: "0px",
    }}>
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1246 1078">
        <g opacity=".4">
        <linearGradient id="doubleEllipseTopLeftID1" gradientUnits="userSpaceOnUse" x1="2073.5078" y1="1.7251" x2="2273.4375" y2="1135.5818" gradientTransform="matrix(-1 0 0 1 2600 0)">
            <stop offset="0.4976" style={{stopColor: '#252d41'}}></stop>
            <stop offset="1" style={{stopColor: '#111828fa'}}></stop>
        </linearGradient>
        <polygon fill="url(#doubleEllipseTopLeftID1)" points="519.8,0.6 0,0.6 0,1078 863.4,1078   "></polygon>
        <linearGradient id="doubleEllipseTopLeftID2" gradientUnits="userSpaceOnUse" x1="1717.1648" y1="3.779560e-05" x2="1717.1648" y2="644.0417" gradientTransform="matrix(-1 0 0 1 2600 0)">
            <stop offset="1.577052e-06" style={{stopColor: '#252d41'}}></stop>
            <stop offset="1" style={{stopColor: '#111828fa'}}></stop>
        </linearGradient>
        <polygon fill="url(#doubleEllipseTopLeftID2)" points="519.7,0 1039.4,0.6 1246,639.1 725.2,644   "></polygon>
        </g>
    </svg>
    </figure>
);

function LoaderSpinner() {
  return (
    <div className="flex flex-col">
    <div className="flex flex-col items-center justify-center mb-[24px] h-[64px]">
        <div role="status">
            <svg aria-hidden="true" className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
            </svg>
            <span className="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  );
}


export function EncounterView() {

    // console.log("Notes: ", note)
    // const note = notes && notes[0]

    const [fade, setFade] = useState(true)
    const [time, setTime] = useState(null)
    const [initialTime, setInitialTime] = useState(null)
    const [currentDuration, setCurrentDuration] = useState(null)
    const intervalRef = useRef()
    const navigate = useNavigate(); // <-- access navigate function

    // useEffect(() => {
    //     setTimeout(() => setFade(true), 100)
    // }, [])

    // const {isMinimized, toggle} = useMinimizedStateContext()
    const isMinimized = false;
    let location = useLocation();
    
      const [isMainButtonOpen, setIsMainButtonOpenSetter] = useState(false);
      const [stopState, SetstopState] = useState();
  
      // const onMouseUp = () => {
      //     setIsMainButtonOpenSetter(false)
      //     window.removeEventListener('mouseup', onMouseUp);
      // }
  
      const setIsMainButtonOpen = (value) => {
        // window.addEventListener('mouseup', onMouseUp);
        setIsMainButtonOpenSetter(value)
      }
  
      const buttonTextContainerExtraStyles = isMinimized ? {
        borderRadius: "8px",
        paddingTop: "4px",
        paddingBottom: "4px",
        fontSize: "14px",
        backgroundColor: 'white',
      } : {
        backgroundColor: 'white',
      }

  // const {
    // noteHistory,
    // currentNoteId,
    // loading,
    // currentNote,
    // updateNoteSection,
    // generateNote
  // } = useNoteDigest()

  const {currentEncounter, updateNoteSection} = useEncounters()

  const recordingStartTime = (
    currentEncounter && currentEncounter.transcript.sessions &&
    currentEncounter.transcript.sessions.length > 0 &&
    currentEncounter.transcript.sessions[0].startedAt || undefined);
  console.log("currentEncounter: ", currentEncounter)
  console.log("currentEncoutner START TIME: ", recordingStartTime)

  const [
    shouldShowNoteTab,
    isNewNote,
    isLoadingNote,
    recording,
    loadingRecorder,
    onEndRecording,
    onResumeRecording,
    onStartRecording,
    onPauseRecording,
    onClickCopyTranscript,
    onClickCopyNote,
    onClickRegenerateNote,
    onDiscardConsultation,
    hasTranscript,
    hasNote,
    error,
    displayCheck,
    duration
  ] = useOutletContext()


  const noteGeneration = (
    currentEncounter &&
    currentEncounter.completedNoteGenerations[currentEncounter.completedNoteGenerations.length - 1])
  const note = noteGeneration &&  noteGeneration.note
  console.log("Notes: ", note)

  const transcript = currentEncounter && currentEncounter.transcript.sessions || [];

    // const {
    //   control,
    //   register,
    //   handleSubmit,
    //   watch,
    //   formState: {errors, isValid}
    // } = useForm({mode: "onChange"})
  
    // const onSubmit = (data) => console.log(data)
  
    // useEffect(() => {
    //     // TypeScript users 
    //     // const subscription = watch(() => handleSubmit(onSubmit)())
    //     const subscription = watch(handleSubmit(onSubmit));
    //     return () => subscription.unsubscribe();
    // }, [handleSubmit, watch]);

    const loading = false;

  const formatDate = (secs) => {
    console.log('formatDFate->secs : ', secs)
    var date = new Date(0);
    date.setSeconds(secs); // specify value for SECONDS here
    var timeString = date.toISOString().substring(14, 19);
    return timeString
  }


    // useEffect(() => {

    //   // intervalRef.current = setInterval()
    //   if (recording) {
    //     intervalRef.current = setInterval(() => {
    //       const recordingStartTimeCurr = (
    //         currentEncounter && currentEncounter.transcript.sessions &&
    //         currentEncounter.transcript.sessions.length > 0 &&
    //         currentEncounter.transcript.sessions[0].startedAt || undefined);
    //       const dataTime = new Date().getTime();
    //       // console.log("timestamp: ", dataTime, recordingStartTimeCurr, dataTime- recordingStartTimeCurr)
    //       const timeDiff = (dataTime - recordingStartTimeCurr) / 1000
    //       console.log("timestamp: ", formatDate(timeDiff))
          
    //       setTime(timeDiff)
    //     }, 1000)
    //   } else if (!recording && intervalRef.current) {
    //     clearInterval(intervalRef.current)
    //   }
  
    //   return () => {
    //     if (intervalRef.current) {
    //       clearInterval(intervalRef.current)
    //     }
    //   };
    // }, [recording])

    let isPWA = false;
    if (window) {
      isPWA = window.matchMedia('(display-mode: standalone)').matches
    }
    
    useEffect(() => {

      if (document) {
        document.querySelector("meta[name='theme-color']").content = "#111828";
      }

      return () => {
        if (document) {
          document.querySelector("meta[name='theme-color']").content = "#ffffff";
        }
      };
    }, [])

    useEffect(() => {
      // set initial duration
      let initialDuration = 0;
      if (currentEncounter && currentEncounter.transcript.sessions && currentEncounter.transcript.sessions.length > 0) {
        initialDuration = currentEncounter.transcript.sessions.reduce((accumulator, currentValue) => accumulator + currentValue.duration, 0)

      }
      setInitialTime(initialDuration)
      // setCurrentDuration(0)
      setTime(initialDuration)
      console.log("ENCOUNTERVIEW: INITIAL DURTATION: ", formatDate(initialDuration / 1000))
    }, [])

    useEffect(() => {
      if ((initialTime + duration) > time) {
        console.log("ENCOUNTERVIEW: DURATION IN ENCOUNTER SCREEN: ", duration, '-', formatDate(initialTime/1000), formatDate(duration/1000), formatDate((initialTime + duration) / 1000))
        setTime((initialTime + duration))
      } else {
        console.log("ENCOUNTERVIEW: DURATION RESET!", formatDate((initialTime + duration) / 1000), '--',  formatDate((time) / 1000) )
        setInitialTime(time)
      }
      //setCurrentDuration(duration)
    }, [duration])

    

    const onStopClicked = async () => {
      // console.log("ENCOUNTERVIEW: onStopClicked", formatDate((initialTime + duration) / 1000))
      // setInitialTime(initialTime + duration)
      // setTime(initialTime + duration)
      await onPauseRecording()
      navigate('../note')
    }

  
  
    return (<div className={`fixed inset-0 w-full h-full flex items-center justify-center bg-[#111828] z-10 transition-all duration-300 ease-in-out	 ${fade ? "opacity-100" : "opacity-0"}`} style={{backgroundColor: '#111828 !important'}}>

            <FiguresGradient />
{/* 
              {error && 
              <div className="absolute top-0 left-0 w-full p-[4px] py-[18px] bg-red-500 text-center rounded z-20">
                <span className=" text-[12px] text-align-center font-bold text-white">{error}</span>
              </div>
            } */}

            <span onClick={onStopClicked} className='z-30'>
                <button class="p-[8px] bg-transparent absolute start-[12px] top-[12px] sm:start-[24px] sm:top-[24px] text-white z-20" type="button">
                    <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" data-icon="backArrowBold" class="!text-white"><path d="M8.54 5.63 3.96 10l4.58 4.38m7.5-4.38H4.17" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"></path></svg>
                </button>
            </span>

            <div className="w-full h-full flex items-center justify-center max-w-full sm:max-w-[360px]">
            {/* <div className="flex flex-col flex-center gap-[30px] pointer-events-none flex-1 px-[8px] justify-center z-20"> */}


            <div className="flex w-full flex-col justify-between px-[16px] pb-[16px] space-y-[16px] relative flex-shrink-0 _step_p31s5_1 h-full sm:h-auto z-20">

            {error && 
              <div className="absolute block md:hidden top-0 left-0 w-full p-[4px] py-[18px] bg-red-500 text-center z-20">
                <span className=" text-[12px] text-align-center font-bold text-white">{error}</span>
              </div>
            }

            {error && 
              <div className="flex flex-col hidden md:block w-full p-[4px] py-[18px] bg-red-500 text-center rounded z-20">
                <span className=" text-[12px] text-align-center font-bold text-white">{error}</span>
              </div>
            }
  
            <div className="flex-fill md:hidden"></div>

            <div className="flex flex-col">
                <div className="flex flex-col items-center justify-center my-[24px] h-[64px]">
                <h1 class="my-[14px] text-4xl font-extrabold leading-none tracking-tight md:text-5xl lg:text-6xl text-white">
                  {time ? formatDate((time) / 1000) : '00:00'}
                </h1>
                </div>
                {/* <span className="text-[14px] text-center">Get a structured medical note from your consultation, in seconds</span> */}
            </div>
  

              {
                !loading
                  ? (
                    <>
                <div className="flex justify-center py-[10px]">
                  <div className="relative self-center ">
                      {/* <img src={process.env.PUBLIC_URL + '/flaticons/talk.png'} style={{width: "150px", height: "auto"}}/> */}

                      {
                        loadingRecorder && recording
                        ?  <LoaderSpinner />
                        : isLoadingNote ? (
                          <RecorderAnimationNote className="RecorderAnimationNote">
                            <span className={`is_active_note`}></span>
                            <span className={`is_active_note`}></span>
                            <span className={`is_active_note`}></span>
                            <span className={`is_active_note`}></span>
                            <span className={`is_active_note`}></span>
                          </RecorderAnimationNote>
                        ) : (
                          <RecorderAnimationRecording className="RecorderAnimationRecording">
                          <span className={recording ? `is_active` : `is_inactive`}></span>
                          <span className={recording ? `is_active` : `is_inactive`}></span>
                          <span className={recording ? `is_active` : `is_inactive`}></span>
                          <span className={recording ? `is_active` : `is_inactive`}></span>
                          <span className={recording ? `is_active` : `is_inactive`}></span>
                        </RecorderAnimationRecording>
                        )
                      }

                    </div>
                  </div>
        
                  <div className="flex flex-col items-center justify-center">
                    <span className={`transition-all duration-300 ease-in-out mb-[6x] py-10 text-lg font-normal text-gray-100 text-md lg:text-xl sm:px-[16px] xl:px-[48px] dark:text-gray-100 text-white text-center ${(!loadingRecorder && recording || isLoadingNote) ? 'opacity-1' : 'opacity-0'}`}>
                      {!isLoadingNote ? 'Notatio is listening to your encounter...' : 'Hold tight, Notatio is generating a note...'}
                    </span>
                  </div>
                    </>
                  ) : (
                  <div className="flex flex-col">
                    <div className="flex flex-col items-center justify-center mb-[24px] h-[64px]">
                        <div role="status">
                            <svg aria-hidden="true" className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                            </svg>
                            <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    </div>
                  )
              }

{/*   
        <div className="flex flex-col">
          <div className="flex flex-col items-center justify-center mb-[24px] h-[64px]">
            <h1 className="text-[24px] text-center font-medium">Notatio is Listening!</h1>
          </div>
          <span className="text-[14px] text-center">Resume your encoutner</span>
        </div> */}
  
        <div className="flex-fill"></div>
  
        {/* ActionButton */}


      {/* ActionButton */}
      <div className="flex">
        <div className="flex flex-col flex-grow">
          <ButtonContainer className={`bg-white !hover:bg-gray-500 ${isPWA ? 'mb-[32px]' : ''}`}>
  
              {
                isLoadingNote && (
                <ButtonTextContainer style={buttonTextContainerExtraStyles} className="!bg-white !hover:bg-gray-500 !hover:bg-inherit">
                  <div className="flex justify-center items-center space-x-[4px] text-black">
                    <div role="status" className="mr-[4px]">
                      <svg aria-hidden="true" className="w-[18px] h-[18px] text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                      </svg>
                      <span className="sr-only">Loading...</span>
                    </div>
                    <div>Generating your note...</div>
                  </div>
                </ButtonTextContainer>
                )
              }
              {
                !isLoadingNote && !isNewNote && recording && (
                <ButtonTextContainer style={buttonTextContainerExtraStyles} className="!bg-white !hover:bg-gray-500 !hover:bg-inherit" onClick={onEndRecording}>
                  <div className="flex justify-center items-center space-x-[4px] text-black">
                    <CheckMarkSvgIcon />
                    <div>End consultation</div>
                  </div>
                </ButtonTextContainer>
                )
              }
              {
                !isLoadingNote && !isNewNote && !recording && (!hasNote || !location.pathname.includes('note')) && (
                <ButtonTextContainer style={buttonTextContainerExtraStyles} onClick={onResumeRecording}>
                  <div className="flex justify-center items-center space-x-[4px] text-black">
                  {
                    loadingRecorder
                    ? (<><MicrophoneSvgIcon /><div>Resume Consultation</div></>)
                    : (
                      <div className="flex justify-center items-center space-x-[4px] text-black">
                        <div role="status" className="mr-[4px]">
                          <svg aria-hidden="true" className="w-[18px] h-[18px] text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                          </svg>
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    )
                  }
                  </div>
                </ButtonTextContainer>
                )
              }
              {
                !isLoadingNote && !isNewNote && !recording && hasNote && location.pathname.includes('note') && (
                <ButtonTextContainer style={buttonTextContainerExtraStyles} onClick={onClickCopyNote}>
                  <div className="flex justify-center items-center space-x-[4px] text-black">
                    {/* <CopySvgIcon /> */}
                    {displayCheck ? <CheckMarkSvgIcon className="text-green-500" /> : <CopySvgIcon /> }
                    <div>Copy note</div>
                  </div>
                </ButtonTextContainer>
                )
              }
              {
                !isLoadingNote && isNewNote && (
                <ButtonTextContainer style={buttonTextContainerExtraStyles} onClick={onStartRecording}>
                  <div className="flex justify-center items-center space-x-[4px] text-white">
                      {
                        loadingRecorder
                        ? (<><MicrophoneSvgIcon /><div>Start Consultation</div></>)
                        : (
                          <div className="flex justify-center items-center space-x-[4px] text-white">
                            <div role="status" className="mr-[4px]">
                              <svg aria-hidden="true" className="w-[18px] h-[18px] text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                              </svg>
                              <span className="sr-only">Loading...</span>
                            </div>
                          </div>
                        )
                      }
                  </div>
                </ButtonTextContainer>
                )
              }

  
            { !isNewNote && !isMinimized && !isLoadingNote && (
              <>
              <ButtonSeparator />
              <ButtonChevronContainer className="!bg-white !hover:bg-gray-500" onClick={setIsMainButtonOpen.bind(null, !isMainButtonOpen)}>
                <ChevronSvgIcon className={`text-black transform ${!isMainButtonOpen ? "rotate-180" : ""}`} />
              </ButtonChevronContainer>
              {isMainButtonOpen && (
                <div className={`absolute ${isPWA ? 'bottom-[103px]' : 'bottom-[71px]'} right-[16px] md:bottom-[68px] md:right-[18px] z-10 mt-2 origin-top-right rounded-[8px] bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`} role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1">
                  <div className="py-[5px] rounded bg-white">
                    { !isNewNote && !recording && hasTranscript && (!hasNote || !location.pathname.includes('note')) && (<>
                      {/* <ButtonContainer onClick={onClickCopyTranscript}
                        className="text-white block px-4 py-2 text-sm !rounded-none !border-none hover:text-gray-300 cursor-pointer">
                        <div className="flex justify-center items-center space-x-[4px] text-white">
                          {displayCheck ? <CheckMarkSvgIcon className="text-green-500" /> : <CopySvgIcon /> }
                          <div>Copy transcript</div>
                        </div>
                      </ButtonContainer> */}
                      <ButtonContainer className="bg-white" onClick={onDiscardConsultation}
                        className="text-white block px-4 py-2 text-sm !rounded-none !border-none hover:text-gray-300 cursor-pointer">
                        <div className="flex justify-center items-center space-x-[4px] text-black">
                          <TrashSvgIcon className="text-black"/>
                          <div>Discard consultation</div>
                        </div>
                      </ButtonContainer>
                    </>)}
                    { !isNewNote && !recording && hasTranscript && hasNote && location.pathname.includes('note') && (<>
                      <ButtonContainer onClick={onClickRegenerateNote}
                        className="text-white block px-4 py-2 text-sm !rounded-none !border-none hover:text-gray-300 cursor-pointer">
                        <div className="flex justify-center items-center space-x-[4px] text-white">
                          <RedoSvgIcon className="text-white"/>
                          <div>Regenerate note</div>
                        </div>
                      </ButtonContainer>
                    </>)}
                    { !isNewNote && recording && (
                      <ButtonContainer  onClick={() => {onPauseRecording(); setIsMainButtonOpen(false)}} className="text-white block px-4 py-2 text-sm !rounded-none !border-none hover:text-gray-300 cursor-pointer !bg-white !hover:bg-gray-500" style={{background: 'inherit'}}>
                        <div className="flex justify-center items-center space-x-[4px] text-black">
                          <PauseSvgIcon className="text-black"/>
                          <div>Pause consultation</div>
                        </div>
                      </ButtonContainer>
                    )}
                    { false && (
                      <ButtonContainer onClick={() => console.log("CRegenerate Note clicked")}
                        className="text-white block px-4 py-2 text-sm !rounded-none !border-none hover:text-gray-300">
                        <div className="flex justify-center items-center space-x-[4px] text-white">
                          <RedoSvgIcon className="text-white"/>
                          <div>Regenerate Note</div>
                        </div>
                      </ButtonContainer>
                    )}
                  </div>
                </div>
              )}
              </> 
            )}
          </ButtonContainer>
        </div>
      </div>
      {/* ./End ActionButton */}
      
      { process.env.REACT_APP_SHOW_DEBUG === "true" && (
        <div className="flex w-full flex-col  overflow-scroll h-[500px] bg-black">
        {
            transcript.map(transcriptSession => {
              return (<div>
                {
                  transcriptSession.items.map((item, i) => (
                    <div className="flex flex-col text-[12px] font-medium">
                      <div className="flex items-center select-none text-mint">
                        <div className="text-gray-600">{formatDate(item["start_time"] / 1000.0)}</div>
                        {item["speaker"] && (
                          <>
                            <span className="mx-[4px] text-white">•</span>
                            <div className={item["speaker"] == "Patient" ? 'text-pink-600' : 'text-blue-600'}>{item["speaker"]}</div>
                          </>
                        )}
                      </div>
                      <div className="mt-[2px] cursor-text relative">
                        <div className={`selection:text-black inline-block selection:bg-primary ${(item["is_final"] == true || item["prev_is_final"] == true) ? 'text-white' :'text-gray-300'}`}>
                          <span>{item["text"]}</span>
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>)
            })
              
          }
          </div>
      )}
  
                </div>
            {/* </div> */}
          </div>


    </div>
    );
  }