import axios from "axios";
import { createContext, useContext, useMemo , useEffect, useState} from "react";
import { useEffectAsync } from '@chengsokdara/react-hooks-async'
import { v4 as uuidv4 } from 'uuid';

import { useStorageHook } from "./useLocalStorage";
import { AuthService } from './useAuth';
import { BASE_URL } from "../constants";

export const DEFAULT_NOTE_GENERATION_MODE = "highest_quality"

function getMonthYear () {
  let dateObj = new Date();
  let month = dateObj.getUTCMonth() + 1; //months from 1-12
  let day = dateObj.getUTCDate();

  return month + "/" + day;
}

// [
//   {
//       "audioFeedbackEncryptionKey": null,
//       "completedNoteGenerations": [

// ],
//       "createdAt": 1701290411000,
//       "freeText": "",
//       "isImportedViaAirdrop": false,
//       "pendingNoteGeneration": null,
//       "rating": null,
//       "title": null,
//       "transcript": {
//           "sessions": [
//               {
//                   "endedAt": 1701291155102,
//                   "hadUnstableNetwork": false,
//                   "items": [
//                       {
//                           "endOffsetMs": 80533,
//                           "isFinal": false,
//                           "speaker": "UNSPECIFIED",
//                           "startOffsetMs": 78083,
//                           "text": "Staying 1234",
//                           "uuid": "b734893c-94ae-4208-99de-e66657118496"
//                       }
//                   ],
//                   "locale": "ENGLISH_US",
//                   "model": "AUTO",
//                   "startedAt": 1701291074658,
//                   "uuid": "4551867f-a732-47a6-9731-7f44d6a0b7b1"
//               },
//               {
//                   "endedAt": 1701291193334,
//                   "hadUnstableNetwork": false,
//                   "items": [
//                       {
//                           "endOffsetMs": 19992,
//                           "isFinal": true,
//                           "speaker": "UNSPECIFIED",
//                           "startOffsetMs": 17632,
//                           "text": "Testing 1234.",
//                           "uuid": "ba80c930-a3c6-44d8-bfcf-ccc1e2eb385e"
//                       }
//                   ],
//                   "locale": "ENGLISH_US",
//                   "model": "AUTO",
//                   "startedAt": 1701291170272,
//                   "uuid": "f69d3d1a-610e-4f3c-9abf-2a5b8cf18d0d"
//               },
//               {
//                   "endedAt": 1701291319574,
//                   "hadUnstableNetwork": false,
//                   "items": [
//                       {
//                           "endOffsetMs": 10582,
//                           "isFinal": true,
//                           "speaker": "UNSPECIFIED",
//                           "startOffsetMs": 7342,
//                           "text": "Testing 12345.",
//                           "uuid": "1ec8e5f4-faf2-4bcb-883e-a92895a0d469"
//                       }
//                   ],
//                   "locale": "ENGLISH_US",
//                   "model": "AUTO",
//                   "startedAt": 1701291303233,
//                   "uuid": "f912798d-9eb9-4bed-ad19-0135687c2132"
//               }
//           ]
//       },
//       "transcriptQuotes": [],
//       "type": "IN_PERSON",
//       "updatedAt": 1701297460211,
//       "uuid": "a1f1edc4-9c9a-4808-a1fc-2a77d252614a"
//   }
// ]

const defaultNoteObject = {
  uuid: null,
  type: null,
  transcriptQuotes: null,
  updatedAt: null,
  audioFeedbackEncryptionKey: null,
  completedNoteGenerations: null,
  createdAt: null,
  freeText: null,
  isImportedViaAirdrop: null,
  pendingNoteGeneration: null,
  rating: null,
  title: null,
  transcript: {
    sessions: []
  }
}

// {
//                   "endedAt": 1701291155102,
//                   "hadUnstableNetwork": false,
//                   "items": [
//                       {
//                           "endOffsetMs": 80533,
//                           "isFinal": false,
//                           "speaker": "UNSPECIFIED",
//                           "startOffsetMs": 78083,
//                           "text": "Staying 1234",
//                           "uuid": "b734893c-94ae-4208-99de-e66657118496"
//                       }
//                   ],
//                   "locale": "ENGLISH_US",
//                   "model": "AUTO",
//                   "startedAt": 1701291074658,
//                   "uuid": "4551867f-a732-47a6-9731-7f44d6a0b7b1"
//               },
const defaultTranscriptObject = {
  uuid: null,
  endedAt: null,
  hadUnstableNetwork: null,
  items: null,
  locale: null,
  model: null,
  startedAt: null,
}

const defaultNoteParams = {
  local: "ENGLISH_UK",
  template: "GENERAL_MEDICINE",
  transcriptItemUuids: [],
  trigger: "MANUAL",
  type: "FULL"
}

const defaultGeneratedNote = {
  completedAt: null,
  note: {
      initialSections: [],
      parameters: defaultNoteParams,
      processedTranscriptItemUuids: [],
      sections: []
  },
  status: "SUCCESS",
};

const DigestContext = createContext();

export const NoteDigestProvider = ({children}) => {
  const [parameters, setParameters] = useState(defaultNoteParams)
  const [loading, setLoading] = useState(false)
  const [generationError, setGenerationError] = useState(false)

  const generateNoteIncremental = async (encounterId, transcriptItems, pastNote) => {
    console.log("generateNoteIncremental(encounterId, transcriptItems, pastNote): ", encounterId, transcriptItems, pastNote)
  }

  // TODO(oandrienko): SET THE TEMPLATE AS AN ADDITIONAL PARAMETER!!!!!!!!
  const generateNote = async (encounterId, transcriptItems, note_generation_mode=undefined, noteTemplate="general_medicine", customInstructions="",
    // model="gpt-4"  // 56s
    // model="gpt-4-turbo" // 56s
    // model="gpt-3.5-turbo" // 56s
    model="gpt-3.5-turbo-azure" // 56s
    // model="gpt-4-azure"  // 56s
  ) => {

    if (note_generation_mode !== undefined) {
      if (note_generation_mode == "highest_quality") {
        model = "gpt-4-azure"
      } else if (note_generation_mode == "balanced") {
        model = "gpt-4-turbo"
      } else if (note_generation_mode == "fastest") {
        model = "gpt-3.5-turbo-azure"
      }
    }

    // FIXME(oandrienko): For now let's not have this.
    model = "gpt-4-azure"

    console.log("generateNote(encounterId, transcriptItems, noteTemplate): ", encounterId, transcriptItems, noteTemplate, model)
    console.log("CUSTOM INSTRUCTIONS: ", customInstructions)
    setLoading(true)
    let errorToReport = null,
      result = null;

    try {
      result = await AuthService.authenticatedApi.post(
        BASE_URL + 'api/digest',
        { transcript_items: transcriptItems,  note_template: noteTemplate, model, encounter_id: encounterId, custom_instructions: customInstructions},
        {
          timeout:  (1000 * 60 * 4)  // 4 minutes
        })
    } catch (error) {
      console.log("error: ", error)
      if (error.code !== "ERR_BAD_REQUEST") {
        const errorMsg = "Something went wrong. Try again later."
        setGenerationError(errorMsg)
        errorToReport = errorMsg
      } else {
        setGenerationError(error.response.data.error)
        errorToReport = error.response.data.error
      }
      setLoading(false)
    }
    setLoading(false)

    if (errorToReport !== null) {
      return undefined;
    }

    const generatedNote = result.data.note;
    const processedTranscriptItemUuids = transcriptItems.map(item => item.id)
    const noteObject = {
      ...defaultGeneratedNote,
      ...{
        completedAt: new Date().getTime(),
        note: {
          sections: generatedNote,
          initialSections: generatedNote,
          processedTranscriptItemUuids,
        }
      }
    }

    return noteObject
  }

  const value = {
    loading,
    generateNote,
    generateNoteIncremental,
    generationError,
    setGenerationError,
  }

  return <DigestContext.Provider value={value}>{children}</DigestContext.Provider>;
};


export const useNoteDigest = () => {
  return useContext(DigestContext);
};
