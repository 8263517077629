import React, {useState, useEffect, useRef, createRef} from 'react';
import {
  Link,
  useNavigate,
} from "react-router-dom";
import styled, {keyframes} from "styled-components";
import { useForm  } from "react-hook-form";

import {
  NablaLogoSvg,
  PlusIconSvg,
  HistoryIconSvg,
  MailIconSvg,
  SettingsIconSvg,
  MinimizeIconSvg,
  ExpandIconSvg,
  CloseIconSvg,
} from '../svgs'

import { ChromeDraggableElement, useMinimizedStateContext } from '../../App';


const AddNoteButton = styled.button`
  background: rgba(122,122,124,.53);
  box-shadow: 0 2px 4px -2px #0000001f;
  border: 0.5px solid white;
  padding: 2.5px 4px;

  border-radius: 8px;
  fontSize: 16px;
  fontWeight: 500;

  font-family: inherit;
  font-size: 100%
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;

  &:hover {
    background: linear-gradient(0deg,#474748,#474748) padding-box, linear-gradient(180deg,#b8b8b8 -76.62%, rgba(184,184,184,0) 131.05%) border-box;
  }
`;


function NewNoteButton ({onClick, showNew=true}) {
    return (
      <AddNoteButton className="!ml-[10px]" onClick={onClick}>
        <div className=" flex justify-center flex-fill items-center space-x-[4px] text-[12px]">
          <PlusIconSvg className="" />
          {showNew && <div className="pr-[8px]">New</div>}
        </div>
      </AddNoteButton>
    );
  }
  

  function minimizeExtension(isMinimized) {
    console.log("onMinimizeExtension calling NEW_MINIMIZED_STATE")
    if (chrome.runtime) {
      chrome.runtime.sendMessage({
          type: "NEW_MINIMIZED_STATE",
          isMinimized: isMinimized,
      });
    }
  }
  
  
  export function MainWidgetHeader({onNewButtonClick, disabledButtons}) {

    const {isMinimized, toggle} = useMinimizedStateContext()
    const navigate = useNavigate(); // <-- access navigate function
  
    // const [isExpanded, setIsExpanded] = useState(true);
    const isExpanded = !isMinimized;

    const onMinimizeExtension = () => {
      console.log("onMinimizeExtension called: isMinimized =", isMinimized)
      if (!isMinimized) {
        setTimeout(minimizeExtension.bind(null, !isMinimized), 200); // !Minimized b/c we will call toggle after.
        navigate("/dashboard/current/transcribe")
        toggle();
      } else {
        minimizeExtension(!isMinimized) // !Minimized b/c we will call toggle after.
        setTimeout(toggle, 10); // !Minimized b/c we will call toggle after.
      }
    }
  
    const onCloseExtension = () => {
      console.log("onCloseExtension called")
      if (chrome.runtime) {
        chrome.runtime.sendMessage({
            type: "CLOSE_EXTENSION"
        });
      }
    }
  
    return (<>
    
      {/* Header */}
      <div className="flex items-center w-full px-[8px] pb-[8px]">
      {/* Logo */}
      <div className="flex items-center space-x-[12px] mr-auto">
        {/* <div className="font-extrabold text-[15px]">NotatioMD</div> */}
        {/* <NablaLogoSvg className="text-white hidden" /> */}
        {/* <img src={process.env.PUBLIC_URL + "/notatio_logo_full.png"} /> */}
        <img src={process.env.PUBLIC_URL + "/notatio-text_logo trans.png"} style={{height: "20px", width: "auto", maxWidth: "inherit"}} />
        <NewNoteButton className={`${disabledButtons ? 'pointer-none' : ''}`} disabled={disabledButtons} onClick={onNewButtonClick} showNew={isExpanded}/>
      </div>
      {/* ./End Logo */}
  
      {/* MovableTopBar */}
      <ChromeDraggableElement className="cursor-move w-full h-full" />
      {/* ./End MovableTopBar */}
  
      {/* HeaderButtonGroup */}
      <div className="flex items-center space-x-[4px]">
  
        {/* LargerScreenButtonGroup */}
        { !isMinimized && (
          <div className="flex items-center space-x-[4px]" style={{animationDuration: "200ms"}}>
            <Link to="/dashboard/history">
              <HistoryIconSvg className="hover:text-gray-300" />
            </Link>
            <Link to="/dashboard/contact-us">
              <MailIconSvg className="hover:text-gray-300" />
            </Link>
            <Link to="/dashboard/settings/general">
              <SettingsIconSvg className="hover:text-gray-300" />
            </Link>
          </div>
        )}
        {/* ./End LargerScreenButtonGroup */}
  
        {/* MinimizeButton */}
        <button type="button" onClick={() => {onMinimizeExtension();}}>
          {isExpanded ? <MinimizeIconSvg className="hover:text-gray-300"/> : <ExpandIconSvg className="hover:text-gray-300"/> }
        </button>
        {/* MinimizeButton */}
  
        {/* CloseButton */}
        <button type="button" onClick={onCloseExtension} >
          <CloseIconSvg className="hover:text-gray-300"/>
        </button>
        {/* CloseButton */}
  
      </div>
      {/* ./End HeaderButtonGroup */}
  
    </div>
    {/* ./End Header */}
  
    </>);
  }
  
  const NewConsultButton = styled.button`
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(33 42 47 / var(--tw-border-opacity));
  background-color: transparent;
  --tw-text-opacity: 1;
  color: rgb(33 42 47 / var(--tw-text-opacity));

  --tw-border-opacity: 1;
    border-color: rgb(220 224 233 / var(--tw-border-opacity));
    --tw-text-opacity: 1;
    color: rgb(220 224 233 / var(--tw-text-opacity));

    position: relative;
    display: flex;
    border-radius: 8px;
    padding: 10px;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));

    --tw-border-opacity: 1;
    border-color: rgb(220 224 233 / var(--tw-border-opacity));
    --tw-text-opacity: 1;
    color: rgb(220 224 233 / var(--tw-text-opacity));

    background-color: transparent;
    --tw-text-opacity: 1;
    color: rgb(33 42 47 / var(--tw-text-opacity));
`;

const NewConsultButtonWrapper = styled.div`
display: flex;
align-items: center;
gap: 4px;
flex: 1 1 auto;
min-width: 0;
min-height: 0;
justify-content: center;
`;

const NewConsultButtonContent = styled.span`
transition-property: opacity;
transition-timing-function: cubic-bezier(.4,0,.2,1);
transition-duration: .2s;
display: flex;
align-items: center;
justify-content: center;
`;


export function EditableTitleForm({onUpdateTitleClick, className, title}) {

  const {
    control,
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    getValues,
    formState: {errors, isValid}
  } = useForm({mode: "onChange"})

  const onSubmit = async (data) => {
    onUpdateTitleClick(data.title)
  }

  useEffect(() => {
    reset()
    const subscription = watch(handleSubmit(onSubmit));
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch]);

   // effect runs when user state is updated
   useEffect(() => {
    console.log("EditbaleTitleForm: ", getValues(), title)
    const formValues = getValues()
    const currentTitle = formValues && formValues["title"];
    console.log("EditbaleTitleForm: curr=", currentTitle, ", title=", title)
    if (currentTitle && currentTitle !== title) {
      console.log("SHOULD RESET EditbaleTitleForm...")
      reset({"title": title});
      // setValue("title", title)
    }
  }, [title]);

  return (<>
    <form onSubmit={handleSubmit(onSubmit)}>
      <input
            className={className}
            name="title"
            autoComplete="off"
            // className="truncate"
            defaultValue={title}
            // value={title}
            {...register(
              "title",
              {
                // required: true,
                // minLength: 0,
              }
            )}
          />
    </form>
  </>)
}


export function DesktopTranscriptHeader({title, onNewButtonClick, onUpdateTitleClick, disabledButtons=false}) {
  return (
    <div className="flex items-center border-gray-200 gap-[8px] _header_zurxb_2 pl-[6px] pr-[16px] py-[8px]">
      <div className="text-[24px] font-semibold leading-[32px] overflow-hidden">
        <div className="font-semibold flex items-center overflow-hidden text-[24px]">
          <EditableTitleForm onUpdateTitleClick={onUpdateTitleClick} title={title} className="flex group items-center hover:bg-gray-100 focus:bg-gray-100 px-[10px] rounded pt-[8px] pb-[7.5px] mobile:pt-8 mobile:pb-[7.5px] w-full justify-center truncate outline-none border-none focus:ring-0 or focus:ring-transparent">
            {/* <span className="truncate">{title}</span> */}
          </EditableTitleForm>
        </div>
      </div>
      <div className="flex-fill"></div>
      <NewConsultButton disabled={disabledButtons} onClick={(e) => onNewButtonClick(e)} className={`block md:!hidden _button_1oj7c_1 p-[6px] text-gray-300 _default_1oj7c_52 _medium_1oj7c_30 _secondary_1oj7c_67 _icon-button_1oj7c_38 _align-center_1oj7c_18 ${disabledButtons ? 'pointer-none': ''}`}>
        <div className="_content-wrapper_1oj7c_7">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" data-icon="add"><path d="M12 5.75v12.5M5.75 12h12.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
        </div>
      </NewConsultButton>
      <div className="hidden md:block">
        <NewConsultButton disabled={disabledButtons}  onClick={(e) => onNewButtonClick(e)} className={`hidden md:block_button_1oj7c_1  _default_1oj7c_52 _large_1oj7c_34 _secondary_1oj7c_67 _align-center_1oj7c_18 ${disabledButtons ? 'pointer-none': ''}`}>
          <NewConsultButtonWrapper className="_content-wrapper_1oj7c_7">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" data-icon="add"><path d="M12 5.75v12.5M5.75 12h12.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
            <NewConsultButtonContent className="_content_1oj7c_2">New consultation</NewConsultButtonContent>
          </NewConsultButtonWrapper>
        </NewConsultButton>
      </div>
    </div>
  )
}
