import React, {useState, useEffect } from 'react';
import {
Link,
Outlet,
useLocation,
useNavigate,
} from "react-router-dom";
import styled, {keyframes} from "styled-components";

import { MainWidgetHeader, DesktopTranscriptHeader } from '../../components/layouts'
import { MainTranscribeAndNotesScreen } from "./TranscribePage"

import { useNoteDigest } from '../../hooks/useNoteDigest';
import { useTranscriptionContext, useTranscription } from '../../hooks/useTranscription';
import { useAuth } from "../../hooks/useAuth";
import { useEnumerateDevicesContext } from "../../hooks/useEnumerateDevices"
import { useEncounters } from "../../hooks/useEncounters";
import { useStorageHook } from "../../hooks/useLocalStorage";

//////////
import { 
  ButtonTextContainer,
  ButtonContainer } from '../../components/forms';

import {

  MicrophoneSvgIcon,
  ChevronSvgIcon,
  CopySvgIcon,

  CheckMarkSvgIcon,
  PauseSvgIcon
} from '../../components/svgs'
import { TranscriptView } from './LiveTranscriptScreen';


export const ButtonChevronContainer = styled.button`
  align-self: stretch;
  padding: 4px 14px;

  &:hover {
    background: linear-gradient(0deg,#000000 0%,#000000 100%) padding-box,linear-gradient(180deg,#6e6e6e,#2f2f2f) border-box;
  }
`;

export const ButtonSeparator = styled.div`
  width: 1px;
  align-self: stretch;
  background-image: linear-gradient(180deg,#b8b8b8 -76.62%,rgba(184,184,184,0) 131.05%);
`;

export const CustomScrollContainer = styled.div`
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  overscroll-behavior-y: contain;
  padding: 8px;
`;


const blinkAnimation = keyframes`
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
`
const RecorderDot = styled.div`
 animation-name: ${blinkAnimation};
 animation-duration: 2s;
 animation-iteration-count: infinite;
`


  
  
//   export function MainTranscribeAndNotesScreen({shouldShowNoteTab, isNewNote, isLoading, recording, onEndRecording, onResumeRecording, onStartRecording, onPauseRecording}) {
  
//     return (<>
    
//         {/* MainContent */}
//         <div className="flex flex-col w-full h-full px-[8px] pb-[8px] flex-fill space-y-[8px] justify-end">
  
//             {!isNewNote && <MainTranscribeAndNotesNav
//                 recording={recording}
//                 shouldShowNoteTab={shouldShowNoteTab}
//             />}
            
//             <Outlet />
  
//         </div>
//         {/* ./End MainContent */}
  
//     </>)
//   }
  


export function NoteLayout({withHeader = true, initialEncounterUuid = null}) {

  const { user, updateUser } = useAuth();

  const { currentDevice } = useEnumerateDevicesContext()
  const navigate = useNavigate(); // <-- access navigate function
  const [displayCheck, setDisplayCheck] = useState(false)
  const [customInstructions, setCustomInstructions, loadingCustomInstructions] = useStorageHook("customInstructions", null);

  const {
    recording,
    // connected,
    connected: loadingRecorder,
    transcript,
    deviceId,
    duration,
    pauseRecording,
    startRecording,
    stopRecording,
    setDeviceId,
    clearTranscriptBuffer,
    setEncounterId,
  } = useTranscription()

  const {
    loading,
    generateNote,
    generationError,
    setGenerationError,
  } = useNoteDigest()

  const {
    currentEncounter,
    encounters,
    addNoteGeneration,
    addTranscriptSession,
    saveEncounter,
    updateEncounterFields,
    startNewEncounter,
    updateEncounterUUID,
    updateCurrentTranscriptItems,
    updateCurrentTranscriptDuration,
    deleteEncounter
  } = useEncounters({initialEncounterUuid});

  const currentNote = (
    currentEncounter &&
    currentEncounter.completedNoteGenerations[currentEncounter.completedNoteGenerations.length - 1])

  useEffect(()=>{

    const currentUUID = initialEncounterUuid || (encounters.length > 0 && encounters[encounters.length - 1].uuid)
    console.log("NEW):CurrentUUID: ", currentUUID)
    if (currentUUID) {
      updateEncounterUUID(currentUUID)
      console.log("!!!!!!!!!!!!!!!!!!!!!!!!!Setting current encounter: ", currentUUID)
      setEncounterId(currentUUID)
    }
  }, [currentEncounter, encounters]);

  useEffect(()=>{
    if (recording && transcript.length > 0) {
      updateCurrentTranscriptItems(transcript)
    }
  }, [transcript]);

  useEffect(()=>{
    if (recording) {
      updateCurrentTranscriptDuration(duration)
    }
  }, [duration]);

  useEffect(()=>{
    if (currentDevice) {
      console.log('Called setDeviceId for Transcription hook', currentDevice);
      setDeviceId(currentDevice.deviceId)
   }
  }, [currentDevice]);


  useEffect(()=>{
    console.log("NOTELAYOUT: mouted...")
    return () => {
      console.log("NOTELAYOUT: unmouted...")
    }
  }, []);


  const onStartRecording = async () => {
    console.log("onStartRecording...")

    startRecording()
    setGenerationError(null)
    // NEW
    addTranscriptSession();
    clearTranscriptBuffer();

    navigate('./encounter')
  };

  const onEndRecording = async () => {
    console.log("onEndRecording: ")
    try {
      stopRecording()
    } catch {
      console.warn("Could not stop recording...")
    }
    // type INCREMENTAL? FULL
    // encounterUuid: "d94b5efc-3c6e-4df6-9c4a-931c6df75b59"
    // previousNote {,…} sections
    // [{key: "CHIEF_COMPLAINT", title: "Chief complaint", content: "Severe acne on forehead and back"},…]
    //
    // transcript
    // : 
    // {transcriptItems: [{uuid: "87fa1c01-8cf1-47c6-88d3-fe130a1b1ced", speaker: "UNSPECIFIED",…},…]}
    // generateFullNote(encounterId, transcript) // user template used in backend
    // generateIncrementalNote(encounterId, transcript, previousNote) // user template used in backend
    const transcriptItems = []
    currentEncounter.transcript.sessions.forEach(session => session.items.forEach(item => {
      transcriptItems.push(item);
    }))
    const noteObject = await generateNote(currentEncounter.id, transcriptItems, user.note_generation_mode, user.note_template, customInstructions);
    console.log("NOTE OBJECT: ", noteObject)
    if (noteObject === undefined || noteObject == null) {
      console.log("addNoteGeneration failed....")
    } else {
      console.log("addNoteGeneration ->  ", noteObject)
      addNoteGeneration(noteObject);
      navigate('./note')
      updateUser({num_notes_generated: user.num_notes_generated + 1})
    }
  };

  const onResumeRecording = () => {
    console.log("onResumeRecording: ")
    startRecording()
    setGenerationError(null)
    // NEW
    addTranscriptSession();
    clearTranscriptBuffer();

    navigate('./encounter')
  };

  const onPauseRecording = () => {
    console.log("onPauseRecording: ")
    pauseRecording()
  };

  const onNewButtonClick = async () => {
    console.log("onNewButtonClick: ");
    await stopRecording()
    setGenerationError(null)
    navigate("/dashboard/current/note")
    // NEW NOTE
    startNewEncounter();
  }

  /**
   * Copy the Transcript.
   */
  const onClickCopyTranscript = () => {
    let textToCopy = "";

    const formatDate = (secs) => {
      var date = new Date(0);
      date.setSeconds(secs); // specify value for SECONDS here
      var timeString = date.toISOString().substring(14, 19);
      return timeString
    }

    const transcriptItems = []
    currentEncounter.transcript.sessions.forEach(session => session.items.forEach(item => {
      transcriptItems.push(item);
    }))

    transcriptItems.forEach(item => {
      textToCopy += `${formatDate(item.start_time)}\n`
      textToCopy += `${item.text }\n`
    })

    console.log("copied Text, ", textToCopy)
    navigator.clipboard.writeText(textToCopy)
    onDisplayCheck()
  }

  /**
   * Copy the Note.
   */
  const onClickCopyNote = () => {
    let textToCopy = "";

    console.log("currentNote: ", currentNote)

    currentNote.note.sections.forEach(section => {
      textToCopy += `${section.title.toUpperCase()}:\n`
      textToCopy += `${section.text}\n\n`
    })

    console.log("copied Text, ", textToCopy)
    navigator.clipboard.writeText(textToCopy)
    onDisplayCheck()
  }

  const onDisplayCheck = () => {
    setDisplayCheck(true)
    setTimeout(() => setDisplayCheck(false), 3000)
  }

  const onUpdateTitleClick = (title) => {
    updateEncounterFields({ title })
  }

  const onClickRegenerateNote = async () => {
    console.log("onClickRegenerateNote!")
    const transcriptItems = []
    currentEncounter.transcript.sessions.forEach(session => session.items.forEach(item => {
      transcriptItems.push(item);
    }))
    const noteObject = await generateNote(currentEncounter.uuid, transcriptItems, user.note_generation_mode, user.note_template, customInstructions);
    console.log("NOTE OBJECT: ", noteObject)
    if (noteObject === undefined || noteObject == null) {
      console.log("addNoteGeneration failed....")
    } else {
      console.log("addNoteGeneration ->  ", noteObject)
      addNoteGeneration(noteObject);
      navigate('./note')
    }
  }

  const onDiscardConsultation = async () => {
    const currentEncounterID = currentEncounter.uuid
    console.log("onDiscardConsultation clicked -> ", currentEncounter)
    await stopRecording()
    setGenerationError(null)
    // NEW NOTE
    // const currentEncounterID = currentEncounter.id
    // DELETE OLD
    console.log("deleting old encounter... deleteEncounter(", currentEncounterID, ")")
    await deleteEncounter(currentEncounterID)
    navigate("./note")
    console.log("done deleting (", currentEncounterID, ")")
    startNewEncounter();
  }


  const isNewNote = !currentNote && !recording && currentEncounter && (currentEncounter.transcript.sessions.length == 0)

  return (<>
      {
        withHeader && (process.env.REACT_APP_BUILD_PLATFORM === "chrome"
          ? <MainWidgetHeader disabledButtons={isNewNote}  recording={recording} onNewButtonClick={onNewButtonClick} />
          : <DesktopTranscriptHeader onUpdateTitleClick={onUpdateTitleClick} disabledButtons={isNewNote} onNewButtonClick={onNewButtonClick} title={currentEncounter && currentEncounter.title} />)
      }
      <MainTranscribeAndNotesScreen
        recording={recording}
        loadingRecorder={!loadingRecorder}
        isNewNote={isNewNote}
        onStartRecording={onStartRecording}
        onEndRecording={onEndRecording}
        onResumeRecording={onResumeRecording}
        onPauseRecording={onPauseRecording}
        onClickCopyTranscript={onClickCopyTranscript}
        onClickCopyNote={onClickCopyNote}
        onClickRegenerateNote={onClickRegenerateNote}
        onDiscardConsultation={onDiscardConsultation}
        isLoadingNote={loading}// FIXME
        shouldShowNoteTab={recording || currentNote}
        hasTranscript={currentEncounter && currentEncounter.transcript.sessions.length > 0}
        hasNote={currentNote}
        error={generationError}
        displayCheck={displayCheck}
        duration={duration}
      />
  </>)
}
