import Select, {
    components,
  } from "react-select";
//   import { ChevronDownIcon } from "@/assets/icons";
import { ChevronRightIcon, XMarkIcon as CloseIcon } from '@heroicons/react/24/solid'
import { Controller } from 'react-hook-form';

// https://www.jussivirtanen.fi/writing/styling-react-select-with-tailwind
// https://heroicons.com/

function toVal(mix) {
	var k, y, str='';

	if (typeof mix === 'string' || typeof mix === 'number') {
		str += mix;
	} else if (typeof mix === 'object') {
		if (Array.isArray(mix)) {
			for (k=0; k < mix.length; k++) {
				if (mix[k]) {
					if (y = toVal(mix[k])) {
						str && (str += ' ');
						str += y;
					}
				}
			}
		} else {
			for (k in mix) {
				if (mix[k]) {
					str && (str += ' ');
					str += k;
				}
			}
		}
	}

	return str;
}

function clsx() {
	var i=0, tmp, x, str='';
	while (i < arguments.length) {
		if (tmp = arguments[i++]) {
			if (x = toVal(tmp)) {
				str && (str += ' ');
				str += x
			}
		}
	}
	return str;
}


const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <ChevronRightIcon className="h-[18px] w-[20px]" />
      </components.DropdownIndicator>
    );
  };
  
  const ClearIndicator = (props) => {
    return (
      <components.ClearIndicator {...props}>
        <CloseIcon className="h-[18px] w-[20px]"  />
      </components.ClearIndicator>
    );
  };
  
  const MultiValueRemove = (props) => {
    return (
      <components.MultiValueRemove {...props}>
        <CloseIcon className="h-[18px] w-[20px]"  />
      </components.MultiValueRemove>
    );
  };

//   const controlStyles = {
//     // base: "border rounded-lg bg-white hover:cursor-pointer",
//     base: "w-full flex flex-shrink-0 select-none p-[8px] border-[0.5px] border-grey-300 rounded text-[14px]",
//     // focus: "border-primary-600 ring-1 ring-primary-500",
//     // nonFocus: "border-gray-300 hover:border-gray-400",
//   };
//   const placeholderStyles = "text-gray-500 pl-1 py-0.5";
//   const selectInputStyles = "pl-1 py-0.5";
// //   const valueContainerStyles = "p-1 gap-1";
//   const valueContainerStyles = "";
//   const singleValueStyles = "leading-7 ml-1";
//   const multiValueStyles =
//     "bg-gray-100 rounded items-center py-0.5 pl-2 pr-1 gap-1.5";
//   const multiValueLabelStyles = "leading-6 py-0.5";
//   const multiValueRemoveStyles =
//     "border border-gray-200 bg-white hover:bg-red-50 hover:text-red-800 text-gray-500 hover:border-red-300 rounded-md";
// //   const indicatorsContainerStyles = "p-1 gap-1";
// const indicatorsContainerStyles = "";
//   const clearIndicatorStyles =
//     "text-gray-500 p-1 rounded-md hover:bg-red-50 hover:text-red-800";
//   const indicatorSeparatorStyles = "bg-gray-300";
//   const dropdownIndicatorStyles =
//     "p-1 hover:bg-gray-100 text-gray-500 rounded-md hover:text-black";
// //   const menuStyles = "p-1 mt-2 border border-gray-200 bg-white rounded-lg";
// const menuStyles = "w-full flex-col max-h-[330px] overflow-hidden border rounded shadow-popover text-14 text-body bg-[#27282B] border-[0.5] border-grey-250"
//   const groupHeadingStyles = "ml-3 mt-2 mb-1 text-gray-500 text-sm";
//   const optionStyles = {
//     base: "hover:cursor-pointer px-3 py-2 rounded",
//     focus: "text-gray-500 active:bg-gray-500",
//     selected: "after:content-['✔'] after:ml-2 after:text-green-500 text-gray-500",
//   };
//   const noOptionsMessageStyles =
//     "text-gray-500 p-2 bg-gray-50 border border-dashed border-gray-200 rounded-sm";

const controlStyles = {
  base: "border rounded-lg bg-white hover:cursor-pointer",
  focus: "border-primary-600 ring-1 ring-primary-500",
  nonFocus: "border-gray-300 hover:border-gray-400",
};
const placeholderStyles = "text-gray-500 pl-1 py-0.5";
const selectInputStyles = "pl-1 py-0.5";
const valueContainerStyles = "p-1 gap-1";
const singleValueStyles = "leading-7 ml-1";
const multiValueStyles =
  "bg-gray-100 rounded items-center py-0.5 pl-2 pr-1 gap-1.5";
const multiValueLabelStyles = "leading-6 py-0.5";
const multiValueRemoveStyles =
  "border border-gray-200 bg-white hover:bg-red-50 hover:text-red-800 text-gray-500 hover:border-red-300 rounded-md";
const indicatorsContainerStyles = "p-1 gap-1";
const clearIndicatorStyles =
  "text-gray-500 p-1 rounded-md hover:bg-red-50 hover:text-red-800";
const indicatorSeparatorStyles = "bg-gray-300";
const dropdownIndicatorStyles =
  "p-1 hover:bg-gray-100 text-gray-500 rounded-md hover:text-black";
const menuStyles = "p-1 mt-2 border border-gray-200 bg-white rounded-lg";
const groupHeadingStyles = "ml-3 mt-2 mb-1 text-gray-500 text-sm";
const optionStyles = {
  base: "hover:cursor-pointer px-3 py-2 rounded",
  focus: "bg-gray-100 active:bg-gray-200",
  selected: "after:content-['✔'] after:ml-2 after:text-green-500 text-gray-500",
};
const noOptionsMessageStyles =
  "text-gray-500 p-2 bg-gray-50 border border-dashed border-gray-200 rounded-sm";



export const ReactSelect = ({name, control, options, defaultValue, isSearchable = false, placeholder=null}) => (
    <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange, value, ref, name } })  => (
            <Select
                inputRef={ref}
                value={options.find(c => c.value === value)}
                onChange={val => {return onChange(val.value)}}
                options={options}
                isSearchable={isSearchable}
                placeholder={placeholder}
                unstyled
                styles={{
                    input: (base) => ({
                        ...base,
                        "input:focus": {
                            boxShadow: "none",
                        },
                    }),
                    // On mobile, the label will truncate automatically, so we want to
                    // override that behaviour.
                    multiValueLabel: (base) => ({
                        ...base,
                        whiteSpace: "normal",
                        overflow: "visible",
                    }),
                    control: (base) => ({
                        ...base,
                        transition: "none",
                    }),
                }}
                components={{ DropdownIndicator, ClearIndicator, MultiValueRemove }}
                classNames={{
                    control: ({ isFocused }) =>
                        clsx(
                            isFocused ? controlStyles.focus : controlStyles.nonFocus,
                            controlStyles.base,
                        ),
                        placeholder: () => placeholderStyles,
                        input: () => selectInputStyles,
                        valueContainer: () => valueContainerStyles,
                        singleValue: () => singleValueStyles,
                        multiValue: () => multiValueStyles,
                        multiValueLabel: () => multiValueLabelStyles,
                        multiValueRemove: () => multiValueRemoveStyles,
                        indicatorsContainer: () => indicatorsContainerStyles,
                        clearIndicator: () => clearIndicatorStyles,
                        indicatorSeparator: () => indicatorSeparatorStyles,
                        dropdownIndicator: () => dropdownIndicatorStyles,
                        menu: () => menuStyles,
                        groupHeading: () => groupHeadingStyles,
                        option: ({ isFocused, isSelected }) =>
                        clsx(
                            isFocused && optionStyles.focus,
                            isSelected && optionStyles.selected,
                            optionStyles.base,
                        ),
                        noOptionsMessage: () => noOptionsMessageStyles,
                    }}
            />
        )}
    />
);

export const SingleSelect = (props) => {
    const { label, options, styles, field, errorMessage } = props;
    return (
        <Select
          defaultValue={options[0]}
          options={options}
          styles={styles}
          instanceId={label}
          id={label}
          {...field}
          aria-label={label}
          aria-labelledby={label}
        />
    );
  };
