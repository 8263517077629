// "Without country select" component.
// import PhoneInput from "react-phone-number-input/react-hook-form-input"

// "With country select" component.
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form"
import { isPossiblePhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'


export function PhoneInput(props) {
    return (
        <PhoneInputWithCountry
            control={props.control}
            rules={{ required: true, validate: isPossiblePhoneNumber}}
            {...props}
        />
    );
}
