import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate} from "react-router-dom";

import {
    BackSvgIcon,
    TrashSvgIcon,
    GotoSvgIcon,
  } from '../../components/svgs'

import {Modal} from '../../components/Modal'

  import { useEncounters } from '../../hooks/useEncounters';
  import { NoteSection } from '../notes/NoteScreen';
  import { CustomScrollContainer } from '../notes/TranscribePage';

import { ChromeDraggableElement } from '../../App';
import { NoteLayout } from '../notes/NoteLayout'
import {EditableTitleForm} from '../../components/layouts'



function PastViewHeader({title, onDelete, onUpdateTitleClick}) {

    if (process.env.REACT_APP_BUILD_PLATFORM === "chrome") {
        return (
        <div className="flex w-full pt-[8px] px-[8px] justify-center">
            <Link to="/dashboard/history" type="button" className="z-3">
                <BackSvgIcon className="hover:text-gray-500 ml-auto" />
            </Link>
            <ChromeDraggableElement className="cursor-move fixed top-[7px] left-0 right-0 h-[9px]" />
            <ChromeDraggableElement className=" cursor-move flex-fill">
                <div className="flex">
                    <div className="px-4 text-15 flex-fill text-center font-semibold">
                    <span>{title}</span>
                    </div>
                <button onClick={onDelete} type="button" className="z-3">
                    <TrashSvgIcon className="text-gray-300 hover:text-white ml-auto" />
                </button>
                </div>
            </ChromeDraggableElement>
        </div>
        );
    }

    return (
        <div className="flex items-center border-gray-200 gap-[8px] _header_zurxb_2 p-[16px]">
            <Link to="/dashboard/history" type="button" className="z-3">
                <BackSvgIcon className="hover:text-gray-500 ml-auto" />
            </Link>
            <div className="flex-fill"></div>
            <div className="text-[24px] font-semibold leading-[32px] overflow-hidden">
            <EditableTitleForm onUpdateTitleClick={onUpdateTitleClick} title={title} className="flex group items-center hover:bg-gray-100 focus:bg-gray-100 px-[10px] rounded pt-[8px] pb-[7.5px] mobile:pt-8 mobile:pb-[7.5px] w-full justify-center truncate outline-none border-none focus:ring-0 or focus:ring-transparent">

            </EditableTitleForm>
            </div>
            {/* <EditableTitleForm className="text-[24px] font-semibold leading-[32px] overflow-hidden"> */}
                {/* {title} */}
            {/* </EditableTitleForm> */}
            <div className="flex-fill"></div>
            {/* <TrashSvgIcon className=" hover:text-gray-500 ml-auto shrink-0" /> */}
            <button onClick={onDelete} type="button" className="z-3">
                <TrashSvgIcon className="hover:text-gray-500 ml-auto text-black shrink-0" />
            </button>
        </div>
    );
}



export function PastNoteView() {

    let { id } = useParams();
    const currentEncounterId = id;

    const [currentEncounter, setCurrentEncounter] = useState(null)
    const [modalIsOpen, setModalIsOpen] = useState(false)

    const {
        encounters,
        updateEncounterUUID,
        deleteEncounter,
        updateEncounterFields,
      } = useEncounters();

    const navigate = useNavigate()

     useEffect(() => {
        encounters.map((encounter) => {
            if (encounter.uuid === currentEncounterId) {
                setCurrentEncounter(encounter)
                updateEncounterUUID(encounter.uuid)
            }
        })
     }, [currentEncounterId, encounters])

    const currentNoteGenerations = (
        currentEncounter &&
        currentEncounter.completedNoteGenerations[currentEncounter.completedNoteGenerations.length - 1])
    const currentNote = currentNoteGenerations && currentNoteGenerations.note

     if (!currentEncounter) {
        return <span>No note</span>
     }

    function onConfirm () {
        deleteEncounter(currentEncounterId);
        navigate("/dashboard/history")
    }


    const onUpdateTitleClick = (title) => {
        updateEncounterFields({ title })
      }

    return (<>
        <Modal
            modalIsOpen={modalIsOpen}
            closeModal={() => setModalIsOpen(false)}
            onConfirm={onConfirm}
            confirmLabel={"Delete consultation"}
            title={"Are you sure you want to delete this consultations?"}
            subtitle={"You won't be able to recover the consultation."}
        />
        <PastViewHeader title={currentEncounter.title} onDelete={() => setModalIsOpen(true)} onUpdateTitleClick={onUpdateTitleClick}/>

        <NoteLayout initialEncounterUuid={currentEncounterId} withHeader={false} />
    </>)

    return (
    <>
        <div
            id="modal"
            className="flex flex-col w-full h-full px-[8px] pb-[8px] flex-fill space-y-[8px] justify-end top-[6px]"
        >
            <div className="flex flex-col flex-fill" style={{animationDuration: "200ms"}}>
            <div className={`flex flex-col flex-fill ${process.env.REACT_APP_BUILD_PLATFORM === "chrome" && "card items-center"} rounded justify-center h-full w-full z-modal overflow-y-hidden`}>

                <PastViewHeader title={currentEncounter.title} />

                <NoteLayout initialEncounterUuid={currentEncounterId} />

                {/* ModalBody */}
                <div className="flex flex-col w-full relative flex-fill px-[8px] pb-[8px]">
                <div className="flex flex-col justify-start w-full flex-grow overflow-y-auto">
                    <div className="flex flex-col my-[12px]">

                {/* TranscriptContent */}
                <div className="flex flex-col flex-fill space-y-[8px]">
                    <div className={`flex flex-fill ${process.env.REACT_APP_BUILD_PLATFORM === "chrome" && "card"}  rounded`}>
                    <CustomScrollContainer className="flex-fill flex-col space-y-[12px]">
    

                    { currentNote && currentNote.sections
                        ? currentNote.sections.map((item, index) => {
                            // const { ref, onChange, ...rest } = register(`note.${index}.${item.title.toLowerCase()}`);
                            // console.log("content ", item.content)
                            return (
                            <>
                            <NoteSection item={item} index={index} />
                            {/* <div className="text-white flex space-x-[4px] items-center text-[13px] font-medium text-primary">
                                <div>{item.title.toUpperCase()}</div>
                            </div>
                            <AutoResizeTextarea
                                // ref={ref}
                                // onChange={onChange}
                                name={`note.${index}.text`}
                                control={control}
                                // {...rest}
                                defaultValue={item.content.join("\n")}
                                // text-[14px] w-full rounded border border-solid border-[#ffffff77] bg-transparent p-[8px] leading-[24px] text-white !outline-none text-center
                                className="flex-shrink-0 !border-transparent p-0 mt-[2px] resize-none rounded-0 min-h-[19px] overflow-hidden focus:border-transparent focus:ring-0 !outline-none" spellcheck="false"
                            >
                                {item.content}
                            </AutoResizeTextarea> */}
                            </>
                        )
                        }) : (
                        <span className="text-center">Notes have not been generated yet</span>
                        )
                    }
    
                        </CustomScrollContainer>
                    </div>
                    </div>
                    {/* ./End TranscriptContent */}


                    </div>
                </div>
                </div>
                {/* ./End ModalBody */}

            </div>
            </div>
        </div>
        </>
    );
}
