import styled from 'styled-components'


export const DisabledButton = styled.button`
  background: #404040;
  border: 1px solid transparent;
  color: #7a7a7c;
`


export const ButtonTextContainer = styled.button`
  padding: 11px 8px;
  flex: 1 1 auto;
  min-width: 0;
  min-height: 0;

  &:hover {
    background: ${(props)=> !props.disabled ?'linear-gradient(0deg,#000000 0%,#000000 100%) padding-box,linear-gradient(180deg,#6e6e6e,#2f2f2f) border-box;':'#404040;'}
  }
`;

export const ButtonTextContainerWhite = styled(ButtonTextContainer)`
  &:hover {
    background: ${(props)=> !props.disabled ?'linear-gradient(0deg,#ffffff 0%,#ffffff 100%) padding-box,linear-gradient(180deg,#808080,#ffffff) border-box;':'#404040;'}
  }
`;

////////////////////
export const ButtonContainer = styled.div`
  background: ${(props)=> !props.disabled ?'linear-gradient(0deg,#282828 0%,#282828 100%) padding-box,linear-gradient(180deg,#6e6e6e,#2f2f2f) border-box;':'#404040;'}
  border: ${(props)=> !props.disabled ?'1px solid;':'1px solid transparent;'}
  border-image-source: linear-gradient(180deg,#b8b8b8 -76.62%,rgba(184,184,184,0) 131.05%);
  display: flex;
  align-items: center;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  box-shadow: 0 4px 4px #0003;
  overflow: hidden;

  &:disabled {
    background: #404040;
    border: 1px solid transparent;
    color: #7a7a7c;
  }
`;

export const ButtonContainerWhite = styled(ButtonContainer)`
  background: ${(props)=> !props.disabled ?'linear-gradient(0deg,#ebebeb 0%,#ebebeb 100%) padding-box,linear-gradient(180deg,#e7e7e7,#ffffff) border-box border-box;':'#404040;'}
  border: ${(props)=> !props.disabled ?'1px solid;':'1px solid transparent;'}
  border-image-source: linear-gradient(180deg,#dfdfdf -76.62%,rgba(184,184,184,0) 131.05%);
  box-shadow: 0 4px 4px #0003;

  &:disabled {
    background: #404040;
    color: #7a7a7c;
  }
`;

////////////////////
export const ButtonChevronContainer = styled.button`
  align-self: stretch;
  padding: 4px 14px;

  &:hover {
    background: linear-gradient(0deg,#000000 0%,#000000 100%) padding-box,linear-gradient(180deg,#6e6e6e,#2f2f2f) border-box;
  }
`;

export const ButtonChevronContainerWhite = styled(ButtonChevronContainer)`
  &:hover {
    background: linear-gradient(0deg,#ffffff 0%,#ffffff 100%) padding-box,linear-gradient(180deg,#ececec,#ffffff) border-box !important;
  }
`;

////////////////////
export const ButtonSeparator = styled.div`
  width: 1px;
  align-self: stretch;
  background-image: linear-gradient(180deg,#b8b8b8 -76.62%,rgba(184,184,184,0) 131.05%);
`;

export const ButtonSeparatorWhite = styled(ButtonSeparator)`
  background-image: linear-gradient(180deg,#b8b8b8 -76.62%,rgba(184,184,184,0) 131.05%);
`;
