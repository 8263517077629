/*global chrome*/

import { useEffect, useState } from "react";
import { useEffectAsync } from '@chengsokdara/react-hooks-async'

/*
 * For Local development on webpage.
 */
const asyncLocalStorage = {
  setItem(key, value) {
      return Promise.resolve().then(function () {
          localStorage.setItem(key, value);
      });
  },
  getItem(key) {
      return Promise.resolve().then(function () {
          return localStorage.getItem(key);
      });
  }
};

const useLocalStorageAsync = (keyName, defaultValue) => {

  const [loading, setLoading] = useState(true);
  const [storedValue, setStoredValue] = useState(defaultValue);

  useEffectAsync(async () => {
      setLoading(true);
      let value = defaultValue;
      try {
        value = await asyncLocalStorage.getItem(keyName);
        if (!value && defaultValue) {
          value = defaultValue
        } else {
          value = JSON.parse(value)
        }
      } catch (e) {
          throw e
      }
      setStoredValue(value)
      setLoading(false)
  }, []);

  const setValue = async (newValue) => {
    setStoredValue(newValue);
    try {
      const newValueJSON = JSON.stringify(newValue)
      await asyncLocalStorage.setItem(keyName, newValueJSON)
    } catch (err) {
      throw err
    }
  };

  return [storedValue, setValue, loading];

};

/*
 * For chrome. Syncs with background script.
 */
const useChromeStorageAsync = (keyName, defaultValue) => {

  const [loading, setLoading] = useState(true);
  const [storedValue, setStoredValue] = useState(defaultValue);

  useEffectAsync(async () => {
    setLoading(true);
    let value = defaultValue;
    try {
      value = await chrome.storage.local.get([keyName]);
      value = value[keyName]
      if (!value && defaultValue) {
        value = defaultValue
      }
    } catch (e) {
        throw e
    }
    setStoredValue(value)
    setLoading(false)
  }, []);

  const setValue = async (newValue) => {
    setStoredValue(newValue);
    try {
      await chrome.runtime.sendMessage({type: "STORE_DATA", area: "local", payload: {[keyName]: newValue}});
    } catch (err) {
      throw err
    }
  };

  return [storedValue, setValue, loading];
};

const getTokensFromLocalStorage = async () => {
  try {
    let value = await asyncLocalStorage.getItem('tokens');
    return JSON.parse(value)
  } catch (e) {
      throw e
  }
};

const setTokensFromLocalStorage = async (newValue) => {
  try {
    const newValueJSON = JSON.stringify(newValue)
    await asyncLocalStorage.setItem('tokens', newValueJSON)
  } catch (err) {
    throw err
  }
};

const getTokensFromChromeStorage = async () => {
  try {
    let value = await chrome.storage.local.get(['tokens']);
    value = value['tokens']
    return value
  } catch (e) {
      throw e
  }
};

const setTokensFromChromeStorage = async (newValue) => {
  try {
    await chrome.runtime.sendMessage({type: "STORE_DATA", area: "local", payload: {['tokens']: newValue}});
  } catch (err) {
    throw err
  }
};


console.log("process.env.REACT_APP_BUILT_FOR_EXTENSION : ", process.env.REACT_APP_BUILT_FOR_EXTENSION === 'true')

export const useStorageHook = process.env.REACT_APP_BUILD_PLATFORM === 'chrome' ? useChromeStorageAsync : useLocalStorageAsync;
export const getTokensFromStorage = process.env.REACT_APP_BUILD_PLATFORM === 'chrome' ? getTokensFromChromeStorage : getTokensFromLocalStorage;
export const setTokensFromStorage = process.env.REACT_APP_BUILD_PLATFORM === 'chrome' ? setTokensFromChromeStorage : setTokensFromLocalStorage;

// ON SIGNUP
// {
//   "type": "STORE_DATA",
//   "area": "local",
//   "payload": {
//       "user:andrienko@live.ca:encounters": {
//           "emitter": "n7ovqciadaq",
//           "value": {
//               "version": 0,
//               "state": {
//                   "encounters": [
//                       {
//                           "uuid": "a1f1edc4-9c9a-4808-a1fc-2a77d252614a",
//                           "type": "IN_PERSON",
//                           "createdAt": 1701290411000,
//                           "updatedAt": 1701290411000,
//                           "rating": null,
//                           "isImportedViaAirdrop": false,
//                           "title": null,
//                           "freeText": "",
//                           "audioFeedbackEncryptionKey": null,
//                           "transcript": null,
//                           "transcriptQuotes": [],
//                           "pendingNoteGeneration": null,
//                           "completedNoteGenerations": []
//                       }
//                   ]
//               }
//           }
//       }
//   }
// }

// ON UPDATE OF USER
// {
//   "type": "UPDATE_USER_CONTEXT",
//   "update": {
//       "language": "en-US"
//   }
// }
