import React, { useState } from 'react';
import { useForm  } from "react-hook-form";
import {
    useNavigate,
    useLocation,
    Link } from "react-router-dom";

import {useAuth} from '../../hooks/useAuth';
import { ReactSelect, ButtonContainer, ButtonTextContainer } from '../../components/forms';

import { BackSvgIcon } from '../../components/svgs'




export function InviteCodePage() {

    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState()
    const [error, setError] = useState()
    // const [phoneCountryCode, phoneCountryCodeSetter] = useState('CA');
    const navigate = useNavigate(); // <-- access navigate function
  
    // const { user, register } = useAuth();
    const { validateInviteCode } = useAuth();
  
    const {
      // control,
      register,
      handleSubmit,
      formState: {errors, isValid}
    } = useForm({mode: "onChange"})
  
    const onSubmit = async (data) => {
      console.log("Onsubmit: ", data)
      const inviteCode = data.inviteCode;

      const response = await validateInviteCode(inviteCode)
      setLoading(false)
      if (response.error) {
        setError(response.error)
      } else {
        navigate(`/auth/info`, { replace: true, state:{ inviteCode }});
      }
    }
  
    return (<>
          {/* <form onSubmit={handleSubmit(onSubmit)}> */}
     {/* MainContent */}
     <form onSubmit={handleSubmit(onSubmit)}>
  
         {/* <div className="flex justify-center py-[50px]">
          <div className="relative self-center">

          </div>
        </div> */}
  
        <div className="flex flex-col">
          <div className="flex flex-col items-center justify-center mb-[24px] h-[64px]">
            <h1 className="text-[24px] text-center font-medium ">
              Invite Code
            </h1>
          </div>
          <span className="text-[14px] text-center ">
            Enter the invite code you were provided above. Don't have one? Email us.
          </span>
        </div>
  
        {/* Form */}
        <div className="mt-[24px] w-full flex-col items-center space-y-[8px] ">
          {/* <label htmlFor="invite-code" className="block text-sm font-medium leading-6 text-gray-500 text-center">Enter the invite code you were provided above. Don't have one? Email us</label> */}
          <input
            name="invite-code"
            placeholder="Invite Code"
            autoComplete="off"
            className="text-[14px] w-full rounded border border-solid border-[#00000077] bg-transparent p-[8px] leading-[24px]  !outline-none text-center"
            {...register(
              "inviteCode",
              {
                required: true,
                maxLength: 7,
                minLength: 3,
              }
            )}
          />
          {/* <label htmlFor="invite-code" className="block text-sm font-medium leading-6 text-gray-500 text-center">Enter the invite code you were provided above. Dom't have one? Email us</label> */}
        </div>
        {/* {errors && errors.name} */}
        <span className="text-red-500 text-[14px] font-semibold">{error && error}</span>
        {/* End Form */}
  
        {/* Form */}
        {/* <div className="my-[24px] w-full flex-col items-center space-y-[8px] text-white text-center">
          <label htmlFor="invite-code" className="block text-sm font-medium leading-6 text-gray-500">Enter the invite code you were provided below.</label>
          <input name="invite-code" placeholder="Invite Code" className="text-[14px] w-full rounded border border-solid border-[#ffffff77] bg-transparent p-[8px] leading-[24px] text-white !outline-none text-center" />
        </div> */}
        {/* End Form */}
  
        <div className="flex-fill"></div>
  
        {/* <div className="flex flex-col">
          <form>
            <PhoneInput
              placeholder="Enter phone number"
              defaultCountry='US'
              international={false}
              value={value}
              countrySelectProps={{ unicodeFlags: true }}
              onChange={setValue}/>
          </form>
        </div> */}
  
  
        {/* ActionButton */}
        <div className="flex-col items-center mt-[32px]">
            <ButtonContainer disabled={!isValid} type="submit">
              <ButtonTextContainer disabled={!isValid}
                // css={!isValid && disabledButton}
                // className="disabled:bg-[#404040]"
              >
                <div className="flex justify-center items-center space-x-[4px] text-white">
                  {/* <MicrophoneSvgIcon /> */}
                  <div>{loading ? "Loading" : "Next"}</div>
                </div>
              </ButtonTextContainer>
            </ButtonContainer>
          {/* </Link> */}
        </div>
        {/* ./End ActionButton */}
  
        {/* Sign in text */}
        {/* <div className="mt-[8px] pt-[4px] text-[14px] text-grey-[2px] leading-[24px] text-center text-white">
          Already have an account? <a className="text-primary hover:underline cursor-pointer font-medium text-blue-600" target="_blank" rel="noreferrer">Sign in</a>
        </div> */}
        {/* END Sign in text */}
  
    </form>
    {/* ./End MainContent */}
    {/* </form> */}
  
    </>
    )
  }