import React, { useEffect, useRef, useState } from 'react';
import { Link } from "react-router-dom";
import moment from 'moment'


import {
    BackSvgIcon,
    TrashSvgIcon,
    GotoSvgIcon,
  } from '../../components/svgs'
  import {Modal} from '../../components/Modal'

import { useEncounters } from '../../hooks/useEncounters';
import { ChromeDraggableElement } from '../../App';


function HistoryHeader({onClearEncounters, disabledDelete=false}) {

    if (process.env.REACT_APP_BUILD_PLATFORM === "chrome") {
        return (
        <div className="flex w-full pt-[8px] px-[8px] justify-center">
            <Link to="/dashboard/current/transcribe" type="button" className="z-3">
                <BackSvgIcon className=" hover:text-gray-500 ml-auto" />
            </Link>
            <ChromeDraggableElement className="cursor-move fixed top-[7px] left-0 right-0 h-[9px]" />
            <ChromeDraggableElement className="cursor-move  flex-fill">
                <div className="flex">
                    <div className="px-4 text-15 flex-fill text-center text-black font-semibold">
                    <span>Last 20 notes history</span>
                    </div>
                <button onClick={onClearEncounters} type="button" className="z-3" disabled={disabledDelete}>
                    <TrashSvgIcon className={`hover:text-gray-500 ml-auto ${disabledDelete ? 'text-gray-300' : ''} `} />
                </button>
                </div>
            </ChromeDraggableElement>
        </div>
        );
    }

    return (
        <div className="flex items-center border-gray-200 gap-[8px] _header_zurxb_2 p-[16px]">
            <div className="text-[24px] font-semibold leading-[32px] overflow-hidden">History</div>
            <div className="flex-fill"></div>
            <button onClick={onClearEncounters} type="button" className="z-3" disabled={disabledDelete}>
                <TrashSvgIcon className={`hover:text-gray-500 ml-auto shrink-0 ${disabledDelete ? 'text-gray-300' : ''} `} />
            </button>
        </div>
    );
}


export function HistoryView() {

    const momentRef = useRef()

    const [modalIsOpen, setModalIsOpen] = useState(false)
    const {
        currentEncounter,
        encounters,
        addNoteGeneration,
        addTranscriptSession,
        saveEncounter,
        startNewEncounter,
        updateEncounterUUID,
        updateCurrentTranscriptItems
      } = useEncounters();

     useEffect(() => {
        momentRef.current = moment()
     }, [])

    const encounterHistory = encounters.slice(0, encounters.length - 1)
    console.log("Encounter History", encounterHistory)

    const {
        clearEncounters,
      } = useEncounters()

    return (
    <>
        <Modal
            modalIsOpen={modalIsOpen}
            closeModal={() => setModalIsOpen(false)}
            onConfirm={clearEncounters}
            confirmLabel={"Delete all consultations"}
            title={"Are you sure you want to delete all your consultations?"}
            subtitle={"You won't be able to recover any consultation."}
        />
        <div
            id="modal"
            className="flex flex-col w-full h-full px-[8px] pb-[8px] flex-fill space-y-[8px] justify-end top-[6px]"
            >
            <div className="flex flex-col flex-fill" style={{animationDuration: "200ms"}}>
            <div className={`flex flex-col flex-fill ${process.env.REACT_APP_BUILD_PLATFORM === "chrome" && "card items-center"} rounded justify-center h-full w-full z-modal overflow-y-hidden`}>
                
                <HistoryHeader onClearEncounters={() => {setModalIsOpen(true)}} disabledDelete={encounterHistory.length === 0} />

                {/* ModalBody */}
                <div className="flex flex-col w-full relative flex-fill px-[16px] pb-[8px]">
                <div className={`flex flex-col ${encounterHistory && encounterHistory.length <= 0 ? 'justify-center' : 'justify-start'} w-full flex-grow overflow-y-auto`}>
                    <div className="flex flex-col my-[12px]">

                    {
                        encounterHistory && encounterHistory.length <= 0 && (
                            <div className="flex flex-col h-full">
                            <div className="flex flex-col flex-center gap-[30px] pointer-events-none flex-1 px-[8px] justify-center">
                              
                            <div className="flex justify-center py-[10px]">
                              <div className="relative self-center ">
                                  <img src={process.env.PUBLIC_URL + '/flaticons/history.png'} style={{width: "150px", height: "auto"}}/>
                                </div>
                              </div>
                    
                              <div className="flex flex-col items-center justify-center">
                                <span className="text-[12px] text-body text-center text-base">
                                  You currently have no notes in your history. The latest 20 notes will be stored here.
                                </span>
                              </div>
                            </div>
                          </div>
                        )
                    }

                    {
                        encounterHistory && encounterHistory.reverse().map(encounter =>
                            <Link to={`${encounter.uuid}/note`}>
                                <div className="flex justify-between items-center py-[8px] border-b !border-gray-300	border-divider w-full group hover:cursor-pointer">
                                    <div className="flex flex-col overflow-hidden">
                                    <div className="text-[14px] leading-[24px] font-medium truncate group-hover:text-gray-500">
                                        {encounter.title}
                                    </div>
                                    <div className="text-[12px] leading-[14px] text-shaded group-hover:text-gray-500">
                                        {moment(encounter.createdAt).fromNow()} • {moment(encounter.createdAt).format('MMMM DD YYYY, hh:mm A')}
                                    </div>
                                    </div>
                                    <GotoSvgIcon  className="group-hover:text-gray-500 min-w-[28px]" />
                                </div>
                            </Link>
                        )
                    }

                    </div>
                </div>
                </div>
                {/* ./End ModalBody */}

            </div>
            </div>
        </div>
        </>
    );
}
