import React, { useEffect } from 'react';
import { useForm  } from "react-hook-form";
import {useOutletContext} from 'react-router-dom'

import { ReactSelect, AutoResizeTextarea} from '../../components/forms';
import { useTranscriptionContext } from '../../hooks/useTranscription';
import { useNoteDigest } from '../../hooks/useNoteDigest';
import { useEncounters } from '../../hooks/useEncounters';

import { IntroScreenTranscriptView } from "./StartTranscriptScreen"
  


export function NoteSection({item, index, onUpdate}) {

    const {
      control,
      register,
      handleSubmit,
      watch,
      formState: {errors, isValid}
    } = useForm({mode: "onChange"})



    const colors = [
      "#34BFEF",
      "#AF58F4",
      "#3ED8BA",
      "#FDBC55",
      "#EF509C"
    ]
    // const colors = ["black"]

    const currentColor = colors[index % (colors.length)]

  
    const onSubmit = (data) => {
      data.key = item.key
      data.title = item.title
      console.log("note changed => ", item.title.toUpperCase(), "-", data)
      onUpdate(data)
    }
  
    useEffect(() => {
        // TypeScript users 
        // const subscription = watch(() => handleSubmit(onSubmit)())
        const subscription = watch(handleSubmit(onSubmit));
        return () => subscription.unsubscribe();
    }, [handleSubmit, watch]);
    
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className=" flex space-x-[4px] items-center text-sm font-bold text-primary">
          <div style={{color: currentColor}}>{item.title && item.title.toUpperCase()}</div>
        </div>
        <AutoResizeTextarea
          // ref={ref}
          // onChange={onChange}
          name={"text"}
          control={control}
          // {...rest}
          defaultValue={item.text}
          // text-[14px] w-full rounded border border-solid border-[#ffffff77] bg-transparent p-[8px] leading-[24px] text-white !outline-none text-center
          className="text-sm text-black flex-shrink-0 !border-transparent p-0 mt-[2px] resize-none rounded-0 min-h-[19px] overflow-hidden focus:border-transparent focus:ring-0 !outline-none" spellcheck="false"
        >
          {item.text}
        </AutoResizeTextarea>
      </form>
    );
  }


  export function NotesView() {

    // console.log("Notes: ", note)
    // const note = notes && notes[0]

  // const {
    // noteHistory,
    // currentNoteId,
    // loading,
    // currentNote,
    // updateNoteSection,
    // generateNote
  // } = useNoteDigest()

  const [
    shouldShowNoteTab,
    isNewNoteOutlet,
    loading,
    recording,
    loadingRecorder,
    onEndRecording,
    onResumeRecording,
    onStartRecording,
    onPauseRecording,
    onClickCopyTranscript,
    onClickCopyNote,
    onClickRegenerateNote,
    onDiscardConsultation,
    hasTranscript,
    hasNote,
    errorOutlet,
    displayCheck,
    duration
  ] = useOutletContext();

  const {currentEncounter, updateNoteSection} = useEncounters()


  const noteGeneration = (
    currentEncounter &&
    currentEncounter.completedNoteGenerations[currentEncounter.completedNoteGenerations.length - 1])
  const note = noteGeneration &&  noteGeneration.note
  console.log("Notes: ", note)

    // const {
    //   control,
    //   register,
    //   handleSubmit,
    //   watch,
    //   formState: {errors, isValid}
    // } = useForm({mode: "onChange"})
  
    // const onSubmit = (data) => console.log(data)
  
    // useEffect(() => {
    //     // TypeScript users 
    //     // const subscription = watch(() => handleSubmit(onSubmit)())
    //     const subscription = watch(handleSubmit(onSubmit));
    //     return () => subscription.unsubscribe();
    // }, [handleSubmit, watch]);
  
    return (<>
      {
      (!hasTranscript && !recording)
        ?<div className="flex flex-col w-full h-full justify-center items-center"><IntroScreenTranscriptView /></div>
        :<>
      { note && note.sections
        ? note.sections.map((item, index) => {
            // const { ref, onChange, ...rest } = register(`note.${index}.${item.title.toLowerCase()}`);
            // console.log("content ", item.content)
            return (
            <>
              <NoteSection item={item} index={index} onUpdate={updateNoteSection} />
              {/* <div className="text-white flex space-x-[4px] items-center text-[13px] font-medium text-primary">
                <div>{item.title.toUpperCase()}</div>
              </div>
              <AutoResizeTextarea
                // ref={ref}
                // onChange={onChange}
                name={`note.${index}.text`}
                control={control}
                // {...rest}
                defaultValue={item.content.join("\n")}
                // text-[14px] w-full rounded border border-solid border-[#ffffff77] bg-transparent p-[8px] leading-[24px] text-white !outline-none text-center
                className="flex-shrink-0 !border-transparent p-0 mt-[2px] resize-none rounded-0 min-h-[19px] overflow-hidden focus:border-transparent focus:ring-0 !outline-none" spellcheck="false"
              >
                {item.content}
              </AutoResizeTextarea> */}
            </>
          )
        }) : (
          <div className="flex flex-col h-full">
          <div className="flex flex-col flex-center gap-[30px] pointer-events-none flex-1 px-[8px] justify-center">
            
          <div className="flex justify-center py-[10px]">
            <div className="relative self-center ">
                <img src={process.env.PUBLIC_URL + '/flaticons/note.png'} style={{width: "150px", height: "auto"}}/>
              </div>
            </div>
  
            <div className="flex flex-col items-center justify-center">
              <span className="text-[12px] text-body text-center ">
                {/* We are still listening to your transcript. Click 'End Consultation' to generate your note. */}
                A note hasn't been generated yet. During your consultation, click 'End Consultation' to generate a note.
              </span>
            </div>
          </div>
        </div>
        )
      }</>
    }
    </>);
  }