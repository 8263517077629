import { createContext, useContext, useMemo, useEffect, useRef, useState } from 'react'
import { useEffectAsync } from '@chengsokdara/react-hooks-async'
import { io } from 'socket.io-client'

import { getValidAccessToken } from './useAuth'
import { useStorageHook } from './useLocalStorage'

import { BASE_URL } from "../constants"
import { v4 as uuidv4 } from 'uuid';


const defaultStopTimeout = 5_000 // TODO(oandrienko): REMOVE THIS?

const defaultTimeout = {
  stop: undefined,
}
const defaultTranscript = []

const defaultConfig = {
    // apiKey: '',
    apiEndpoint: BASE_URL,
    autoStart: false,
    nonStop: false,
    stopTimeout: defaultStopTimeout,
    streaming: true,
    timeSlice: 500,
    onDataAvailable: undefined,
    withListener: false,
    deviceId: null,
};


export const useTranscription = (config = {}) => {
    const {
        // apiKey,
        apiEndpoint,
        autoStart,
        nonStop,
        stopTimeout,
        streaming,
        timeSlice,
        onDataAvailable: onDataAvailableCallback,
        withListener,
        defaultDeviceId,
      } = {
        ...defaultConfig,
        ...config,
      }

    const chunks = useRef([])
    const listener = useRef()
    const recorder = useRef()
    const stream = useRef()
    const timeout = useRef(defaultTimeout)
    const speakingRef = useRef()
    const socketRef = useRef()
    const lastTimestamp = useRef()
  
    const [recording, setRecording] = useState(false)
    const [duration, setDuration] = useState(false)
    const [speaking, setSpeaking] = useState(false)
    const [connected, setConnected] = useState(autoStart)
    const [transcript, setTranscript] = useState(defaultTranscript)
    const [deviceId, setDeviceId] = useState(defaultDeviceId)
    const [encounterId, setEncounterId] = useState(null)
    const [loading, setLoading] = useState(false)


      /**
   * cleanup on component unmounted
   * - flush out and cleanup lamejs encoder instance
   * - destroy recordrtc instance and clear it from ref
   * - clear setTimout for onStopRecording
   * - clean up hark speaking detection listeners and clear it from ref
   * - stop all user's media steaming track and remove it from ref
   */
    const createSockets = async () => {
        
      let extraHeaders = {}
      const accessToken =  await getValidAccessToken();

      console.log('%c Setting socket TOKEN!', 'background: #222; color: #bada55');
      console.log("createSockets -> Token: ", accessToken)
      if (accessToken) {
        extraHeaders["Authorization"] = `Bearer ${accessToken}`;
        console.log("adding auth header to socket...")
      }
      console.log("Extraheaders:" , extraHeaders)

      const socket = io(apiEndpoint, {
        autoConnect: autoStart,
        reconnection: autoStart,
        extraHeaders,
      });
      console.log('%c "Created socket!', 'background: #222; color: #bada55');
      socketRef.current = socket;

      socket.on('connect', function() {
        console.log('%c Socket has connected to the server!', 'background: #222; color: #bada55');
        lastTimestamp.current = (new Date()).getTime();
        socketRef.current.emit('listen', {
          object: "listen_config",
          uuid: encounterId,
        })
        console.log("Sent listen config!")
        setConnected(true)
        onStartRecording()
      });

      socket.on("connect_error", (err) => {
        console.log('%c Socket has connection ERROR!', 'background: #222; color: #bada55');
        console.log(`connect_error due to ${err.message}`);
      });

      socket.on('listen_v2', function(msg) {
        console.log('%c Socket TASK FINISHED', 'background: #222; color: #bada55');
        console.log('task_finished: ', msg);
      });

      socket.on('disconnect',function() {
        console.log('%c Socket has disconnected!', 'background: #222; color: #bada55');
        setConnected(false)
      });

      socket.on('error',function(err) {
        console.log('%c Socket has an error!', 'background: #222; color: #bada55');
        console.log("error: ", err)
      });

      // TODO(oandrienko): CLEAN UP BELOW LOGIC
      socket.on('listen', (msg) => {
        console.log('%c Socket has recieved a message!', 'background: #222; color: #bada55');
        console.log(msg);
        if (msg.object === "transcript_item") {
          console.log("`transcript_item` recieved!", msg)
          // TODO(oandrienko): CLEAN UP BELOW LOGIC
          //
          //
          setTranscript((prev) => {
            let found = false;
            let shouldSkip = false;
            const newTrans = prev.map(item => {
              if (item.id === msg.id) {
                console.log("Found previous item with ID so updating: ", msg.id)
                // if (msg.end_time < item.end_time) {
                //   console.log("Bad new msg... msg.time ", msg.end_time, " vs item.time ", item.end_time)
                //   shouldSkip = true;
                //   return item
                // }
                found = true;
                return msg
              }
              return item
            })
            let tmp;
            if (!shouldSkip) {
              tmp =  found ? newTrans : [...prev, msg];
            } else {
              tmp =  prev
            }
            console.log("New Trans: ", tmp)
            return tmp
          })
        }
      });

      if (autoStart) {
        console.log("Calling socket.connect()....")
        socket.connect();
      }
    }


  /**
   * cleanup on component unmounted
   * - flush out and cleanup lamejs encoder instance
   * - destroy recordrtc instance and clear it from ref
   * - clear setTimout for onStopRecording
   * - clean up hark speaking detection listeners and clear it from ref
   * - stop all user's media steaming track and remove it from ref
   */
  useEffect(() => {
    
    console.log("calling create sockets...")
    createSockets()
    
    return () => {
      if (chunks.current) {
        chunks.current = []
      }
      if (recorder.current) {
        recorder.current.destroy()
        recorder.current = undefined
      }
      onStopTimeout('stop')
      if (listener.current) {
        listener.current.pause()
      }
      if (stream.current) {
        stream.current.getTracks().forEach((track) => track.stop())
        stream.current = undefined
      }
      if (socketRef.current) {
        socketRef.current.off('connect')
        socketRef.current.off('disconnect')
        socketRef.current.off('listen')
      }
    }
  }, [])

    /**
   * if config.autoStart is true
   * start speech recording immediately upon component mounted
   */
    useEffectAsync(async () => {
      if (autoStart) {
        await onStartRecording()
      }
    }, [autoStart])


  /**
   * start speech recording and start listen for speaking event
   */
  const startRecording = async () => {
    console.log('autoStart? : ', autoStart);
    console.log('connected.current? : ', connected);

    console.log("Checking access token first...")
    setLoading(true); console.log("!!!!!!! LOADING? : true")
    await socketRef.current.disconnect();
    await createSockets()
    
    if (!autoStart) {
      console.log('%c Trying to connect to socket...', 'background: #222; color: #bada55');
      const resultingSocket = socketRef.current.connect()
      console.log("resultingSocket", resultingSocket)
      console.log(`%c Done connecting?... connected? ${resultingSocket.connected}`, 'background: #222; color: #bada55');
    }
    if (connected) {
      await onStartRecording()
      setLoading(false); console.log("!!!!!!! LOADING? : false")
    }
  }

  /**
   * pause speech recording also stop media stream
   */
  const pauseRecording = async () => {
    // await onPauseRecording() // TODO(oandrienko): Should we stop the recording?
    await onStopRecording()
    if (!autoStart) {
      console.log('%c Disconnect from socket...', 'background: #222; color: #bada55');
      await socketRef.current.disconnect();
    }
  }

  /**
   * stop speech recording and start the transcription
   */
  const stopRecording = async () => {
    await onStopRecording()
    if (!autoStart) {
      console.log('%c Disconnect from socket...', 'background: #222; color: #bada55');
      await socketRef.current.disconnect();
    }
  }

  /**
   * start speech recording event
   * - first ask user for media stream
   * - create recordrtc instance and pass media stream to it
   * - create lamejs encoder instance
   * - check recorder state and start or resume recorder accordingly
   * - start timeout for stop timeout config
   * - update recording state to true
   */
  const onStartRecording = async () => {
    console.log("onStartRecording...")
    try {
      if (!stream.current) {
        await onStartStreaming()
      }
      if (stream.current) {
        if (!recorder.current) {
          const {
            default: { RecordRTCPromisesHandler, StereoAudioRecorder },
          } = await import('recordrtc')
          const recorderConfig = {
            mimeType: 'audio/wav',
            numberOfAudioChannels: 1, // mono
            recorderType: StereoAudioRecorder,
            // Rates of 16000, 22050, 32000, 44100, and 48000 are all relatively common
            // https://librosa.org/blog/2019/07/17/resample-on-load/
            sampleRate: 44100, // 44100, // Sample rate = 44.1khz // TODO(oandrienko): CHANGE THIS? LOWER SAMPLE RATE?
            timeSlice: streaming ? timeSlice : undefined,
            type: 'audio',
            ondataavailable: streaming ? onDataAvailable : undefined,
          }
          recorder.current = new RecordRTCPromisesHandler(
            stream.current,
            recorderConfig
          )
        }
        const recordState = await recorder.current.getState()
        if (recordState === 'inactive' || recordState === 'stopped') {
          await recorder.current.startRecording()
        }
        if (recordState === 'paused') {
          await recorder.current.resumeRecording()
        }
        if (nonStop) {
          onStartTimeout('stop')
        }
        setRecording(true)
      }
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * get user media stream event
   * - try to stop all previous media streams
   * - ask user for media stream with a system popup
   * - register hark speaking detection listeners
   */
  const onStartStreaming = async () => {
    try {
      if (stream.current) {
        stream.current.getTracks().forEach((track) => track.stop())
      }
      //     var constraints = { deviceId: { exact: camera.deviceId } };
      //     return navigator.mediaDevices.getUserMedia({ video: constraints });
      if (deviceId) {
        console.log("Using specific device for userMedia: ", deviceId)
        stream.current = await navigator.mediaDevices.getUserMedia({
          audio:  { deviceId: { exact: deviceId } },
          // video: false,
        })
      } else {
        stream.current = await navigator.mediaDevices.getUserMedia({
          audio: true,
          // video: false,
        })
      }
      if (!listener.current) {
        if (withListener) {
          // TODO(oandrienko): DISABLED FOR NOW, NEED TO SET UP WASM AND ONNX
          // LOADING FUNCTIONALITY FOR CREATE REACT APP.
          // FOR NOW NOT WORKING.
          // THIS LISTENER SHOULD HAVE LOW PRECISION, SO LETS THROUGH
          // LOTS OF SILENCE. THAT WAY WE CAN LOWER THE LOAD ON THE SERVER.
          console.log("Starting local SileroVAD local listener...")

          // const { MicVAD } = await import('@ricky0123/vad-web')

          // listener.current = await MicVAD.new({
          //   stream: stream.current,
          //   positiveSpeechThreshold: 0.8,
          //   minSpeechFrames: 5,
          //   preSpeechPadFrames: 10,
          //   onSpeechStart: (() => {
          //     console.log("MicVAD.onSpeechStart ")
          //     onStartSpeaking()

          //   }),
          //   onSpeechEnd:((arr) => {
          //     console.log("MicVAD.onSpeechEnd ")
          //     onStopSpeaking()
          //   }),
          //   onVADMisfire:((arr) => {
          //     console.log("MicVAD.onVADMisfire ")
          //     onStopSpeaking()
          //   }),
          // })
          // listener.current.start()
        } else {
          console.log("Skipping local listener creation...")
        }
      }
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * start stop timeout event
   */
  const onStartTimeout = (type) => {
    if (!timeout.current[type]) {
      timeout.current[type] = setTimeout(onStopRecording, stopTimeout)
    }
  }

  /**
   * user start speaking event
   * - set speaking state to true
   * - clear stop timeout
   */
    const onStartSpeaking = () => {
      console.log('start speaking')
      setSpeaking(true)
      speakingRef.current = true;
      onStopTimeout('stop')
    }
  
    /**
     * user stop speaking event
     * - set speaking state to false
     * - start stop timeout back
     */
    const onStopSpeaking = () => {
      console.log('stop speaking')
      setSpeaking(false)
      speakingRef.current = false;
      if (nonStop) {
        onStartTimeout('stop')
      }
    }

  /**
   * pause speech recording event
   * - if recorder state is recording, pause the recorder
   * - clear stop timeout
   * - set recoriding state to false
   */
  const onPauseRecording = async () => {
    console.log("onPauseRecording...")
    try {
      if (recorder.current) {
        const recordState = await recorder.current.getState()
        if (recordState === 'recording') {
          await recorder.current.pauseRecording()
        }
        onStopTimeout('stop')
        setRecording(false)
      }
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * stop speech recording event
   * - flush out lamejs encoder and set it to undefined
   * - if recorder state is recording or paused, stop the recorder
   * - stop user media stream
   * - clear stop timeout
   * - set recording state to false
   * - start Whisper transcription event
   * - destroy recordrtc instance and clear it from ref
   */
  const onStopRecording = async () => {
    console.log("onStopRecording...")
    try {
      if (recorder.current) {
        const recordState = await recorder.current.getState()
        if (recordState === 'recording' || recordState === 'paused') {
          await recorder.current.stopRecording()
        }
        onStopStreaming()
        onStopTimeout('stop')
        setRecording(false)
        setDuration(0)
        await recorder.current.destroy()
        chunks.current = []
        recorder.current = undefined
      }
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * stop media stream event
   * - remove hark speaking detection listeners
   * - stop all media stream tracks
   * - clear media stream from ref
   */
  const onStopStreaming = () => {
    if (listener.current) {
      listener.current.pause();
      listener.current = undefined
    }
    if (stream.current) {
      stream.current.getTracks().forEach((track) => track.stop())
      stream.current = undefined
    }
  }

  /**
   * stop timeout event
   * - clear stop timeout and remove it from ref
   */
  const onStopTimeout = (type) => {
    if (timeout.current[type]) {
      clearTimeout(timeout.current[type])
      timeout.current[type] = undefined
    }
  }

  /**
   * Get audio data in chunk based on timeSlice
   * - while recording send audio chunk to Whisper
   * - chunks are concatenated in succession
   * - set transcript text with interim result
   */
  const onDataAvailable = async (data) => {
    const timestamp = (new Date()).getTime();
    const currentDuration = timestamp - lastTimestamp.current
    lastTimestamp.current = timestamp
    console.log('onDataAvailable', data, currentDuration)
    setDuration(prevDuration => prevDuration + currentDuration)
    try {
      if (streaming && recorder.current) {
        onDataAvailableCallback?.(data)
        chunks.current.push(data)
        
        const recorderState = await recorder.current.getState()
        if (recorderState === 'recording') {
          const reader = new FileReader(); // or `FileReaderSync()`
          reader.readAsDataURL(data);
          reader.onload = function(event) {
            const payload = event.target.result.split(",")[1]; // remove first bit of `data:audio/wav;base64,<payload>`
            socketRef.current.emit('listen', {object: "audio_chunk", payload});
          };
        }
      }
    } catch (err) {
      console.error(err)
    }
  }

  const clearTranscriptBuffer = () => {
    setTranscript([])
  }

  return {
    loading: connected,
    recording,
    speaking,
    connected,
    transcript,
    deviceId,
    duration,
    pauseRecording,
    startRecording,
    stopRecording,
    setDeviceId,
    setTranscript,
    clearTranscriptBuffer,
    setEncounterId,
  }

};


//       "transcript": {
//           "sessions": [
//               {
//                   "endedAt": 1701291155102,
//                   "hadUnstableNetwork": false,
//                   "items": [
//                       {
//                           "endOffsetMs": 80533,
//                           "isFinal": false,
//                           "speaker": "UNSPECIFIED",
//                           "startOffsetMs": 78083,
//                           "text": "Staying 1234",
//                           "uuid": "b734893c-94ae-4208-99de-e66657118496"
//                       }
//                   ],
//                   "locale": "ENGLISH_US",
//                   "model": "AUTO",
//                   "startedAt": 1701291074658,
//                   "uuid": "4551867f-a732-47a6-9731-7f44d6a0b7b1"
//               },


const TranscriptionContext = createContext();

export const TranscriptionProvider = (config) => {

  const [currentSessionId, setCurrentSessionId, loadingSessionId] = useStorageHook(
    "currentSessionId", null,
  );
  const [transcriptSessions, setTranscriptSessions, loadingTranscriptSessions] = useStorageHook(
    "transcriptSessions", []
  );
  const [flatTranscript, setFlatTranscript] = useState([]);

  const {
    recording,
    transcript,
    speaking,
    connected,
    deviceId,
    duration,
    pauseRecording,
    startRecording,
    stopRecording,
    setDeviceId,
    setTranscript,
  } = useTranscription(config)

  // useEffect(() => {
  //   console.log("useEffect currentSessionId: ", currentSessionId)
  //   if (!recording && !loadingSessionId && !loadingTranscriptSessions) {
  //     let startOffset = 0.
  //     if (transcriptSessions.length > 0) {
  //       startOffset = transcriptSessions[0].timestamp
  //     }
  //     const currId = uuidv4();
  //     const timesamp = new Date().getTime();
  //     const newTranscriptSession = {
  //       id: currId,
  //       items: [],
  //       timestamp: new Date().getTime(),
  //       startMs: timesamp - startOffset,
  //       endMs: 0.,
  //     }
  //     console.log("ADDING NEW TRANSSESSION ", newTranscriptSession)
  //     setCurrentSessionId(currId)
  //     setTranscriptSessions([...transcriptSessions, newTranscriptSession])
  //   }
  // }, [recording, loadingSessionId, loadingTranscriptSessions]);

  useEffect(() => {
    console.log("Mounted!")
    transcriptSessions.map(session => {
      if (session.id === currentSessionId && session.startMs === session.endMs) {
        session.endMs = (session.startMS - ((new Date().getTime() )/ 1000))
        console.log("Set end time to ", session.endMs)
      }
    });
    return () => {
      console.log("UNMOUNTED!")
      transcriptSessions.map(session => {
        if (session.id === currentSessionId && session.startMs === session.endMs) {
          session.endMs = (session.startMS - ((new Date().getTime() )/ 1000))
          console.log("Set end time to ", session.endMs)
        }
      });
    }
  }, [])

  useEffect(() => {
    console.log("Setting current transcriptSessions based on new transcript: ", transcript, currentSessionId, transcriptSessions)
    if (transcript && transcript.length > 0 && transcriptSessions && currentSessionId) {
      const newTranscriptSessions = transcriptSessions.map(session => {
        if (session.id === currentSessionId) {
          console.log(`REASSIGNED TRANSCRIPT.SESSION ${currentSessionId} to `, transcript)
          let newTranscript = transcript.map(item => {
            return Object.assign({}, item, {start_time: item.start_time + session.startMs, end_time: item.end_time + session.startMs})
          })
          let endMs = 0
          if (newTranscript.length > 0) {
            endMs = session.startMs + newTranscript[newTranscript.length-1].end_time
          }
          return Object.assign({}, session, {items: newTranscript, endMs})
        }
        return session
      })
      setTranscriptSessions(newTranscriptSessions)
    }
  }, [transcript, currentSessionId]);

  useEffect(() => {
    console.log("Converting Transcript.sessions to flat transcript: ", transcriptSessions, flatTranscript)
    if (transcriptSessions) {
      let currFlatTranscript = transcriptSessions.map(session => session.items).flat()
      console.log("new flatTranscript: ", currFlatTranscript)
      setFlatTranscript(currFlatTranscript)
    }
  }, [transcriptSessions]);

  const onPauseRecording = () => {

    pauseRecording();
  };

  const onStartRecording = () => {
    setTranscript([])
    ////
    let startMs = 0.
    const timestamp = (new Date().getTime()) / 1000;
    if (transcriptSessions.length > 0) {
      startMs = timestamp - transcriptSessions[0].timestamp
      console.log("OFFSET: start Ms: ", startMs, " -- ", timestamp, transcriptSessions[0].timestamp, startMs)
    }
    const currId = uuidv4();
    const newTranscriptSession = {
      id: currId,
      items: [],
      timestamp,
      startMs,
      endMs: startMs,
    }
    console.log("ADDING NEW TRANSSESSION old=",    transcriptSessions, ", new=",  newTranscriptSession, " with ID = ", currId)
    setTranscriptSessions([...transcriptSessions, newTranscriptSession])
    setCurrentSessionId(currId)
    ////
    startRecording();
  };

  const onStopRecording = () => {
    transcriptSessions.map(session => {
      if (session.id === currentSessionId) {
        session.endMs = (session.startMS - ((new Date().getTime() )/ 1000))
        console.log("Set end time to ", session.endMs)
      }
    });
    stopRecording();
  };

  const onNewTranscript = async () => {
    console.log("onNewTranscript")
    setTranscript([])
    await setTranscriptSessions([])
    await setCurrentSessionId(null)
    setFlatTranscript([])
  };


  const value = useMemo(
    () => ({
      recording,
      transcript: flatTranscript,
      speaking,
      connected,
      // transcript,
      deviceId,
      duration,
      pauseRecording,
      startRecording: onStartRecording,
      stopRecording: onStopRecording,
      setDeviceId,
      onNewTranscript,
    }),
    [ recording,
      flatTranscript,
      speaking,
      connected,
      // transcript,
      deviceId,
      duration,
      pauseRecording,
      onStartRecording,
      onStopRecording,
      setDeviceId,
      onNewTranscript,
    ]
  );
  return <TranscriptionContext.Provider value={value}>{config.children}</TranscriptionContext.Provider>;
};

export const useTranscriptionContext = () => {
  return useContext(TranscriptionContext);
};
