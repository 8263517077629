import { useEffectAsync } from '@chengsokdara/react-hooks-async'
import { useEffect, useRef, useState } from 'react'


const defaultConfig = {
  autoStart: false,
  defaultDeviceId: null,
};


export const useSoundMeter = (config) => {

  const {
    autoStart,
    defaultDeviceId
  } = {
    ...defaultConfig,
    ...config,
  }

  const mediaStream = useRef();
  const interval = useRef();
  const audioCtx = useRef();
    
  const [recording, setRecording] = useState(false)
  const[volume, setVolume ] = useState(0)
  const [deviceId, setDeviceId] = useState(defaultDeviceId)

  /**
   * if config.autoStart is true
   * start speech recording immediately upon component mounted
   */
  useEffectAsync(async () => {
    if (autoStart) {
      await startRecording()
    }
  }, [autoStart])


  useEffect(() => {
    return () => {
      stopRecording();
    }
  }, [])

  const setCurrentDeviceId = async (newDeviceId) => {
    await stopRecording()
    setDeviceId(newDeviceId)
    await startRecording()
  }

  /**
   * start speech recording event
   * - first ask user for media stream
   * - create recordrtc instance and pass media stream to it
   * - create lamejs encoder instance
   * - check recorder state and start or resume recorder accordingly
   * - start timeout for stop timeout config
   * - update recording state to true
   */
  const startRecording = async () => {
    console.log("onStartRecording Sound Meter... with deviceId ", deviceId)
    try {
      let stream = null;
      if (deviceId != null) {
        stream = await navigator.mediaDevices.getUserMedia({
          audio:  { deviceId: { exact: deviceId } },
          // video: false,
        })
      } else {
        stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          // video: false
        });
      }
      
      if (mediaStream.current) {
        mediaStream.current.getTracks().forEach(function(track) {
          console.log("Stopped streams!")
          track.stop();
        });  
      } 
      mediaStream.current = stream;

      // Create and configure the audio pipeline
      const audioContext = new AudioContext();
      const analyzer = audioContext.createAnalyser();
      analyzer.fftSize = 512;
      analyzer.smoothingTimeConstant = 0.1;
      const sourceNode = audioContext.createMediaStreamSource(stream);
      sourceNode.connect(analyzer);

      audioCtx.current = audioContext;

      // Analyze the sound
      interval.current = setInterval(() => {
          // Compute the max volume level (-Infinity...0)
          const fftBins = new Float32Array(analyzer.frequencyBinCount); // Number of values manipulated for each sample
        analyzer.getFloatFrequencyData(fftBins);
          // audioPeakDB varies from -Infinity up to 0
        const audioPeakDB = Math.max(...fftBins);

          // Compute a wave (0...)
          const frequencyRangeData = new Uint8Array(analyzer.frequencyBinCount);
        analyzer.getByteFrequencyData(frequencyRangeData);
        const sum = frequencyRangeData.reduce((p, c) => p + c, 0);
          // audioMeter varies from 0 to 10
        const audioMeter = Math.sqrt(sum / frequencyRangeData.length);
        // console.log("audioMeter: ", audioMeter)

        setVolume(audioMeter)
      }, 150);

      setRecording(true)
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * stop speech recording and start the transcription
   */
  /**
   * stop speech recording event
   * - flush out lamejs encoder and set it to undefined
   * - if recorder state is recording or paused, stop the recorder
   * - stop user media stream
   * - clear stop timeout
   * - set recording state to false
   * - start Whisper transcription event
   * - destroy recordrtc instance and clear it from ref
   */
  const stopRecording = async () => {
    console.log("onStopRecording Sound Meter...")
    try {
      if (interval.current) {
        clearInterval(interval.current);
        interval.current = null;
      }
      if (audioCtx.current) {
        await audioCtx.current.close()
      }
      if ( mediaStream.current) {
        console.log("Stopping streams...")
        mediaStream.current.getTracks().forEach(function(track) {
          console.log("Stopped streams!")
          track.stop();
        });   
        console.log("Stopped streams!")
      } 
    } catch (err) {
      console.error(err)
    }
  }

  return {
    volume,
    deviceId,
    recording,
    startRecording,
    stopRecording,
    setDeviceId: setCurrentDeviceId,
  }

};


export function SoundMeter({volume}) {
  return (
    <div className="flex gap-[4px]">
      <div className={`rounded-[3px] w-[4px] h-[13px] bg-shaded ${ volume >= 2 ? 'bg-black' : 'bg-zinc-300'}`} />
      <div className={`rounded-[3px] w-[4px] h-[13px] bg-shaded ${ volume >= 3 ? 'bg-black' : 'bg-zinc-300'}`} />
      <div className={`rounded-[3px] w-[4px] h-[13px] bg-shaded ${ volume >= 4 ? 'bg-black' : 'bg-zinc-300'}`} />
      <div className={`rounded-[3px] w-[4px] h-[13px] bg-shaded ${ volume >= 5 ? 'bg-black' : 'bg-zinc-300'}`} />
      <div className={`rounded-[3px] w-[4px] h-[13px] bg-shaded ${ volume >= 6 ? 'bg-black' : 'bg-zinc-300'}`} />
      <div className={`rounded-[3px] w-[4px] h-[13px] bg-shaded ${ volume >= 7 ? 'bg-black' : 'bg-zinc-300'}`} />
      <div className={`rounded-[3px] w-[4px] h-[13px] bg-shaded ${ volume >= 8 ? 'bg-black' : 'bg-zinc-300'}`} />
      <div className={`rounded-[3px] w-[4px] h-[13px] bg-shaded ${ volume >= 9 ? 'bg-black' : 'bg-zinc-300'}`} />
    </div>
  )
}
