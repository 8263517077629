import {
    Link,
    Outlet,
    useLocation,
} from "react-router-dom";
import { ChromeDraggableElement } from '../../App';

export { SettingsGeneralView } from './GeneralTab'
export { SettingsAccountView } from './AccountTab'
export { SettingsNoteView } from './NoteTab'


function MenuContainer({children, className}) {
    return (
      <div className={`flex flex-shrink-0 rounded-[8px] h-[38px] items-center justify-center font-semibold p-[4px] cursor-default bg-primary-dark ${className}`}>
        {children}
      </div>
    )
  }
  
  function MenuItem({title, children, isActive=false}) {

    const activeStyle = {
      background: "linear-gradient(0deg,#282828 0%,#282828 100%) padding-box,linear-gradient(180deg,#6e6e6e,#2f2f2f) border-box",
      border: "1px solid",
      borderImageSource: "linear-gradient(180deg,#b8b8b8 -76.62%,rgba(184,184,184,0) 131.05%)",
    }
  
    return (
      <div style={isActive ? activeStyle : {}} className={`text-[12px] flex flex-1 items-center justify-center font-medium h-full rounded-sm shadow-sm ${isActive ? 'card text-white' : 'text-black text-shaded hover:text-gray-500 unselectedtab'}`}>
        <div className="flex items-center">
          <span>{title}</span>
          {children}
        </div>
      </div>
    );
  }  



function SettingsHeader() {

    if (process.env.REACT_APP_BUILD_PLATFORM === "chrome") {
        return (
          <div className="flex w-full pt-[8px] px-[8px]">
            <ChromeDraggableElement className="cursor-move fixed top-[7px] left-0 right-0 h-[9px]" />
            <ChromeDraggableElement className="cursor-move flex-fill">
              <span className="text-[15px] font-semibold px-[4px]">Settings</span>
            </ChromeDraggableElement>
            <Link to="/dashboard/current/transcribe" className="">
              <button type="button" className="z-3">
                <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 21 21" data-icon="close" height="20" width="20" className="hover:text-gray-500 ml-auto"><path d="m15.4 5-10 10m0-10 10 10" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
              </button>
            </Link>
          </div>
        );
    }

    return (
        <div className="flex items-center border-gray-200 gap-[8px] _header_zurxb_2 p-[16px]">
            <div className="text-[24px] font-semibold leading-[32px] overflow-hidden">Settings</div>
            <div className="flex-fill"></div>
            {/* <TrashSvgIcon className=" hover:text-gray-500 ml-auto shrink-0" /> */}
        </div>
    );
}


export function SettingsLayout({}) {
    let location = useLocation();
    return (
      <div
        id="modal"
        className="flex flex-col w-full h-full px-[16px] pb-[8px] flex-fill space-y-[8px] justify-end top-[6px]"
      >
      <div className="flex flex-col flex-fill" style={{animationDuration: "200ms"}}>
        <div className={`flex flex-col flex-fill ${process.env.REACT_APP_BUILD_PLATFORM === "chrome" && "card items-center"} rounded justify-center h-full w-full z-modal overflow-y-hidden`}>
          
          <SettingsHeader />
  
          <div className="flex-col w-full relative flex-fill px-[8px] pb-[8px]">
            <div className="flex-col justify-start w-full flex-grow">
              <div className="flex-col flex-grow pt-[8px]">
  
                <MenuContainer className="sm:min-w-[504px] sm:w-1/2 min-w-full w-full">
                  <Link to="/dashboard/settings/general" className="flex flex-fill h-full active">
                    <MenuItem title="General" isActive={location.pathname.includes("general")}/>
                  </Link>
                  <Link to="/dashboard/settings/note" className="flex flex-fill h-full active">
                    <MenuItem title="Note" isActive={location.pathname.includes("note")} />
                  </Link>
                  <Link to="/dashboard/settings/account" className="flex flex-fill h-full active">
                    <MenuItem title="Account" isActive={location.pathname.includes("account")} />
                  </Link>
                </MenuContainer>
  
                {/* Content */}
                
                <div className="flex flex-col flex-fill space-y-[16px] mt-[16px] sm:min-w-[504px] sm:w-1/2 min-w-full w-full">
                  <Outlet />
                </div>
                {/* ./Content */}
  
              </div>
            </div>
          </div>
  
        </div>
      </div>
    </div>
    );
  }
