import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import styled from 'styled-components'

import {
    BackSvgIcon,
    PaperAirplaneSvgIcon
} from '../components/svgs'
import { ChromeDraggableElement } from '../App';


const CustomTextArea = styled.textarea`
  width: 100%;
  border-radius: 8px;
  border-width: 1px;
  background-color: transparent;
  padding: 10px;
  resize: none;
  min-height: 44px;
  border: solid 0.5px #ffffff77;
`;



function ContactHeader() {

    if (process.env.REACT_APP_BUILD_PLATFORM === "chrome") {
        return (
        <div className="flex w-full pt-[8px] px-[8px] justify-center">
            <Link to="/dashboard/current/transcribe" type="button" className="z-3">
                <BackSvgIcon className="hover:text-gray-500 ml-auto text-black" />
            </Link>
            <ChromeDraggableElement className="cursor-move fixed top-[7px] left-0 right-0 h-[9px]" />
            <ChromeDraggableElement className="cursor-move flex-fill">
                <div className="flex">
                    <div className="px-[4px] text-[15px] flex-fill text-center font-semibold">
                    <span>Contact Us</span>
                    </div>
                <button type="button" className="z-3">
                    <PaperAirplaneSvgIcon className="hover:text-gray-500 ml-auto text-black" />
                </button>
                </div>
            </ChromeDraggableElement>
        </div>
        );
    }

    return (
        <div className="flex items-center border-gray-200 gap-[8px] _header_zurxb_2 p-[16px]">
            <div className="text-[24px] font-semibold leading-[32px] overflow-hidden">Contact</div>
            <div className="flex-fill"></div>
            {/* <TrashSvgIcon className=" hover:text-gray-500 ml-auto shrink-0" /> */}
            <button type="button" className="z-3">
                <PaperAirplaneSvgIcon className="hover:text-gray-500 ml-auto text-black shrink-0" />
            </button>
        </div>
    );
}


export function ContactView() {
    return (
    <>
        <div
            id="modal"
            className="flex flex-col w-full h-full px-[8px] pb-[8px] flex-fill space-y-[8px] justify-end top-[6px]"
            onClick={e => e.stopPropagation()}
            >
            <div className="flex flex-col flex-fill" style={{animationDuration: "200ms"}}>
            <div className={`flex flex-col flex-fill ${process.env.REACT_APP_BUILD_PLATFORM === "chrome" && "card items-center"} rounded justify-center h-full w-full z-modal overflow-y-hidden`}>

            <ContactHeader />
                

                {/* ModalBody */}
                <div className="flex flex-col w-full relative flex-fill px-[16px] pb-[8px]">
                <div className="flex flex-col justify-start w-full flex-grow overflow-y-auto">
                    <div className="flex flex-col my-[12px]">

                    <div className="flex-col group flex-fill space-y-[4px]">

                        {/* HistoryItem */}
                        <div className="flex space-x-[4px] items-center text-[13px] font-medium text-primary mt-[8px]">
                        <div>Your message</div>
                        </div>
                        <CustomTextArea className="flex flex-fill" placeholder="Write your feedback here..." style={{height: "75px !important", fontSize: "12px"}} />
                        {/* ./End HistoryItem */}

                    </div>

                    </div>
                </div>
                </div>
                {/* ./End ModalBody */}

            </div>
            </div>
        </div>
    </>
    );
}